import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Consignment } from '../../types';
import UserContext from './user';
import useConsignmentApiRoutes, { ConsignmentQueryParams } from '../hooks/api/useConsignmentApiRoutes';
import SocketContext from './socket';
import SnackBarContext from './snackbar';
import ModalContext from './modal';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../constants/table';
import { useGetDriverFullName } from '../hooks/useGetDriverName';

const ConsignmentContext = React.createContext<{
  consignments: Consignment[];
  totalConsignments: number;
  isLoading: boolean;
  setTotalConsignments: React.Dispatch<React.SetStateAction<number>>;
  setConsignments: React.Dispatch<React.SetStateAction<Consignment[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  queryParams: ConsignmentQueryParams;
  refreshConsignments: () => Promise<void | Consignment[]>;
}>({
  consignments: [],
  totalConsignments: 0,
  isLoading: false,
  setTotalConsignments: () => undefined,
  setConsignments: () => undefined,
  setIsLoading: () => undefined,
  queryParams: { countryCode: 'NZ', page: DEFAULT_PAGE, itemsPerPage: ITEM_PER_PAGE },
  refreshConsignments: () => Promise.resolve([]),
});

export default ConsignmentContext;

export const ConsignmentProvider = ({
  children,
  queryParams,
}: {
  children: React.ReactNode;
  queryParams: ConsignmentQueryParams;
}) => {
  const { isLoggedIn } = useContext(UserContext);
  const { consignment: modalConsignment, setConsignment } = useContext(ModalContext);
  const { socket, setUpdatedStatusCodes } = useContext(SocketContext);
  const [consignments, setConsignments] = useState<Consignment[]>([]);
  const [totalConsignments, setTotalConsignments] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { list: listConsignments } = useConsignmentApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const active = useRef(true);

  const queryParamsJson = JSON.stringify(queryParams);
  const refreshConsignments = useCallback(async (): Promise<void | Consignment[]> => {
    if (isLoggedIn) {
      setIsLoading(true);
      const { consignments, total } = await listConsignments(JSON.parse(queryParamsJson));
      if (active.current) {
        setConsignments(consignments);
        setTotalConsignments(total);
      }
      setIsLoading(false);
      return consignments;
    }
  }, [isLoggedIn, listConsignments, queryParamsJson]);

  useEffect(() => {
    refreshConsignments();
  }, [refreshConsignments]);

  const handleUpdateConsignment = useCallback(
    (consignment: Consignment) => {
      if (modalConsignment && modalConsignment._id === consignment._id) {
        // also update the modalConsignment with taht from socket
        setConsignment(consignment);
      }
      setConsignments((curConsignments) => {
        const curIndex = curConsignments.findIndex((c) => c._id === consignment._id);
        if (curIndex === -1) {
          // add to array
          return [consignment, ...curConsignments];
        } else {
          // replace with new value
          curConsignments.splice(curIndex, 1, consignment);
          return [...curConsignments];
        }
      });
      setUpdatedStatusCodes((curStatusCodes) => {
        if (curStatusCodes.includes(consignment.status)) {
          return curStatusCodes;
        } else {
          return [...curStatusCodes, consignment.status];
        }
      });
    },
    [modalConsignment, setConsignment, setUpdatedStatusCodes]
  );

  useEffect(() => {
    if (socket) {
      socket.on('update-consignment', handleUpdateConsignment);
      socket.on('update-notes', (consignment: Consignment) => {
        handleUpdateConsignment(consignment);
        refreshConsignments();
        setUpdatedStatusCodes((curStatusCodes) => {
            return [...curStatusCodes, "Note added"];
        });
        const driverName = useGetDriverFullName(consignment);
        showSnack(`Driver ${driverName}  has separately added notes for #${consignment.consignmentId}`, 'info', {
          autoHideDuration: undefined,
        });
      });
      socket.on('failed-consignment', (consignment: Consignment) => {
        handleUpdateConsignment(consignment);
        refreshConsignments();
        showSnack(`Dead run entered by driver for #${consignment.consignmentId}`, 'warning', {
          autoHideDuration: undefined,
        });
      });
      socket.on('incomplete-consignment', (consignment: Consignment) => {
        handleUpdateConsignment(consignment);
        refreshConsignments();
        showSnack(`Incomplete consignment entered by driver for #${consignment.consignmentId}`, 'warning', {
          autoHideDuration: undefined,
        });
      });
      socket.on('complete-consignment', (consignment: Consignment) => {
        handleUpdateConsignment(consignment);
        refreshConsignments();
        showSnack(`Consignment Completed by driver #${consignment.consignmentId}`, 'success');
      });
      socket.on('cancel-consignment', (consignment: Consignment) => {
        handleUpdateConsignment(consignment);
        refreshConsignments();
        showSnack(`Consignment Cancelled for #${consignment.consignmentId}`, 'success');
      });
      socket.on('delete-consignment', (consignment: Consignment) => {
        setConsignments((curConsignments) => [
          ...curConsignments.filter((c) => c._id !== consignment._id), // filter out
        ]);
      });
    }

    return () => {
      if (socket) {
        socket.off('complete-consignment');
        socket.off('incomplete-consignment');
        socket.off('cancel-consignment');
        socket.off('update-consignment');
        socket.off('failed-consignment');
        socket.off('delete-consignment');
        socket.off('update-notes');

      }
    };
  }, [handleUpdateConsignment, refreshConsignments, showSnack, socket]);

  return (
    <ConsignmentContext.Provider
      value={{
        consignments,
        totalConsignments,
        isLoading,
        setTotalConsignments,
        setConsignments,
        setIsLoading,
        queryParams,
        refreshConsignments,
      }}
    >
      {children}
    </ConsignmentContext.Provider>
  );
};
