import { useCallback } from 'react';
import { Vehicle } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useVehicleApiRoutes() {
  const publicRoute = usePublicRoute();

  const list = useCallback(
    async (countryCode: string): Promise<Vehicle[]> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/vehicle`,
        params: { countryCode },
      });
      return response;
    },
    [publicRoute]
  );

  return { list };
}
