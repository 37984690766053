import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createStyles, makeStyles } from '@mui/styles';

import { Box, Typography, Button ,Theme, Chip} from '@mui/material';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/NavBar';
import { Colors } from '../../../pegasus/Colors';
import HomeIcon from '@mui/icons-material/Home';
import { CheckinSheetForm } from '../../../../types';
import useCheckinApiRoutes from '../../../hooks/api/useCheckinApiRoutes';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { TableFields } from './CheckinTable';
import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';





const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
      padding: theme.spacing(4),
      '& p, li': {
        lineHeight: 2,
      },
    },
  })
);

export default function DataTable() {
  const classes = styles();
  const [rows, setRows] = React.useState<CheckinSheetForm[]>([]);
  const [view, setView] = React.useState<any>();
  const { openDrawer, setCheckinSheet } = useContext(ModalContext);

  const { listByView } = useCheckinApiRoutes();
  const { viewId = '' } = useParams<'viewId'>();
  const handleClick = (checkinsheet: React.SetStateAction<CheckinSheetForm | undefined>) => {
    setCheckinSheet(checkinsheet);

    openDrawer('view-checkinsheet');
  };
  React.useEffect(() => {
    const fetchData = async () => {
      const { checkinsheets, view } = await listByView(viewId);
      setRows(checkinsheets);
      setView(view);
    }
    fetchData();
  },[]);
  return (
    <><Navbar showSettings={false}>
      <Box display="flex" alignItems="center">
        <Typography variant="linkLarge">
          <strong>UBF - {view?.createdBy?.firstName} {view?.createdBy?.lastName}</strong>
          <p style={{"margin": "0px","fontSize": "16px","fontWeight": "normal"}}>This 
           &nbsp; <strong>{view?.name}</strong> view has been shared by <strong>{view?.createdBy?.firstName} {view?.createdBy?.lastName}</strong></p>
          </Typography>
        
      
      </Box>
    </Navbar><Box className={classes.container}>
        {
          view?.filters.map((item:any) => (
            <>
            <Box display={"flex"}>
              <Chip label={TableFields.find((t)=>t.field==item.field)?.label} sx={{marginRight:'5px'}}></Chip> 
              {item.values.map((v:any)=> (
              <Chip label={v} sx={{marginRight:'3px'}}></Chip> 

              ))}

            </Box><br/>
            </>
          ))
        }
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Consignment</strong></TableCell>
                <TableCell align="left"><strong>Shipper</strong></TableCell>
                <TableCell align="left"><strong>Consignee</strong></TableCell>
                <TableCell align="left"><strong>Carrier</strong></TableCell>
                <TableCell align="left"><strong>Date Delivered</strong></TableCell>
                <TableCell align="left"><strong>Tags</strong></TableCell>
                <TableCell align="left"><strong>Consol</strong></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" onClick={() => handleClick(row)}>
                    <a href="javascript:void(0)">{row.consignmentId}</a>
                  </TableCell>
                  <TableCell align="left">{row.sender.companyName}</TableCell>
                  <TableCell align="left">{row.consignee.companyName}</TableCell>
                  <TableCell align="left">{row.deliveredBy}</TableCell>
                  <TableCell align="left">{row.defaultDeliveryDateTime ?  format(new Date(row.defaultDeliveryDateTime), 'd MMM, h:mm aa') : "-"}</TableCell>
                  <TableCell align="left">{row.tags? row.tags.join(", "):"-"}</TableCell>
                  <TableCell align="left">{row.console? row.console.join(", "):"-"}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box></>
  );

}