import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import useGetUserRoutePermissions from '../hooks/useGetUserRoutePermissions';

function OperationsViewButton() {
  const { countryCode = '' } = useParams<'countryCode'>();
  const { canSeeOperationsDashboard } = useGetUserRoutePermissions();

  return canSeeOperationsDashboard ? (
    <Button
      sx={{ alignSelf: 'baseline', flexShrink: 0, marginRight: 1 }}
      variant="contained"
      size="medium"
      to={`/operations/${countryCode}/dashboard`}
      component={Link}
    >
      <ArrowBackIcon sx={{ marginRight: 1 }} />
      Operations View
    </Button>
  ) : null;
}

export default OperationsViewButton;
