import React, { useContext, useState } from 'react';
import { AddressBookLocation, Consignment, User } from '../../../../../types';
import { Typography, Box, IconButton, Button } from '@mui/material';
import { Colors } from '../../../../pegasus/Colors';
import { PIcon } from '../../../../pegasus/PIcon';
import useUserApiRoutes from '../../../../hooks/api/useUserApiRoutes';
import SnackBarContext from '../../../../context/snackbar';
import ModalContext from '../../../../context/modal';
import useGetContextColor from '../../../../hooks/useGetContextColor';
import useConsignmentActions from '../../../../hooks/useConsignmentActions';
import ConsignmentContext from '../../../../context/consignments';
import { useGetDriver } from '../../../../hooks/useGetDriverName';
export const ConsignmentField = ({ consignment }: { consignment: Consignment }) => {
  const { openDrawer, setConsignment } = useContext(ModalContext);
  const contextColor = useGetContextColor(consignment);

  const viewConsignment = () => {
    setConsignment(consignment);
    openDrawer('view-consignment');
  };

  return (
    <Button onClick={viewConsignment} size="small" fullWidth>
      <Typography variant="linkSmall" noWrap color={contextColor} sx={{ textDecoration: 'underline' }}>
        {consignment.consignmentId}
      </Typography>
    </Button>
  );
};
export const DriverActions = ({ driver }: { driver: User }) => {
  const { openDrawer, setUser } = useContext(ModalContext);
  const [sendingInvite, setSendingInvite] = useState(false);
  const { resendDriverInvite } = useUserApiRoutes();
  const { truckUnassignment } = useConsignmentActions();
  const { consignments } = useContext(ConsignmentContext);

  const { showSnack } = useContext(SnackBarContext);
  const editDriver = () => {
    setUser(driver);
    openDrawer('edit-user');
  };
  const unassignTruck = () =>{
    const filterOut = consignments.filter((con) => {
      const dInfo =   (con?.assignedToDriverLeg2 as User) || (con?.assignedToDriverLeg1 as User);
      if(dInfo?.driverInformation?.currentRegistration == driver.driverInformation?.currentRegistration) {
        return con;
      }
    });
    truckUnassignment(driver,filterOut);
  };
  const resendInvite = () => {
    setSendingInvite(true);
    resendDriverInvite(driver._id)
      .then(() => {
        showSnack('Invite Sent!', 'success');
      })
      .finally(() => setSendingInvite(false));
  };
  return (
    <Box display="flex" justifyContent="flex-end">
     {driver.driverInformation?.currentRegistration?(
      <Button  size="small" onClick={unassignTruck} sx={{ marginRight: 5 }}> 
      <Typography color={Colors.error.dark} >
        Log off {driver.driverInformation?.currentRegistration}
        </Typography>
      </Button>
      ):null} 
      <Button size="small" onClick={resendInvite} sx={{ marginRight: 4 }} disabled={sendingInvite}>
        <Typography color={Colors.secondary.dark} sx={{ textDecoration: 'underline' }}>
          {sendingInvite ? 'Sending...' : 'Resend Invite'}
        </Typography>
      </Button>

      <IconButton onClick={editDriver}>
        <PIcon name="edit" size={24} sx={{ color: Colors.primary.dark }} />
      </IconButton>
    </Box>
  );
};
export const AddressField = ({ location }: { location: Pick<AddressBookLocation, 'companyName' | 'address'> }) => {
  return (
    <Typography variant="linkXSmall">
      {location.companyName}
      <Box fontWeight={400}>{location.address}</Box>
    </Typography>
  );
};
