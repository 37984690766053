import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';

const ReceiverSectionReadOnly = ({ contextColor }: { contextColor: string }) => {
  const { consignment } = useContext(ModalContext);
  return (
    <>
      <Typography variant="displayXSmallBold" color={contextColor} mb={3}>
        Receiver
      </Typography>
      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="COMPANY NAME" value={consignment?.receiver.companyName} />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="ADDRESS" value={consignment?.receiver.address} />
        </Grid>
      </Grid>
      {consignment?.receiver.additionalInfo ? (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="ADDITIONAL ADDRESS INFORMATION" value={consignment?.receiver.additionalInfo} />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="CONTACT NAME" value={consignment?.receiver.contactName} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="PHONE #" value={consignment?.receiver.phone} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="EMAIL" value={consignment?.receiver.email} />
        </Grid>
      </Grid>

      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={3}>
          <ReadOnlyField label="DELIVERY INSTRUCTIONS" value={consignment?.deliveryInstructions} />
        </Grid>
      </Grid>
      {consignment?.receiver.contactEmails && (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="ADDITIONAL EMAILS" value={consignment?.receiver.contactEmails.join(', ')} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReceiverSectionReadOnly;
