import React, { ChangeEvent, useContext,useState } from 'react';
import { TableField } from '../../../../types';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { Box, Typography } from '@mui/material';
import { AddressField, ConsignmentField } from './components';
import { format } from 'date-fns';
import { AssignDriverButton } from '../components/AssignDriverButton';
import ConsignmentContext from '../../../context/consignments';
import { Search } from '../../../components/form/Search';
import { Formik } from 'formik';
const TableFields: TableField[] = [
  {
    field: 'consignmentId',
    label: 'CONSIGNMENT #',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <ConsignmentField consignment={consignment} />,
  },
  {
    field: 'sender',
    label: 'ORIGIN',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.sender} />,
  },
  {
    field: 'receiver',
    label: 'DESTINATION',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.receiver} />,
  },
  {
    field: 'createdAt',
    label: 'CREATED',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => (
      <Typography variant="linkXSmall" fontWeight={400}>
        {format(new Date(consignment.createdAt), 'd MMM, h:mm aa')}
      </Typography>
    ),
  },
  {
    field: 'actions',
    label: '',
    columns: 2,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AssignDriverButton consignment={consignment} />,
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);

const UnAssignedTable = () => {
  const { consignments } = useContext(ConsignmentContext);
  const [consigmentFilter, setConsignmentFilter] = useState<{
    searchText: string;
  }>({ searchText: '' });
  return (
    <>
         <Formik
              validationDisabled={true}
              initialValues={consigmentFilter}
              onSubmit={setConsignmentFilter}
              enableReinitialize
            >
              {({ handleSubmit, handleChange }) => {
                const handleSearchChange = (
                  event: React.FormEvent<HTMLFormElement> | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  handleChange(event);
                  handleSubmit();
                };
                return (
                  <>
                    <Search
                      name="searchText"
                      placeholder="Search by Consignment, Company name, Origin, Destination"
                      onChange={handleSearchChange}
                      sx={{ width: '600px', marginTop: 0.5, marginBottom:1, marginRight: 1, border: 'solid lightgray 1px',borderRadius: '10px',boxShadow: '0px 0px 5px 0px lightgray' }}
                    />
                  </>
                );
              }}
      </Formik>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      <Box overflow="auto">
      {(consigmentFilter && !consigmentFilter.searchText.trim()?consignments
          .filter((c) => ['unassigned', 'atDepot', 'onHold'].includes(c.status)) :
          consignments.filter((c) => ['unassigned', 'atDepot', 'onHold'].includes(c.status) && (c.consignmentId.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase()) 
              || c.sender.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
              || c.receiver.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
              || c.receiver.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
              || c.sender.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
          )))
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((consignment) => {
            return (
              <TableRow
                key={consignment._id}
                consignment={consignment}
                tableFields={TableFields}
                columnCount={totalColumns}
              />
            );
          })}
      </Box>
    </>
  );
};

export default UnAssignedTable;
