import React from 'react';
import { Box, Theme ,Typography } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';
import { createStyles, makeStyles } from '@mui/styles';

const indicatorSize = 10;
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    label: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    indicator: {
      display:'inline-block',
      marginLeft:'8px',
      width: indicatorSize,
      height: indicatorSize,
      borderRadius: indicatorSize,
      backgroundColor: theme.palette.error.dark,
    },
  })
);
export type ReadOnlyFieldProps = { label: string; value?: string; textColor?: string; showIndicator?:boolean; };
export const ReadOnlyField = ({ label, value, textColor = Colors.greyscale.offBlack, showIndicator }: ReadOnlyFieldProps) => {
  const classes = styles();
  return (
    <>
      <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
        {label} {showIndicator ? <Typography className={classes.indicator}></Typography> : null}
      </Typography>

      {value ? (
        <>
          <br />
          <Typography variant="textSmall" color={textColor} fontWeight={400}>
            {value}
          </Typography>
        </>
      ) : null}
    </>
  );
};
