import { useContext } from 'react';
import { CheckinSheetForm, Consignment } from '../../types';
import UserContext from '../context/user';
import useGetOrderType from './useGetOrderType';
import useGetStatus from './useGetStatus';

export default function useGetContextColor(consignment: Consignment | undefined ) {
  const { user } = useContext(UserContext);
  const getStatus = useGetStatus();
  const getOrderType = useGetOrderType();
  if (user?.type === 'dispatch') {
    const orderType = getOrderType(consignment);
    return orderType?.contextColor;
  } else {
    const status = getStatus(consignment);
    return status?.contextColor;
  }
}

