import React, { useMemo, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';
import { Colors } from '../../pegasus/Colors';
import { PIcon } from '../../pegasus/PIcon';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 16,
      height: '60px',
    },
    input: {
      paddingTop: 10,
      paddingBottom: 10,
      '&::placeholder': {
        color: Colors.greyscale.label,
        opacity: 1,
      },
    },
    grey: {
      backgroundColor: Colors.grey.bg,
    },
    white: {
      backgroundColor: 'white',
    },
  })
);

export const Search = ({
  onChange,
  bgColor = 'grey',
  name,
  ...otherProps
}: {
  bgColor?: 'white' | 'grey';
} & TextFieldProps &
  Required<Pick<TextFieldProps, 'onChange'>>) => {
  const [userQuery, setUserQuery] = useState('');
  const delayedQuery = useMemo(() => debounce(onChange, 500), [onChange]);
  const classes = useStyles();
  const { handleChange } = useFormikContext();
  const handleSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUserQuery(event.target.value);
    delayedQuery(event);
    handleChange(event);
  };
  return (
    <TextField
      name={name ? name : 'searchText'}
      variant="standard"
      value={userQuery}
      onChange={handleSearchChange}
      InputProps={{
        disableUnderline: true,
        startAdornment: <PIcon name="searchIcon" sx={{ marginRight: 1, marginLeft: 3 }} />,
        classes: { root: `${classes.root} ${classes[bgColor]}`, input: classes.input },
      }}
      {...otherProps}
    />
  );
};
