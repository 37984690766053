export type ConsignmentType = {
    name: string;
    code: string;
  
  };
  const consignmentTypes:Array<ConsignmentType> = [
    {
      name:"Air",
      code:"Air"
    },
    {
      name:"Sea",
      code:"Sea"
    },
    {
      name:"Unknown",
      code:"Unknown"
    }
  ]
  export default consignmentTypes;
