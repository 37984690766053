import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Consignment, TableField, User } from '../../../../types';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { AddressField, ConsignmentField } from './components';
import ConsignmentContext from '../../../context/consignments';
import { ArchiveButton, CheckInField } from '../../Operations/tables/components';
import { CheckInSheetButton } from '../components/CheckInSheetButton';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme} from '@mui/material';
import { Search } from '../../../components/form/Search';
import { Formik } from 'formik';
import DepotContext from '../../../context/depot';
import { Colors } from '../../../pegasus/Colors';
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
    },
    tabContent: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    darkButton: {
      backgroundColor: Colors.primary.darkMode,
      marginRight: theme.spacing(1),
    },
    select: {
      width: 200,
    },
  })
);
const TableFields: TableField[] = [
  {
    field: 'consignmentId',
    label: 'CONSIGNMENT #',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <ConsignmentField consignment={consignment} />,
  },
  {
    field: 'sender',
    label: 'ORIGIN',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.sender} />,
  },
  {
    field: 'receiver',
    label: 'DESTINATION',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.receiver} />,
  },
  {
    field: 'checkInSheet.screenAtDate',
    label: 'CHECK IN SHEET',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <CheckInField consignment={consignment} />,
  },
  {
    field: 'actions',
    label: '',
    columns: 2,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => {
      return (
        <>
        <CheckInSheetButton consignment={consignment} />
        <ArchiveButton consignment={consignment} />
      </>
      )

    },
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);

const CompletedTable = () => {
  const classes = styles();
  const { drivers } = useContext(DepotContext);
  const { consignments } = useContext(ConsignmentContext);
  const [selectedDriver, setSelectedDriver] = useState('all');
  const [selectedCheckinType, setCheckinType] = useState('all');

  const [consigmentFilter, setConsignmentFilter] = useState<{
    searchText: string;
  }>({ searchText: '' });


  return (
    <>
        <Formik
              validationDisabled={true}
              initialValues={consigmentFilter}
              onSubmit={setConsignmentFilter}
              enableReinitialize
            >
              {({ handleSubmit, handleChange }) => {
                const handleSearchChange = (
                  event?: React.FormEvent<HTMLFormElement> | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  handleSubmit();
                };
                const handleFilterChange = ({ target: { value } }: SelectChangeEvent<string>) => {
                  setSelectedDriver(value);
                  handleSubmit();
                };
                const handleCheckinChange = ({ target: { value } }: SelectChangeEvent<string>) => {
                  setCheckinType(value);
                  handleSubmit();
                };
                return (
                  <>
                    <Search
                      name="searchText"
                      placeholder="Search by Consignment, Company name, Origin, Destination"
                      onChange={handleSearchChange}
                      sx={{ width: '600px', marginTop: 0.5, marginBottom:1, marginRight: 1, border: 'solid lightgray 1px',borderRadius: '10px',boxShadow: '0px 0px 5px 0px lightgray' }}
                    />
                    <div style={{marginBottom:2}}>
                      <FormControl sx={{ width: 200, marginTop: 0.5, marginRight: 1, marginBottom: 0.5, border: 'solid lightgray 1px',borderRadius: '10px',boxShadow: '0px 0px 5px 0px lightgray'}}>
                        <InputLabel>Filter by Driver</InputLabel>
                        <Select
                          variant="filled"
                          value={selectedDriver}
                          onChange={handleFilterChange}
                          className={classes.select}
                          disableUnderline={true}
                          name=""
                        >
                          <MenuItem value="all">
                            <em>All</em>
                          </MenuItem>
                          {drivers.map((driver) => (
                            <MenuItem key={driver._id} value={driver._id}>
                              {driver.firstName} {driver.lastName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: 200, marginTop: 0.5, marginBottom: 0.5, border: 'solid lightgray 1px',borderRadius: '10px',boxShadow: '0px 0px 5px 0px lightgray'}}>
                        <InputLabel>Filter by CheckIn</InputLabel>
                        <Select
                          variant="filled"
                          value={selectedCheckinType}
                          onChange={handleCheckinChange}
                          className={classes.select}
                          disableUnderline={true}
                          name=""
                        >
                          <MenuItem value="all">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="true">
                            <em>Not Checked-In</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                  </div>
                  </>
                );
              }}
      </Formik>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      <Box overflow="auto">
        {(consigmentFilter && !consigmentFilter.searchText.trim()?consignments
          .filter((c) => ['complete'].includes(c.status)) :
          consignments.filter((c) => ['complete'].includes(c.status) && (c.consignmentId.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase()) 
                    || c.sender.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
                    || c.receiver.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
                    || c.receiver.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
                    || c.sender.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
                  )))
          .filter((c) =>
              (c.assignedToDriverLeg1Id == selectedDriver || c.assignedToDriverLeg2Id == selectedDriver || selectedDriver == 'all') 
              && ( selectedCheckinType == "all" ?true: (!c.checkInSheet?.recievedBySignature && c.orderType=='pick-up'))
          )
          
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((consignment) => {
            return (
              <TableRow
                key={consignment._id}
                consignment={consignment}
                tableFields={TableFields}
                columnCount={totalColumns}
              />
            );
          })}
      </Box>
    </>
  );
};

export default CompletedTable;
