import { useContext } from 'react';
import ModalContext, { ConfirmationServiceContext } from '../context/modal';
import { Consignment, StatusCode, User } from '../../types';
import useConsignmentApiRoutes from './api/useConsignmentApiRoutes';
import SnackBarContext from '../context/snackbar';
import useUserApiRoutes from './api/useUserApiRoutes';

export default function useConsignmentActions() {
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { cancelAssignment, archiveConsignment, cancelAndArchiveConsignment, moveToUnAssigned: moveToUnAssignedRoute, statusUpdate } = useConsignmentApiRoutes();
  const { editProfile } = useUserApiRoutes();

  const { showSnack } = useContext(SnackBarContext);
  const { setConsignment } = useContext(ModalContext);

  const archivedConsignment = async (consignment: Consignment) => {
    const res = await showConfirmationModal({
      title: 'Archive consignment?',
      contentText: 'Are you sure you want to archive consignment? This cannot be undone',
    });
    if (res && res.confirmed === true) {
      try {
        const archivedConsignment = await archiveConsignment(consignment);
        // in case cancelled from a modal window
        setConsignment(archivedConsignment);
        setOpenId(undefined);
        showSnack('Consignment Archived', 'success');
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };

  const cancelConsignment = async (consignment: Consignment) => {
    const res = await showConfirmationModal({
      title: 'Cancel Assignment?',
      contentText: 'Canceling will remove the assigned driver and set the status to Unassigned',
    });
    if (res && res.confirmed === true) {
      try {
        const canceledConsignment = await cancelAssignment(consignment);
        // in case cancelled from a modal window
        setConsignment(canceledConsignment);
        setOpenId(undefined);
        showSnack('Assignment Cancelled', 'success');
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };

  const cancelArchiveConsignment = async (consignment: Consignment) => {
    const res = await showConfirmationModal({
      title: 'Cancel Consignment?',
      contentText: 'Canceling will cancel & archive the consigment as well as the driver will remove the assigned driver',
    });
    if (res && res.confirmed === true) {
      try {
        const canceledConsignment = await cancelAndArchiveConsignment(consignment);
        // in case cancelled from a modal window
        setConsignment(canceledConsignment);
        setOpenId(undefined);
        showSnack('Consigment Cancelled & Archived', 'success');
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };

  const moveToUnAssigned = async (consignment: Consignment) => {
    const res = await showConfirmationModal({
      title: 'Move to UnAssigned?',
      contentText: 'Canceling the hold will remove the assigned driver (if any) and set the status to Unassigned',
    });
    if (res && res.confirmed === true) {
      try {
        const updatedConsignment = await moveToUnAssignedRoute(consignment);
        // in case cancelled from a modal window
        setConsignment(updatedConsignment);
        setOpenId(undefined);
        showSnack('Consignment Updated', 'success');
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };

  const moveToInTransit = async (consignment: Consignment) => {
    let toStatus: StatusCode | null = null;
    if (consignment.orderType === 'drop-off' && consignment.status === 'assigned') {
      toStatus = 'inTransit';
    } else if (consignment.orderType === 'transfer' && consignment.status === 'assignedLeg2') {
      toStatus = 'inTransitLeg2';
    }
    changeStatus({
      consignment: consignment,
      toStatus: toStatus,
      title: 'Move to In Transit?',
      contentText: 'Only move to In Transit if you are sure that the consignment have been picked up by the driver',
    });
  };

  const changeStatus = async ({
    title,
    contentText,
    consignment,
    toStatus,
  }: {
    title: string;
    contentText: string;
    consignment: Consignment;
    toStatus: StatusCode | null;
  }) => {
    if (toStatus) {
      const res = await showConfirmationModal({
        title: title,
        contentText: contentText,
      });
      if (res && res.confirmed === true) {
        try {
          const updatedConsignment = await statusUpdate(consignment._id, toStatus);
          // in case cancelled from a modal window
          setConsignment(updatedConsignment);
          setOpenId(undefined);
          showSnack('Consignment Updated', 'success');
        } catch (error) {
          // setOpenId(undefined);
        }
      }
    } else {
      await showConfirmationModal({
        title: `Cannot move to ${toStatus}`,
        contentText: `This order type: "${consignment.orderType}" with current status:
        "${consignment.status}" is not allowed to be moved to ${toStatus}`,
        hideOk: true,
      });
    }
  };

  const moveToAtDepot = async (consignment: Consignment) => {
    let toStatus: StatusCode | null = null;
    if (consignment.orderType === 'transfer' && consignment.status === 'inTransit') {
      toStatus = 'atDepot';
    }
    changeStatus({
      consignment: consignment,
      toStatus: toStatus,
      title: 'Move to At Depot?',
      contentText:
        'Only move to At Depot if you are sure that the consignment has been receipted into the depot by the driver',
    });
  };

  const truckUnassignment = async (user:User, consignment:Consignment | Consignment[]) => {
    const res = await showConfirmationModal({
      title: "Unassign Truck",
      contentText: "Are you sure you want to unassign the truck from the driver?",
    });
    if (res && res.confirmed === true) {
      try {
        delete user.driverInformation?.currentRegistration;
        const updatedUser = await editProfile(user);
        // in case cancelled from a modal window
        if(!Array.isArray(consignment)){
            const updatedConsignment = await moveToUnAssignedRoute(consignment);
            // in case cancelled from a modal window
            setConsignment(updatedConsignment);
        } else {
          consignment.filter((item) => item.status != 'complete').forEach(async (con) => {
            const updatedConsignment = await moveToUnAssignedRoute(con);
            // in case cancelled from a modal window
            setConsignment(updatedConsignment);
          })
        }
        setOpenId(undefined);
        showSnack('Truck has been unassigned', 'success');
        showSnack('Consignment Updated', 'success');
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };
  return { cancelConsignment, cancelArchiveConsignment, moveToUnAssigned, archivedConsignment, moveToInTransit, moveToAtDepot, truckUnassignment };
}
