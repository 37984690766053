import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CheckinSheetForm } from '../../types';
import UserContext from './user';
import useConsignmentApiRoutes, { ConsignmentQueryParams } from '../hooks/api/useConsignmentApiRoutes';
import SocketContext from './socket';
import SnackBarContext from './snackbar';
import ModalContext from './modal';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../constants/table';
import { useGetDriverFullName } from '../hooks/useGetDriverName';
import useCheckinApiRoutes, { CheckinSheetQueryParams } from '../hooks/api/useCheckinApiRoutes';
import ViewContext from './view';
import useViewApiRoutes from '../hooks/api/useViewApiRoutes';
interface Filter {
  field: string;
  values: any[];  // Replace `any` with more specific type if possible
}

const CheckinSheetContext = React.createContext<{
  checkins: CheckinSheetForm[];
  totalCheckinSheets: number;
  isLoading: boolean;
  setTotalCheckinsheets: React.Dispatch<React.SetStateAction<number>>;
  setCheckinsheets: React.Dispatch<React.SetStateAction<CheckinSheetForm[]>>;
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  checkinQueryParams: CheckinSheetQueryParams;
  configfilters:Filter[];
  setFilterContext:(value:any) => void;
  refreshConsignments: () => Promise<void | CheckinSheetForm[]>;
}>({
  checkins: [],
  totalCheckinSheets: 0,
  isLoading: false,
  setTotalCheckinsheets: () => undefined,
  setCheckinsheets: () => undefined,
  setIsLoading: () => undefined,
  checkinQueryParams: { countryCode: 'NZ', page: DEFAULT_PAGE, itemsPerPage: ITEM_PER_PAGE,filters:[]},
  configfilters:[],
  setFilters:() => undefined,
  setFilterContext:(value:any) => undefined,
  refreshConsignments: () => Promise.resolve([]),
});

export default CheckinSheetContext;

export const CheckinSheetProvider = ({
  children,
  checkinQueryParams,
}: {
  children: React.ReactNode;
  checkinQueryParams: CheckinSheetQueryParams;
}) => {
  const { isLoggedIn } = useContext(UserContext);
  const { saveView } = useContext(ViewContext);

  const { checkinsheet: modalConsignment, setCheckinSheet } = useContext(ModalContext);
  const { socket, setUpdatedStatusCodes } = useContext(SocketContext);
  const [checkins, setCheckinsheets] = useState<CheckinSheetForm[]>([]);
  const [configfilters, setFilters] = useState<Filter[]>([]);

  const [totalCheckinSheets, setTotalCheckinsheets] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { listCheckinSheet: listCheckinSheet } = useCheckinApiRoutes();
  const { listView } = useViewApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const active = useRef(true);

  const queryParamsJson = JSON.stringify(checkinQueryParams);
  const setFilterContext = useCallback((value:any) => {
    setFilters(value);
  }, [isLoggedIn])
  const refreshConsignments = useCallback(async (): Promise<void | CheckinSheetForm[]> => {
    if (isLoggedIn) {
      setIsLoading(true);
      const { checkinsheets, total } = await listCheckinSheet(JSON.parse(queryParamsJson));
      if (active.current) {
        checkinsheets.map((item) => item.documentsSrc = item.documents ? JSON.parse(JSON.stringify(item.documents)) : []);
        setCheckinsheets(checkinsheets);
        setTotalCheckinsheets(total);
   
      }
      setIsLoading(false);
      return checkinsheets;
    }
  }, [isLoggedIn, listCheckinSheet, queryParamsJson]);

  useEffect(() => {
    refreshConsignments();
  }, [refreshConsignments]);

  const handleUpdateConsignment = useCallback(
    (checkinsheet: CheckinSheetForm) => {
      if (modalConsignment && modalConsignment._id === checkinsheet._id) {
        // also update the modalConsignment with taht from socket
        setCheckinSheet(checkinsheet);
      }
      setCheckinsheets((curConsignments) => {
        const curIndex = curConsignments.findIndex((c) => c._id === checkinsheet._id);
        if (curIndex === -1) {
          // add to array
          return [checkinsheet, ...curConsignments];
        } else {
          // replace with new value
          curConsignments.splice(curIndex, 1, checkinsheet);
          return [...curConsignments];
        }
      });
      setUpdatedStatusCodes((curStatusCodes) => {
        if (curStatusCodes.includes(checkinsheet.status)) {
          return curStatusCodes;
        } else {
          return [...curStatusCodes, checkinsheet.status];
        }
      });
    },
    [modalConsignment, setCheckinSheet, setUpdatedStatusCodes]
  );

  useEffect(() => {
    if (socket) {
      socket.on('update-checkinsheet', handleUpdateConsignment);
  

      socket.on('complete-checkinsheet', (checkinsheet: CheckinSheetForm) => {
        handleUpdateConsignment(checkinsheet);
        refreshConsignments();
        showSnack(`CheckinSheetForm Completed by driver #${checkinsheet.consignmentId}`, 'success');
      });
      socket.on('cancel-checkinsheet', (checkinsheet: CheckinSheetForm) => {
        handleUpdateConsignment(checkinsheet);
        refreshConsignments();
        showSnack(`CheckinSheetForm Cancelled for #${checkinsheet.consignmentId}`, 'success');
      });
      socket.on('delete-checkinsheet', (checkinsheet: CheckinSheetForm) => {
        setCheckinsheets((currCheckinSheets) => [
          ...currCheckinSheets.filter((c) => c._id !== checkinsheet._id), // filter out
        ]);
      });
    }

    return () => {
      if (socket) {
        socket.off('complete-checkinsheet');
        socket.off('incomplete-checkinsheet');
        socket.off('cancel-checkinsheet');
        socket.off('update-checkinsheet');
        socket.off('failed-checkinsheet');
        socket.off('delete-checkinsheet');
        socket.off('update-notes');

      }
    };
  }, [handleUpdateConsignment, refreshConsignments, showSnack, socket]);
  
  return (
    <CheckinSheetContext.Provider
      value={{
        checkins,
        configfilters,
        totalCheckinSheets,
        isLoading,
        setTotalCheckinsheets,
        setCheckinsheets,
        setFilters,
        setIsLoading,
        setFilterContext,
        checkinQueryParams,
        refreshConsignments,
      }}
    >
      {children}
    </CheckinSheetContext.Provider>
  );
};
