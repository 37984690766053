import React, { useContext, useState } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import { View } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useViewApiRoutes from '../../../hooks/api/useViewApiRoutes';
import ShareViewForm from './ShareViewForm';
import CheckinSheetContext from '../../../context/checkins';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined'; // Ensure you've installed @mui/icons-material

const ShareViewModal = ({ view }: any) => {
    const { closeDrawer, viewFilter } = useContext(ModalContext);
    const { updateView } = useViewApiRoutes();
    const { showSnack } = useContext(SnackBarContext);
    const [value] = useState(`https://trucking.ubfreight.com/views/${view._id}`);

    const handleCopy = () => {
      navigator.clipboard.writeText(value).then(() => {
        // Handle successful copy (show a tooltip, snackbar, or message)
      }, () => {
        // Handle copy error (show an error message)
      });
    };
    const handleSubmit = (values: Partial<View>, { setSubmitting }: FormikHelpers<Partial<View>>) => {
        console.log(values);
        updateView(values)
            .then((view) => {
                setSubmitting(false);
                showSnack('View Updated!', 'success');
                closeDrawer('share-view');

            })
            .catch(() => setSubmitting(false));
    };

    return (
        <ShareViewForm
            onSubmit={handleSubmit}
            initialValues={view}
        >
            <TextField
                disabled
                fullWidth
                variant="outlined"
                value={value}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleCopy} edge="end">
                                <CopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <br/><br/>
            <SubmitButton disabled={false} color="primary" sx={{ marginRight: 1 }}>
                Update View
            </SubmitButton>
        </ShareViewForm>
    );
};

export default ShareViewModal;
