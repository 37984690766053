import { Box, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { AddressBook } from '../../../../types';
import { PCheckbox } from '../../../pegasus/PCheckbox';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import AutoCompleteAddress from '../../../components/form/AutoCompleteAddress';

type Props = {
  initialValues: Partial<AddressBook>;
  onSubmit: (
    values: Partial<AddressBook>,
    formikHelpers: FormikHelpers<Partial<AddressBook>>
  ) => void | Promise<AddressBook>;
  children: React.ReactNode;
};

const AddressBookForm = ({ initialValues, onSubmit, children }: Props) => {
  const classes = useStyles();
  const validate = (values: Partial<AddressBook>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof AddressBook]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (typeof values[k] !== 'boolean' && !values[k] && k !== 'areaZone') {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <>
            <form onSubmit={handleSubmit} className={classes.form}>
              <PTextField name="companyName" fullWidth label="Company Name" />
              <AutoCompleteAddress name="address" fullWidth label="Address" />
              <PTextField name="additionalInfo" fullWidth label="Additional Info" />
              <PTextField name="contactName" fullWidth label="Contact Name" />
              <PTextField name="email" fullWidth label="Email" type="email" />
              <PhoneNumberInput name="phone" fullWidth label="Phone #" />
              <Grid container spacing={0}>
                {/* <Grid item xs={12}>
                  <PCheckbox
                    name="pickUp"
                    label="Pick-up Emails"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <PCheckbox name="milestones" label="Milestone Emails" />
                </Grid>
                {/* <Grid item xs={12}>
                  <PCheckbox
                    name="tracking"
                    label="Tracking Emails"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <PCheckbox name="labels" label="Emails A4 Label" />
                </Grid>
                {/* <Grid item xs={12}>
                  <PCheckbox
                    name="consignment"
                    label="Email Consignment"
                  />
                </Grid> */}
              </Grid>
              <Box marginTop={4}>{children}</Box>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddressBookForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
