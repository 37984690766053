import { useCallback } from 'react';
import { User } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useAuthApiRoutes() {
  const publicRoute = usePublicRoute();

  const signIn = useCallback(
    async (email: string, password: string, countryCode: string, token:string): Promise<User> => {
      
      const response =
      !token ?
      await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/signin`,
        data: { email, password, countryCode },
      }) : 
      await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/sso-signin`,
        data: { email:token, countryCode:countryCode },
      });
      return response;
    },
    [publicRoute]
  );

  const signOut = useCallback(async (): Promise<undefined> => {
    const response = await publicRoute({
      method: 'get',
      url: `${REACT_APP_API_BASE_URL}/signout`,
    });
    return response;
  }, [publicRoute]);

  const resetInstructions = useCallback(
    async (email: string): Promise<undefined> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/reset-instructions`,
        data: { email },
      });
      return response;
    },
    [publicRoute]
  );
  const resetPassword = useCallback(
    async (password: string, password2: string, token: string): Promise<undefined> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/reset-password`,
        data: { password, password2, token },
      });
      return response;
    },
    [publicRoute]
  );

  const getAuthState = useCallback(async (): Promise<User | undefined> => {
    const response = await publicRoute({
      method: 'get',
      url: `${REACT_APP_API_BASE_URL}/state`,
    });
    return response.user;
  }, [publicRoute]);

  return { getAuthState, signIn, signOut, resetInstructions, resetPassword };
}
