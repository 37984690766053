import React, { useState } from 'react';
import { Checkbox, Grid, Theme, Typography } from '@mui/material';
import { CheckinSheetForm, TableField, TableFieldCheckin } from '../../../../types';
import get from 'lodash/get';
import { format } from 'date-fns';
import { createStyles, makeStyles } from '@mui/styles';
import useGetContextColor from '../../../hooks/useGetContextColor';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Import the icon
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 6,
      overflow: 'hidden',
      marginBottom: 10,
    },
    firstColumn: {
      borderLeftStyle: 'solid',
      borderLeftWidth: 6,
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 18,
      paddingBottom: 20,
      paddingLeft: 18,
      paddingRight: 16,
    },
  })
);

const CheckinTableRow = ({
  checkinsheet,
  tableFields,
  columnCount,
  onChange
}: {
  checkinsheet: CheckinSheetForm;
  tableFields: TableFieldCheckin[];
  columnCount: number;
  onChange:(value:any) => void;
}) => {
  const classes = styles();
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChange = (selectedId:string) => {
    const selectedSet = new Set(selectedIds);
    if(selectedSet.has(selectedId)){
      selectedSet.delete(selectedId);
    }
    else {
      selectedSet.add(selectedId);
    }
    setSelectedIds([...selectedSet]);
    onChange([...selectedSet])
  };
  return (
    <>
      <Grid container columns={columnCount} className={classes.container}>
        {tableFields.map((tableField, index) => {
          let fieldData = get(checkinsheet, tableField.field, '');
          if (tableField.formatDate === true && fieldData) {
            fieldData = format(new Date(fieldData), 'd MMM, h:mm aa');
          }
          if (tableField.commaString === true && fieldData) {
            fieldData = fieldData.join(",");
          }
          const rowClasses = [classes.cell];
          if (index === 0) {
            rowClasses.push(classes.firstColumn);
          }
          return (
            <Grid
              key={index}
              item
              xs={tableField.columns}
              className={rowClasses.join(' ')}
              sx={checkinsheet.goodsType =='dangerous'? { borderLeftColor: 'red' , borderLeftWidth:'4px' } :{}}

            >
            {/*     {checkinsheet.goodsType === 'dangerous' && (
                  <WarningAmberIcon color="error" sx={{ mr: 1 }} /> // Add the icon for dangerous items
                )} */}
              {tableField.renderField ? (
                tableField.renderField(checkinsheet)
    
               
              ) : (
                <Typography variant="linkXSmall">
                  {(tableField.field=="consignmentId" )?<Checkbox onChange={() => handleChange(checkinsheet._id)}></Checkbox>:null}
                  {fieldData}
                {checkinsheet.goodsType =='dangerous' && tableField.field=="consignmentId"? <WarningAmberIcon color="error" sx={{ mr: 1,position:'absolute',ml:2 }} />:null}
                {checkinsheet.consignmentUid && tableField.field=="consignmentId"? <LocalShippingOutlinedIcon color="success" sx={{ mr: 1,position:'absolute',ml:3,marginTop:"10px" }} />:null}

                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CheckinTableRow;
