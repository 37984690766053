import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { User } from '../../../types';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import { PSelectAsync, PSelectAsyncProps } from '../../pegasus/PSelectAsync';
import get from 'lodash/get';
import { getFullName } from '../../hooks/useGetDriverName';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/base';

interface OptionType {
  addNew?: boolean;
  _id: string;
  firstName: string;
  lastName: string;
}

const filter = createFilterOptions<OptionType>();

export default function UserSelect(
  props: {
    name: string;
    label: string;
    depot: string;
    onAddUser?: () => void;
  } & Partial<PSelectAsyncProps>
) {
  const [options, setOptions] = useState<User[]>([]);
  const { name, label, depot, onAddUser, ...orderProps } = props;
  const { setFieldValue, values } = useFormikContext();
  const value = get(values, name, null);
  const selectedOption = options.find((o) => o._id === value) || null;
  const { list } = useUserApiRoutes();
  const loading = options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    list({
      depots: [depot],
      unlimit: true,
    }).then(({ users }) => {
      if (active) {
        setOptions(users);
      }
    });
    return () => {
      active = false;
    };
  }, [depot, list, loading]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, option: unknown) => {
    if (option === null) {
      setFieldValue(name, '');
    } else if ((option as OptionType).addNew && onAddUser) {
      onAddUser();
    } else if ((option as User)._id) {
      setFieldValue(name, (option as User)._id);
    }
  };

  const filterOptions = onAddUser
    ? (options: unknown[], params: FilterOptionsState<unknown>) => {
        const filtered = filter(options as User[], params);
        // Suggest the creation of a new value
        filtered.push({
          addNew: true,
          _id: 'add_new',
          firstName: 'Add New',
          lastName: '',
        });
        return filtered;
      }
    : undefined;

  return (
    <PSelectAsync
      name={name}
      label={label}
      value={selectedOption}
      options={options}
      getOptionLabel={(option: unknown) => getFullName(option as User) || ''}
      isOptionEqualToValue={(option, value) => {
        return value ? (option as User)._id === (value as User)._id : false;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={(option as User)._id}>
            {getFullName(option as User) || ''}
          </li>
        );
      }}
      onChange={handleChange}
      filterOptions={filterOptions}
      {...orderProps}
    />
  );
}
