import { useFormikContext } from 'formik';
import React from 'react';
import { PSelectAsync } from '../../pegasus/PSelectAsync';
import get from 'lodash/get';
import orderTypes, { OrderType } from '../../constants/orderTypes';

export default function OrderTypeSelect({
  name,
  label,
  onChange,
}: {
  name: string;
  label: string;
  onChange?: () => void;
}) {
  const { setFieldValue, values } = useFormikContext();
  const value = get(values, name, null) || '';
  const selectedOption = [{ name: 'None', code: '' }, ...orderTypes].find((o) => o.code === value) || null;

  const handleChange = (event: React.SyntheticEvent<Element, Event>, option: unknown) => {
    if (option === null) {
      setFieldValue(name, '');
    } else if ((option as OrderType).code) {
      setFieldValue(name, (option as OrderType).code);
    }
    if (onChange) {
      onChange();
    }
  };

  return (
    <PSelectAsync
      name={name}
      label={label}
      value={selectedOption}
      options={[{ name: 'None', code: '' }, ...orderTypes]}
      getOptionLabel={(option: unknown) => (option as OrderType).name || ''}
      isOptionEqualToValue={(option, value) => {
        return value ? (option as OrderType).code === (value as OrderType).code : false;
      }}
      renderOption={(props, option) => {
        return (
          <>
            <li {...props} key={(option as OrderType).code}>
              {(option as OrderType).code === '' ? <em>{(option as OrderType).name}</em> : (option as OrderType).name}
            </li>
          </>
        );
      }}
      onChange={handleChange}
    />
  );
}
