import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';

const SenderSectionCheckinReadOnly = () => {
  const { checkinsheet } = useContext(ModalContext);
  return (
    <>
      <Typography variant="displayXSmallBold"  mb={3}>
        Sender
      </Typography>
      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="COMPANY NAME" value={checkinsheet?.sender.companyName} />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="ADDRESS" value={checkinsheet?.sender.address} />
        </Grid>
      </Grid>
      {checkinsheet?.sender.additionalInfo ? (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="ADDITIONAL ADDRESS INFORMATION" value={checkinsheet?.sender.additionalInfo} />
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="PO NUMBER" value={checkinsheet?.sender.poNumber} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="REFERENCE" value={checkinsheet?.sender.references} />
        </Grid>
    
      </Grid>

    </>
  );
};

export default SenderSectionCheckinReadOnly;
