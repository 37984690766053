import React, { useContext, useEffect, useState } from 'react';
import { Button, Theme, IconButton, Box, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ModalContext from '../../../context/modal';
import { PIcon } from '../../../pegasus/PIcon';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Colors } from '../../../pegasus/Colors';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1.5),
    },
    greyButton: {
      backgroundColor: Colors.greyscale.offBlack,
      opacity: 0.5,
    },
    dialogPaper: {
      minWidth: 600,
      borderRadius: 0,
      padding: theme.spacing(5),
      backgroundColor: 'white',
      maxWidth : 950
    },
    container: {
      backgroundColor: 'white',
    },
  })
);

const ViewPdfModal = ({
  getPdfDocument,
  children,
  open,
  setOpen,
  printButtonText,
  totalLabel,
  type
}: {
  getPdfDocument: (consignmentId: string, params: { totalLabel?: number }) => Promise<string>;
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  printButtonText: string;
  totalLabel?: number;
  type?:string;
}) => {
  const { consignment,checkinsheet } = useContext(ModalContext);
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if ((type=='third-party-checkin' || type=='consignment-checkin' || !type)  && open) {
      let id = (type=='third-party-checkin' || !type ? checkinsheet?._id  ?? consignment?._id :  checkinsheet?.consignmentUid  ?? consignment?._id  ) ?? "";
      getPdfDocument(id, { totalLabel }).then((response) => {
        const blob = base64toBlob(response);
        const blobUrl = URL.createObjectURL(blob);
        setFileUrl(blobUrl);
      });
    } else {
      setFileUrl('');
    }
  }, [consignment, getPdfDocument, open, totalLabel]);

  const handleClose = () => {
    setOpen(false);
  };

  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  };

  const handleOnPrint = () => {
    if (fileUrl) {
      const w = window.open(fileUrl);
      if (w) {
        w.print();
      }
    }
  };

  return (consignment??checkinsheet) ? (
    <>
      {children}
      <PdfDialog
        open={open}
        onClose={handleClose}
        onPrint={handleOnPrint}
        fileUrl={fileUrl}
        printButtonText={printButtonText}
      />
    </>
  ) : null;
};

export interface PdfDialogProps {
  open: boolean;
  fileUrl: string;
  printButtonText: string;
  onClose: () => void;
  onPrint: () => void;
}

const PdfDialog = ({ onClose, open, fileUrl, onPrint, printButtonText }: PdfDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: classes.dialogPaper }}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Button variant="contained" onClick={onPrint}>
          <PIcon name="printer" sx={{ marginRight: 1.5 }} />
          {printButtonText}
        </Button>
        <IconButton size="medium" onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box></Box>
      {fileUrl ? (
        <Box id="lables-a4" display="flex" className={classes.container}>
          <Document file={fileUrl}>
            <Page pageNumber={1} />
          </Document>
        </Box>
      ) : (
        <CircularProgress sx={{ alignSelf: 'center' }} />
      )}
    </Dialog>
  );
};

export default ViewPdfModal;
