import React, { useContext } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';
import { DeadRun } from '../../../../types';
import { format } from 'date-fns';
import Carousel from '../../../components/Carousel';

const DeadRunRow = ({ deadRun }: { deadRun: DeadRun }) => {
  return (
    <Grid container spacing={1} mb={5} columns={20}>
      <Grid item xs={3}>
        <ReadOnlyField label="CREATED AT" value={format(new Date(deadRun.createdAt), 'd MMM, h:mm aa')} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyField label="DRIVER" value={deadRun.createdBy} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyField label="REASON" value={deadRun.reason} />
      </Grid>
      <Grid item xs={8}>
        <Carousel photos={deadRun.photos} />
      </Grid>
    </Grid>
  );
};

const OrderDeadRunsSection = ({ contextColor }: { contextColor: string }) => {
  const { consignment } = useContext(ModalContext);

  return consignment && consignment.deadRuns?.length ? (
    <>
      <Divider />
      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography variant="displayXSmallBold" color={contextColor}>
            Dead Runs
          </Typography>
        </Grid>
      </Grid>

      {consignment.deadRuns.map((deadRun, index) => (
        <DeadRunRow key={index} deadRun={deadRun} />
      ))}
    </>
  ) : null;
};

export default OrderDeadRunsSection;
