import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, Typography } from '@mui/material';
import { PTextFieldMemo, RequiredFormikTextFields } from '../../../pegasus/PTextField';
import AddressSelect from '../../../components/form/AddressSelect';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import { ConsignmentFormValues } from './ConsignmentForm';
import { get, isEqual, pick } from 'lodash';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';
import PTagsInput from '../../../pegasus/PTagsInput';
import useAddressBookApiRoutes from '../../../hooks/api/useAddressBookApiRoutes';

const ReceiverSection = ({
  values,
  ...formikProps
}: RequiredFormikTextFields & { values: Partial<ConsignmentFormValues> }) => {
  const [loading, setLoading] = useState(false);
  const { create,list } = useAddressBookApiRoutes();
  const [companySuggestions, setCompanySuggestions] = useState<any>([]);
  const [inputValue, setInputValue] = useState(get(values, 'receiver.companyName'));
  const fetchCompanies = async (query: string) => {
    setLoading(true);
    try {
      const response = await list({searchText:query})
      setCompanySuggestions(response.addressBooks); // Assuming response.data is the list of companies
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCompanyChange = (event: any, newValue:any) => {
    if (newValue && typeof newValue === 'string') {
      // Manual input: handle case when user types a new value
      setInputValue('');
      formikProps.setFieldValue('receiver.companyName', newValue);

      formikProps.setFieldValue('receiver.address', ''); // Clear Formik address value
    } else if (newValue && newValue.companyName) {
      // Option selected from list
      setInputValue('');
      formikProps.setFieldValue('receiver.companyName', newValue.companyName || '');
      formikProps.setFieldValue('receiver.address', newValue.address || '');
      formikProps.setFieldValue('receiver.contactName', newValue ? newValue.contactName : '');
      formikProps.setFieldValue('receiver.phone', newValue ? newValue.phone : '');
      formikProps.setFieldValue('receiver.email', newValue ? newValue.email : '');

    } else {
      // Clear fields when nothing is selected
      
      formikProps.setFieldValue('receiver.companyName', '');
      formikProps.setFieldValue('receiver.address', '');
      formikProps.setFieldValue('receiver.contactName', '');
      formikProps.setFieldValue('receiver.phone',  '');
      formikProps.setFieldValue('receiver.email','');

    }
  };
  useEffect(() => {
    if (inputValue) {
      fetchCompanies(inputValue);
    } else {
      setCompanySuggestions([]);
    }
  }, [inputValue]);
  return (
    <>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Receiver
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
        <Autocomplete
          autoComplete
          includeInputInList
          freeSolo
          options={companySuggestions}
          getOptionLabel={(option:any) => option.companyName || ''} // Assuming each company object has a 'name' property
          loading={loading}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          onChange={handleCompanyChange}
          renderInput={(params) => (
          <PTextFieldMemo
              {...params}
            value={get(values, 'receiver.companyName')}
              label="Company Name"
            name="receiver.companyName"
              disabled={false}
            fullWidth
            {...formikProps}
            />
          )}
          />
        </Grid>
        <Grid item xs={8}>
          <AddressSelect addressBookFieldName="receiver" name="receiver.address" fullWidth label="Address" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PTextFieldMemo
            value={get(values, 'receiver.additionalInfo')}
            name="receiver.additionalInfo"
            fullWidth
            label="Additional Address Information"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'receiver.contactName')}
            name="receiver.contactName"
            fullWidth
            label="Contact Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneNumberInput name="receiver.phone" fullWidth label="Phone Number" />
        </Grid>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'receiver.email')}
            name="receiver.email"
            fullWidth
            label="Email"
            type="email"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PTagsInput  value={get(values, 'receiver.contactEmails')} name="eceiver.contactEmails" label="Additional Email" {...formikProps}/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PDateTimePicker name="preferredDeliveryTime" fullWidth label="Preferred Delivery Time" />
        </Grid>
        <Grid item xs={8}>
          <PTextFieldMemo
            value={get(values, 'deliveryInstructions')}
            name="deliveryInstructions"
            fullWidth
            label="Delivery Instructions"
            {...formikProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

const formFields = [
  'receiver.companyName',
  'receiver.address',
  'receiver.additionalInfo',
  'receiver.contactName',
  'receiver.phone',
  'receiver.email',
  'receiver.contactEmails',
  'preferredDeliveryTime',
  'deliveryInstructions',
];
export default React.memo(ReceiverSection, (prevProps, nextProps) => {
  return isEqual(pick(prevProps.values, formFields), pick(nextProps.values, formFields));
});
