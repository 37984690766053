import { Consignment } from '../../types';
import useGetConsignmentAddressObject from './useGetConsignmentAddressObject';

const defaultCenter = {
  lat: -36.979984387467205,
  lng: 174.77844557333128,
};
export default function useGetConsignmentLocation() {
  const getConsignmentAddressObject = useGetConsignmentAddressObject();
  return (consignment: Consignment) => {
    const addressObject = getConsignmentAddressObject(consignment);
    return addressObject?.location || defaultCenter;
  };
}
