import { Box, Button } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import SnackBarContext from '../../../context/snackbar';
import ConsignmentForm, { ConsignmentFormValues } from './ConsignmentForm';
import { SubmitButton } from '../../../pegasus/PSubmit';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { CheckInSheet, CheckinSheetForm, Consignment } from '../../../../types';
import pick from 'lodash/pick';
import CheckinSheetFormAdd, { CheckinSheetFormValues } from './CheckinSheetFormAdd';
import useCheckinApiRoutes from '../../../hooks/api/useCheckinApiRoutes';

const AddCheckinSheet = ({ countryCode }: { countryCode: string }) => {
  const { closeDrawer, isDrawerOpen, consignment } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { createCheckinsheet } = useCheckinApiRoutes();
  const isDuplicate = isDrawerOpen('duplicate-consignment');
  const initialValues = useMemo<Partial<CheckinSheetForm>>(() => {
    const baseFields: Partial<CheckinSheetForm> = {
      status: 'unassigned',

    };
    const otherFields: Partial<CheckinSheetForm> = isDuplicate
      ? pick(consignment, [
          'countryCode',
          'orderType',
          'depot',
          'sender',
          'receiver',
          'urgent',
          'tailLiftRequired',
          'customsMpiFoodSafety',
          'fragile',
          'sufficientPackaging',
          'spmPallet',
          'statementOfContent',
          'tamperEvidentForm',
          'knownShipper',
          'saturdayDelivery',
          'temperatureControlRequired',
          'labelsAttached',
          'damaged',
          'goodsType',
          'calculateVolumeBy',
          'deliveryInstructions',
          'lineItems',
          'dangerousGoodsReason',
          'temperatureControlReason',
          'tags',
          'console',
          'physicallyScanned'
        ])
      : {
          countryCode: countryCode,
          sender: {
            countryCode: countryCode,
            companyName: '',
            address: '',
            additionalInfo: '',
            contactName: '',
            phone: '',
            email: '',
            areaZone: '',
            milestones: true,
            labels: true,
            contactEmails: [],
            originCountryCode: '',
            state: '',
          },
          consignee: {
            countryCode: countryCode,
            companyName: '',
            address: '',
            additionalInfo: '',
            contactName: '',
            phone: '',
            email: '',
            contactEmails: [],
            originCountryCode: '',
            state: '',
            consigneeIsUnknown:false,
          },
          signatureRequired: false,
          photoPodRequired: false,
          urgent: false,
          tailLiftRequired: false,
          customsMpiFoodSafety: false,
          fragile: false,
          knownShipper:false,
          sufficientPackaging:false,
          spmPallet:false,
          statementOfContent:false,
          tamperEvidentForm:false,
          saturdayDelivery: false,
          goodsType: 'general',
          labelsAttached:false,
          temperatureControlRequired:false,
          damaged:false,
          dangerousGoodsReason:"",
          temperatureControlReason:"",
          calculateVolumeBy: 'unitType',
          proofOfDelivery:{
            photos:[]
          },
          physicallyScanned:false
        };
    return { ...baseFields, ...otherFields };
  }, [consignment, countryCode, isDuplicate]);

  const closeModal = useCallback(() => {
    closeDrawer(isDuplicate ? 'duplicate-checkinsheet' : 'new-checkinsheet');
  }, [closeDrawer, isDuplicate]);

  const handleSubmit = (values: CheckinSheetFormValues, { setSubmitting }: FormikHelpers<CheckinSheetFormValues>) => {
    createCheckinsheet(values)
      .then(() => {
        setSubmitting(false);
        showSnack('Check-In Sheet Created!', 'success');
        closeModal();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Box color="primary" display="flex" flexDirection="column" flex={1}>
      <CheckinSheetFormAdd onSubmit={handleSubmit} initialValues={initialValues}>
        <SubmitButton color="primary" sx={{ marginRight: 1 }}>
          Submit New Check-in
        </SubmitButton>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </CheckinSheetFormAdd>
    </Box>
  );
};

export default AddCheckinSheet;
