import React, { useContext, useRef, useState } from 'react';
import ModalContext from '../../../context/modal';
import { Box, Button, ButtonGroup, Divider, Theme } from '@mui/material';
import { TabButton } from './TabButton';
import { TabConsignment } from './TabConsignment';
import { TabNotes } from './TabNotes';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import useConsignmentActions from '../../../hooks/useConsignmentActions';
import useGetContextColor from '../../../hooks/useGetContextColor';
import SnackBarContext from '../../../context/snackbar';
import ViewCheckInSheet from './ViewCheckInSheet';
import { useGetDriver } from '../../../hooks/useGetDriverName';
import UserContext from '../../../context/user';
import useCheckinApiRoutes from '../../../hooks/api/useCheckinApiRoutes';
import { TabCheckinSheet } from './TabCheckinSheet';
import ViewheckinSheetPdf from './ViewCheckinSheetPdf';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';

export type View = { label: string; value: string };
const ViewCheckinSheetForm = () => {
    const { checkinsheet, openDrawer, closeDrawer } = useContext(ModalContext);
    const [sendingEmail, setSendingEmail] = useState(false);
    const { showSnack } = useContext(SnackBarContext);
    const { toggleCompletion, emailReport } = useCheckinApiRoutes();
    const {emailCustomerReport } = useConsignmentApiRoutes();
    //const contextColor = useGetContextColor(checkinsheet);
    // const { cancelConsignment, cancelArchiveConsignment, moveToUnAssigned, moveToInTransit, moveToAtDepot, truckUnassignment} = useConsignmentActions();
    const classes = useStyles();
    const isAssigned = checkinsheet?.status === 'assigned' || checkinsheet?.status === 'assignedLeg2';
    // const driver      = useGetDriver(checkinsheet);
    const { isLoggedIn, user } = useContext(UserContext);

    const views = useRef<View[]>([
        { label: 'Check-in Sheet', value: 'checkinsheet' },
    ]).current;

    const [view, setView] = useState(views[0]);

    const editCheckinSheet = () => {
        openDrawer('edit-checkinsheet');
    };
    const changeStatus = () => {
        if (checkinsheet) {
            toggleCompletion(checkinsheet).then(() => {
                showSnack('CheckIn-sheet status Updated!', 'success');
                closeDrawer('view-checkinsheet');
            })
                .catch(() => { });
        }
    };
    const handleEmailReport = async () => {
        if (checkinsheet) {
            try {
                setSendingEmail(true);
                if(checkinsheet.consignmentUid) {
                    await emailCustomerReport(checkinsheet.consignmentUid);
                }   else{
                    await emailReport(checkinsheet._id);
                }
                showSnack('Email Sent', 'success');
                setSendingEmail(false);
            } catch (error) {
                setSendingEmail(false);
                console.log('error: ', error);
            }
        }
    };
    const base64toBlob = (base64Data: string) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: 'application/pdf' });
    };
    return checkinsheet ? (
        <Box display="flex" flexDirection="column" flex={1}>

            <Box padding={6.5} paddingTop={2} flex={1} overflow="auto">
                <Box display="flex" flexDirection="column" alignItems="center" mb={6}>

                </Box>
                <Box>
                    {view.value === 'checkinsheet' ? <TabCheckinSheet /> : null}
                </Box>
            </Box>
            <Divider />
            {
                isLoggedIn && (
                    <Box display="flex" paddingX={6.5} paddingBottom={5} paddingTop={3} >

                        { 
                            checkinsheet.status != 'cancel' && !checkinsheet.consignmentUid? (<Button
                                variant="contained"
                                className={classes.button}
                                onClick={editCheckinSheet}
                            >
                                Edit Check-In Sheet
                            </Button>) : null
                        }
                        <Button
                            variant="contained"
                            onClick={handleEmailReport}
                            className={`${classes.button} ${classes.greyButton}`}
                            disabled={sendingEmail}
                        >
                            Share Check-In Sheet
                        </Button>

                        <>
                            {user?.type !== 'operations' ? (
                                <Button variant="contained" className={classes.button} onClick={changeStatus}>
                                    Mark as {checkinsheet.status == 'complete' ? 'Active' : 'Archived'}
                                </Button>
                            ) : null}

                            <ViewheckinSheetPdf />
                        </>




                    </Box>
                )
            }

        </Box>
    ) : null;
};

export default ViewCheckinSheetForm;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginRight: theme.spacing(1.5),
        },
        greyButton: {
            backgroundColor: Colors.greyscale.offBlack,
            opacity: 0.5,
        },
    })
);
