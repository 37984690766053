import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import DepotSelect from './form/DepotSelect';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UserContext from '../context/user';
import DepotContext from '../context/depot';

const useStyles = makeStyles({
  container: {
    marginBottom: 0,
  },
});

function DispatchViewButton({ buttonText = 'Dispatch View' }: { buttonText?: string }) {
  const { countryCode = '' } = useParams<'countryCode'>();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { depot } = useContext(DepotContext);
  const [initialValues, setInitialValues] = useState({
    depot: user?.type === 'admin' ? '' : user?.depotIds?.find((d) => d) || '',
  });
  // console.log('depot: ', depot);
  const goToDepot = () => {
    console.log('goToDepot');
  };

  useEffect(() => {
    let active = true;
    if (active && depot?._id) {
      setInitialValues({ depot: depot?._id });
    }
    return () => {
      active = false;
    };
  }, [depot?._id]);

  return (
    <Formik initialValues={initialValues} onSubmit={goToDepot} enableReinitialize>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Box sx={{ minWidth: 300, marginRight: 1 }}>
              <DepotSelect
                containerClass={classes.container}
                countryCode={countryCode}
                name="depot"
                label="Depot"
                setFieldValue={setFieldValue}
                value={values.depot as string}
                restrictDepots={true}
              />
            </Box>
            <Button
              disabled={!values.depot}
              sx={{ alignSelf: 'center', flexShrink: 0, marginRight: 1 }}
              variant="contained"
              size="medium"
              to={`/dispatch/${countryCode}/${values.depot}`}
              component={Link}
            >
              <ArrowBackIcon sx={{ marginRight: 1 }} />
              {buttonText}
            </Button>
          </>
        );
      }}
    </Formik>
  );
}

export default DispatchViewButton;
