import React, { useContext, useRef, useState } from 'react';
import ModalContext from '../../../context/modal';
import { Box, Button, ButtonGroup, Divider, Theme } from '@mui/material';
import { TabButton } from './TabButton';
import { TabConsignment } from './TabConsignment';
import { TabNotes } from './TabNotes';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import useConsignmentActions from '../../../hooks/useConsignmentActions';
import useGetContextColor from '../../../hooks/useGetContextColor';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import SnackBarContext from '../../../context/snackbar';
import ViewCheckInSheet from './ViewCheckInSheet';
import { useGetDriver } from '../../../hooks/useGetDriverName';
import UserContext from '../../../context/user';

export type View = { label: string; value: string };
const ViewConsignmentModal = () => {
  const { consignment, openDrawer } = useContext(ModalContext);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { showSnack } = useContext(SnackBarContext);
  const { emailCustomerReport, downloadCustomerReport } = useConsignmentApiRoutes();
  const contextColor = useGetContextColor(consignment);
  const { cancelConsignment, cancelArchiveConsignment, moveToUnAssigned, moveToInTransit, moveToAtDepot, truckUnassignment} = useConsignmentActions();
  const classes = useStyles();
  const isAssigned = consignment?.status === 'assigned' || consignment?.status === 'assignedLeg2';
  const driver      = useGetDriver(consignment);
  const {user}      = useContext(UserContext);

  const views = useRef<View[]>([
    { label: 'Consignment', value: 'consignment' },
    { label: 'Notes', value: 'notes' },
  ]).current;

  const [view, setView] = useState(views[0]);

  const editConsignment = () => {
    openDrawer('edit-consignment');
  };
  const cancelDriver = () => {
    if (consignment) {
      cancelConsignment(consignment);
    }
  };
  const removeConsignment = () => {
    if (consignment) {
      cancelArchiveConsignment(consignment);
    }
  };
  const unassignTruck = () => {
    if (consignment && driver) {
      truckUnassignment(driver, consignment);
    }
  };
  const unAssign = () => {
    if (consignment) {
      moveToUnAssigned(consignment);
    }
  };

  const handleInTransitClick = () => {
    if (consignment) {
      moveToInTransit(consignment);
    }
  };
  const handleAtDepotClick = () => {
    if (consignment) {
      moveToAtDepot(consignment);
    }
  };
  const handleEmailCustomerReport = async () => {
    if (consignment) {
      try {
        setSendingEmail(true);
        await emailCustomerReport(consignment._id);
        showSnack('Email Sent', 'success');
        setSendingEmail(false);
      } catch (error) {
        setSendingEmail(false);
        console.log('error: ', error);
      }
    }
  };

  const handleDuplicate = () => {
    openDrawer('duplicate-consignment');
  };

  const handleReportPdf = () => {
    if (consignment) {
      downloadCustomerReport(consignment._id).then((response: any) => {
        let a = document.createElement("a");
        document.body.appendChild(a);
     
        const blob = base64toBlob(response);
        const blobUrl = URL.createObjectURL(blob);
        a.href= blobUrl;
        a.download = "CustomerReport.pdf";
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      });
    }
  };
  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  };
  return consignment ? (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box padding={6.5} flex={1} overflow="auto">
        <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
          <ButtonGroup>
            {views.map((_view) => (
              <TabButton
                contextColor={contextColor || ''}
                key={_view.value}
                view={_view}
                setView={setView}
                active={_view === view}
              />
            ))}
          </ButtonGroup>
        </Box>
        <Box>
          {view.value === 'consignment' ? <TabConsignment contextColor={contextColor || ''} /> : null}
          {view.value === 'notes' ? <TabNotes contextColor={contextColor || ''} /> : null}
        </Box>
      </Box>

      <Divider />

      <Box display="flex" paddingX={6.5} paddingBottom={5} paddingTop={3} color={contextColor}>
        {consignment.status === 'onHold' ? (
          <Button variant="outlined" className={classes.button} color="inherit" onClick={unAssign}>
            Move To Un-Assigned
          </Button>
        ) : null}
        {
          consignment.status != 'cancel' ? (    <Button
            variant="contained"
            className={classes.button}
            sx={{ backgroundColor: contextColor }}
            onClick={editConsignment}
          >
            Edit Consignment
          </Button>):null
        }
    
    
          <>
          {isAssigned?(
            <Button variant="contained" className={classes.button} onClick={cancelDriver}>
              Cancel Driver
            </Button>):null}
            {!consignment.archived && consignment.status!='cancel'?(
              <Button variant="contained" className={classes.button} color="error" onClick={removeConsignment}>
              Cancel Consignment
            </Button>
            ):null}
             
            {isAssigned  && driver?.driverInformation?.currentRegistration &&  user?.type !== 'operations'  ? (
                <Button variant="contained" className={classes.button} onClick={unassignTruck}>
                Unassign Truck {driver?.driverInformation?.currentRegistration}
              </Button>
            ):null}
            {isAssigned  && (consignment.orderType === 'drop-off' || consignment.orderType === 'transfer') ? (
              <Button variant="outlined" className={classes.button} color="inherit" onClick={handleInTransitClick}>
                In Transit
              </Button>
            ) : null}
         
          </>
      
           {isAssigned ? (
          <>
            {consignment.orderType === 'drop-off' || consignment.orderType === 'transfer' ? (
              <Button variant="outlined" className={classes.button} color="inherit" onClick={handleInTransitClick}>
                In Transit
              </Button>
            ) : null}
          </>
        ) : null}
        {consignment.orderType === 'transfer' && consignment.status === 'inTransit' ? (
          <Button variant="outlined" className={classes.button} color="inherit" onClick={handleAtDepotClick}>
            At Depot
          </Button>
        ) : null}
        <Button variant="outlined" className={classes.button} color="inherit" onClick={handleDuplicate}>
          Create New Duplicate
        </Button>
        {consignment.proofOfDelivery ? (
          <>
          <Button
            variant="contained"
            onClick={handleEmailCustomerReport}
            className={`${classes.button} ${classes.greyButton}`}
            disabled={sendingEmail}
          >
            Email Customer Report
          </Button>
          <Button
          variant="contained"
          onClick={handleReportPdf}
          className={`${classes.button} ${classes.greyButton}`}
          disabled={sendingEmail}
        >
          Download Customer Report
        </Button>
        </>
        ) : null}
        <ViewCheckInSheet />
      </Box>
    </Box>
  ) : null;
};

export default ViewConsignmentModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1.5),
    },
    greyButton: {
      backgroundColor: Colors.greyscale.offBlack,
      opacity: 0.5,
    },
  })
);
