import React, { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { AddressBook } from '../../types';

const GoogleApiContext = React.createContext<{
  autocompleteService: google.maps.places.AutocompleteService | undefined;
  placesService: google.maps.places.PlacesService | undefined;
}>({
  autocompleteService: undefined,
  placesService: undefined,
});

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
  location?: { lat: number; lng: number };
  addressBook?: AddressBook;
}

export const componentRestrictions = { country: ['nz', 'au', 'fj'] };

export default GoogleApiContext;

const libraries: ['places', 'geometry'] = ['places', 'geometry'];

export const GoogleApiProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: CONFIG.googleApiKey,
    libraries: libraries,
  });
  const [placesRef, setPlacesRef] = useState<HTMLDivElement | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();

  useEffect(() => {
    if (isLoaded && placesRef) {
      setPlacesService(new google.maps.places.PlacesService(placesRef));
      setAutocompleteService(new google.maps.places.AutocompleteService());
    }
  }, [isLoaded, placesRef, setAutocompleteService]);

  return (
    <GoogleApiContext.Provider
      value={{
        autocompleteService,
        placesService,
      }}
    >
      <div ref={setPlacesRef}></div>
      {isLoaded ? children : undefined}
    </GoogleApiContext.Provider>
  );
};
