import React, { useCallback, useState } from 'react';
import { Consignment } from '../../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';
import Carousel from '../../../components/Carousel';
import ImageViewer from "react-simple-image-viewer";
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      backgroundColor: Colors.primary.background,
      marginBottom: theme.spacing(2),
    },
    image: {
      width: '100%',
      maxHeight: 300,
      objectFit: 'contain',
    },
  })
);

function ProofOfDelivery({ consignment }: { consignment: Consignment }) {
  const classes = styles();
  const images  = consignment?.proofOfDelivery?.photos;
  const finalImg= (images && images.length? images.map((item) => item.signedUrl) : []).map(String);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return consignment.proofOfDelivery ? (
    <>
      <Box className={classes.container}>
        <Typography variant="textSmall">Signed by: {consignment.proofOfDelivery.customerName}</Typography>
        <img
          className={classes.image}
          src={consignment.proofOfDelivery.customerSignature?.signedUrl}
          alt={consignment.proofOfDelivery.customerSignature?.filename}
        />
        <Typography variant="textSmall">Customer Rating: {consignment.proofOfDelivery.rating}</Typography>
        <Typography variant="textSmall">Delivery Notes: {consignment.proofOfDelivery.deliveryNotes}</Typography>
      </Box>
      <Box mb={2}>
      {consignment.proofOfDelivery && consignment.proofOfDelivery.photos && consignment.proofOfDelivery.photos.length && consignment.proofOfDelivery.photos.map((src, index) => (
        <img
          src={src.signedUrl}
          onClick={() => openImageViewer(index)}
          width="100"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={finalImg}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
{/*         <Carousel photos={consignment.proofOfDelivery.photos} />
 */}      </Box>
    </>
  ) : null;
}

export default ProofOfDelivery;
