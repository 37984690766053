import { useCallback, useContext } from 'react';
import SocketContext from '../context/socket';

export default function useShowIndicator() {
  const { updatedStatusCodes } = useContext(SocketContext);

  const showIndicator = useCallback(
    (indicatorStatuses: string[]) => {
      return updatedStatusCodes.some((c) => indicatorStatuses.includes(c));
    },
    [updatedStatusCodes]
  );
  return showIndicator;
}
