import { Autocomplete, TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { PropsOf } from '@emotion/react';
import { PTextField, ThemedTextFieldProps } from './PTextField';
import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../pegasus/Colors';
export type PSelectAsyncProps = Omit<PropsOf<typeof Autocomplete>, 'renderInput'> &
  Required<Pick<TextFieldProps, 'name'>> &
  Pick<ThemedTextFieldProps, 'label' | 'containerClass'>;

const useStyles = makeStyles({
  popper: {
    '& .MuiPaper-root': {
      backgroundColor: Colors.grey.bg,
      borderRadius: '16px',
      marginTop: '5px',
    },
    '& ul > li.MuiAutocomplete-option.Mui-focused': {
      color: Colors.primary.main,
      backgroundColor: Colors.grey.bg,
    },
    '& ul > li.MuiAutocomplete-option[aria-selected="true"]': {
      color: Colors.primary.main,
      backgroundColor: '#EFF0F6 !important',
    },
    '& ul > li.MuiAutocomplete-option[aria-selected="true"]:hover': {
      backgroundColor: Colors.grey.bg,
    },
    '& ul > li.MuiAutocomplete-option': {
      color: Colors.greyscale.placehold,
    },
  },
  root: {
    '& .MuiFilledInput-root': {
      paddingLeft: '20px',
    },
  },
  rootAutocomplete: {
    '& .MuiBox-root': {
      marginBottom: 0,
    },
  },
});

export const PSelectAsync = React.forwardRef((props: PSelectAsyncProps, ref) => {
  const { placeholder, name, label, containerClass, ...otherProps } = props;
  const { handleChange } = useFormikContext();
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      disablePortal
      onChange={handleChange}
      classes={{ popper: classes.popper, root: classes.rootAutocomplete }}
      renderInput={({ InputProps, ...otherParams }) => (
        <PTextField
          classes={{ root: classes.root }}
          placeholder={placeholder}
          InputProps={{ ...InputProps, disableUnderline: true, autoComplete: 'do-not-autofill' }}
          name={name}
          label={label}
          containerClass={containerClass}
          {...otherParams}
        />
      )}
      ref={ref}
      {...otherProps}
    />
  );
});
PSelectAsync.displayName = 'PSelectAsync';
