import React from 'react';
import { useContext } from 'react';
import ModalContext from '../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../pegasus/PSubmit';
import EditProfileForm from '../form/EditProfileForm';
import { User } from '../../../types';
import SnackBarContext from '../../context/snackbar';
import useUserApiRoutes from '../../hooks/api/useUserApiRoutes';
import UserContext from '../../context/user';

const EditProfileModal = () => {
  const { closeDrawer } = useContext(ModalContext);
  const { editProfile } = useUserApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { user, saveUser } = useContext(UserContext);

  const handleSubmit = (values: Partial<User>, { setSubmitting }: FormikHelpers<Partial<User>>) => {
    editProfile(values)
      .then((userRes: Partial<User>) => {
        setSubmitting(false);
        saveUser(userRes);
        showSnack('Profiled updated!', 'success');
        closeDrawer('edit-profile');
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <EditProfileForm
      onSubmit={handleSubmit}
      initialValues={{
        _id: user?._id || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
      }}
    >
      <SubmitButton color="primary" sx={{ marginRight: 1 }}>
        Save Edits
      </SubmitButton>
    </EditProfileForm>
  );
};

export default EditProfileModal;
