import { Box, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from '../../types';
import useViewApiRoutes from '../hooks/api/useViewApiRoutes';
import useCheckinApiRoutes from '../hooks/api/useCheckinApiRoutes';
const ViewContext = React.createContext<{
  view?: View[];
  saveView: React.Dispatch<React.SetStateAction<View[]>>;
  uniqueFilters:{   
    uniqueConsignmentIds:[],
    uniqueNames:[],
    uniqueTags:[],
    uniqueConsol:[],
    uniqueDeliveredBy:[]
  }
}>({
  view: [],
  uniqueFilters:{
    uniqueConsignmentIds:[],
    uniqueNames:[],
    uniqueTags:[],
    uniqueConsol:[],
    uniqueDeliveredBy:[]
  },
  saveView: () => undefined,
});

export default ViewContext;

export const ViewProvider = ({ children }: { children: React.ReactNode }) => {
  const [view, saveView] = useState<View[]>([]);
  const [uniqueFilters, saveUniqueFilters] = useState<any>();

  const [isLoading, setIsLoading] = useState(true);
  const { listView } = useViewApiRoutes();
  const {listCheckinCompanies} = useCheckinApiRoutes();

  // must use useCallback becasue if saveUser is in a dependency array in another component it will keep re-rendereing!


  useEffect(() => {
    listView()
      .then((view) => {
        if (view) {
          saveView(view.views);
        }
      })
      .finally(() => setIsLoading(false));
      listCheckinCompanies()
      .then((list) => {
        if(list) {
          saveUniqueFilters(list);
        }
      })
      .finally(() => setIsLoading(false));

  }, [listView,listCheckinCompanies, saveView]);

  return (
    <ViewContext.Provider
      value={{
        view,
        saveView,
        uniqueFilters
      }}
    >
      {children}

    </ViewContext.Provider>
  );
};
