import React, { useContext } from 'react';
import AppDrawer from './AppDrawer';
import ModalContext from '../../context/modal';
import NewConsignmentModal from '../../pages/Operations/consignment/NewConsignmentModal';
import EditConsignmentModal from '../../pages/Operations/consignment/EditConsignmentModal';
import NewUserModal from '../../pages/Admin/user/NewUserModal';
import { Box, Typography } from '@mui/material';
import ViewConsignmentModal from '../../pages/Operations/consignment/ViewConsignmentModal';
import { Colors } from '../../pegasus/Colors';
import { ConsignmentBadges } from '../../pages/Dispatch/components/ConsignmentBadges';
import EditProfileModal from '../../components/Modals/EditProfileModal';
import UserContext from '../../context/user';
import AddressBookModal from '../../pages/Operations/address-book/AddressBookModal';
import EditUserModal from '../../pages/Admin/user/EditUserModal';
import CheckInSheetModal from '../../pages/Operations/consignment/CheckInSheetModal';
import useGetContextColor from '../../hooks/useGetContextColor';
import AddCheckinSheet from '../../pages/Operations/consignment/AddCheckinSheet';
import EditCheckinSheet from '../../pages/Operations/consignment/EditCheckinSheet';
import ViewCheckinSheetForm from '../../pages/Operations/consignment/ViewCheckinSheetForm';
import NewViewModal from '../../pages/Operations/consignment/ViewModal';
import ShareViewModal from '../../pages/Operations/consignment/ShareViewModal';

const AppModals = () => {
  const { isDrawerOpen, country, consignment,checkinsheet} = useContext(ModalContext);
  const contextColor = useGetContextColor(consignment);
  const { user: userProfile } = useContext(UserContext);
  const view = JSON.parse(localStorage.getItem('selectedView')??"{}");
  return (
    <>
      <AppDrawer
        headerText="Add New Consignment"
        open={isDrawerOpen('new-consignment') && !!country?.code}
        drawerType="new-consignment"
        contentPadding={false}
      >
        <NewConsignmentModal countryCode={country?.code || ''} />
      </AppDrawer>
      <AppDrawer
        headerText="Check-in Sheet"
        open={isDrawerOpen('new-checkinsheet') && !!country?.code}
        drawerType="new-checkinsheet"
        contentPadding={false}
      >
        <AddCheckinSheet countryCode={country?.code || ''} />
      </AppDrawer>
      <AppDrawer
          headerText={
            <Typography variant="displayXSmallBold" marginY={3} mr={3}>
              Save as new view
            </Typography>
          }
        open={isDrawerOpen('new-view') && !!country?.code}
        drawerType="new-view"
        contentPadding={false}
      >
        <NewViewModal countryCode={country?.code || ''} />
      </AppDrawer>
      <AppDrawer
        contextColor={Colors.greyscale.offBlack}
        headerText={
          <Typography variant="displayXSmallBold" marginY={3} mr={3}>
            Share the {view?.name} view
          </Typography>
        }
        open={isDrawerOpen('share-view')}
        drawerType="share-view"
        contentPadding={false}
      >
        <ShareViewModal view={view} />
      </AppDrawer>
      <AppDrawer
        contextColor={Colors.greyscale.offBlack}
        headerText={
          <Typography variant="displayXSmallBold" marginY={3} mr={3}>
            Edit Check-in sheet
          </Typography>
        }
        open={isDrawerOpen('edit-checkinsheet') && !!checkinsheet}
        drawerType="edit-checkinsheet"
        contentPadding={false}
      >
        <EditCheckinSheet  />
      </AppDrawer>
      <AppDrawer
        headerText="Duplicate Consignment"
        open={isDrawerOpen('duplicate-consignment')}
        drawerType="duplicate-consignment"
        contentPadding={false}
      >
        <NewConsignmentModal countryCode={country?.code || ''} />
      </AppDrawer>
      <AppDrawer
        contextColor={Colors.greyscale.offBlack}
        headerText={
          <Typography variant="displayXSmallBold" marginY={3} mr={3}>
            Edit Consignment #{consignment?.consignmentId}
          </Typography>
        }
        open={isDrawerOpen('edit-consignment') && !!consignment}
        drawerType="edit-consignment"
        contentPadding={false}
      >
        <EditConsignmentModal />
      </AppDrawer>
      <AppDrawer
        headerText={
          consignment ? (
            <Box display="flex">
              <Typography variant="displayXSmallBold" marginY={3} mr={3}>
                Consignment #{consignment?.consignmentId}
              </Typography>
              <ConsignmentBadges
                consignment={consignment}
                backgroundColor={Colors.greyscale.input}
                color={Colors.primary.main}
              />
            </Box>
          ) : null
        }
        contextColor={contextColor}
        open={isDrawerOpen('view-consignment') && !!consignment}
        drawerType="view-consignment"
        contentPadding={false}
      >
        <ViewConsignmentModal />
      </AppDrawer>
      <AppDrawer
        headerText={
          checkinsheet ? (
            <Box display="flex">
              <Typography variant="displayXSmallBold" marginY={3} mr={3}>
               View Check-in sheet
              </Typography>
      
            </Box>
          ) : null
        }
        contextColor={contextColor}
        open={isDrawerOpen('view-checkinsheet') && !!checkinsheet}
        drawerType="view-checkinsheet"
        contentPadding={false}
      >
        <ViewCheckinSheetForm />
      </AppDrawer>
      <AppDrawer
        headerText={`Prepare Check-in Sheet for Consignment #${consignment?.consignmentId}`}
        open={isDrawerOpen('edit-check-in-sheet') && !!consignment}
        drawerType="edit-check-in-sheet"
        contentPadding={false}
      >
        <CheckInSheetModal />
      </AppDrawer>
      <AppDrawer headerText="Invite New User" open={isDrawerOpen('new-user') && !!country?.code} drawerType="new-user">
        <NewUserModal countryCode={country?.code || ''} />
      </AppDrawer>
      <AppDrawer
        headerText="Edit User"
        open={isDrawerOpen('edit-user')}
        drawerType="edit-user"
        contextColor={Colors.greyscale.offBlack}
      >
        <EditUserModal />
      </AppDrawer>
      {/* <AppDrawer
        headerText={
          <Typography variant="displayXSmallBold" marginY={3}>
            Assign Consignment
            <br />#{consignment?.consignmentId}
          </Typography>
        }
        open={isDrawerOpen('assign-driver') && !!consignment}
        drawerType="assign-driver"
      >
        <AssignDriverModal />
      </AppDrawer> */}
      <AppDrawer
        headerText="Edit Profile"
        open={isDrawerOpen('edit-profile') && !!userProfile}
        drawerType="edit-profile"
      >
        <EditProfileModal />
      </AppDrawer>
      <AppDrawer
        headerText="Edit Company"
        open={isDrawerOpen('edit-address-book')}
        drawerType="edit-address-book"
        contextColor={Colors.greyscale.offBlack}
      >
        <AddressBookModal />
      </AppDrawer>
    </>
  );
};

export default AppModals;
