import React, { useContext } from 'react';
import { FormikHelpers } from 'formik';
import SnackBarContext from '../../../context/snackbar';
import { SubmitButton } from '../../../pegasus/PSubmit';
import DepotForm from './DepotForm';
import { Depot } from '../../../../types';
import useDepotApiRoutes from '../../../hooks/api/useDepotApiRoutes';

const NewDepotModal = ({
  initialValues,
  onDepotCreate,
}: {
  initialValues: Partial<Depot>;
  onDepotCreate: (depot: Depot) => void;
}) => {
  const { create } = useDepotApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const handleSubmit = (values: Partial<Depot>, { setSubmitting }: FormikHelpers<Partial<Depot>>) => {
    create(values)
      .then((depot) => {
        showSnack('Depot Created!', 'success');
        onDepotCreate(depot);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <DepotForm onSubmit={handleSubmit} initialValues={initialValues}>
      <SubmitButton color="primary" sx={{ marginRight: 1 }}>
        Create Depot
      </SubmitButton>
    </DepotForm>
  );
};

export default NewDepotModal;
