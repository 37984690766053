import { Colors } from '../pegasus/Colors';

export type OrderType = {
  name: string;
  code: string;
  contextColor: string;
  backgroundColor: string;
  displayInConsignment?:boolean
};

const orderTypes: OrderType[] = [
  {
    name: 'Pick Up',
    code: 'pick-up',
    contextColor: Colors.primary.dark,
    backgroundColor: Colors.primary.lightBackground,
    displayInConsignment:true
  },
  {
    name: 'Drop Off',
    code: 'drop-off',
    contextColor: Colors.secondary.dark,
    backgroundColor: Colors.secondary.background,
    displayInConsignment:true
  },
  { 
    name: 'Transfer',
    code: 'transfer', 
    contextColor: Colors.success.main, 
    backgroundColor: Colors.success.background,
    displayInConsignment:true
  },
  { 
    name: 'Drivers Only',
    code: 'drivers-only', 
    contextColor: Colors.success.main, 
    backgroundColor: Colors.success.background,
    displayInConsignment:false
  },
];
export default orderTypes;
