import React, { useContext, useEffect } from 'react';
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { PTextField, PTextFieldMemo, PTextFieldReadOnly, ThemedTextFieldProps, useGetFormikTextFields } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { ConsignmentFormValues } from './ConsignmentForm';
import { PCheckbox } from '../../../pegasus/PCheckbox';
import { Consignment, LineItem, lineItemTypes } from '../../../../types';
import { get } from 'lodash';
import useGetTotalCube, { useGetRowTotals } from '../../../hooks/useGetTotalCube';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { Colors } from '../../../pegasus/Colors';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { ErrorLabel } from '../../../pegasus/ErrorLabel';
import { CheckinSheetFormValues } from './CheckinSheetFormAdd';
import UserSelect from '../../../components/form/UserSelect';
import SignaturePad from '../../../components/SignaturePad';
import UserContext from '../../../context/user';
import FileUpload from './FileUpload';

const CheckinSheetOrderDetailsSection = () => {
    const { values, setFieldValue, errors } = useFormikContext<CheckinSheetFormValues>();

    const addRow = () => {
        setFieldValue('lineItems', [
            ...(values.lineItems || []),
            {
                type: 'select',
                units: 0,
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
            },
        ]);
    };

    return (
        <>
            <Typography variant="displayXSmallBold" color="inherit" mb={3}>
                Order Details
            </Typography>

            <VolumeCalculation />
            {values.lineItems?.map((lineItem, index) => (
                <Grid container key={index} spacing={0.5} columns={13.5}>
                    <LineItemRow index={index} lineItemsFieldName="lineItems" />
                </Grid>
            ))}
            <TotalsRow />

            {errors.lineItems && (
                <Box mb={2}>
                    <ErrorLabel errorText={errors.lineItems} />
                </Box>
            )}
            <Button
                variant="outlined"
                color={errors.lineItems ? 'error' : 'inherit'}
                fullWidth
                sx={{ marginBottom: 2.5 }}
                onClick={addRow}
            >
                Add Another Row
            </Button>

            <BooleanOptions />
        </>
    );
};
const base64ToUint8Array = (base64: any) => {
    const base64String = base64.split(',')[1];
    const binaryString = Buffer.from(base64, 'base64').toString('binary');
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

const downloadImage = (base64: any, filename: any) => {
    const base64String = base64.split(',')[1];
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const byteArray = base64ToUint8Array(base64String);
    const blob = new Blob([byteArray], { type: mimeString });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
}
const VolumeCalculation = () => {
    const { values, handleChange } = useFormikContext<CheckinSheetFormValues>();
    const theme = useTheme();

    return (
        <>
            <RadioGroup sx={{ mb: 2 }} value={values.calculateVolumeBy} name="calculateVolumeBy" onChange={handleChange} row>
                <FormControlLabel value="unitType" control={<Radio />} label="Calculate by Unit Type" />
                <FormControlLabel value="totalShipment" control={<Radio />} label="Calculate by Total Shipment" />
            </RadioGroup>
            {values.calculateVolumeBy === 'totalShipment' && (
                <Paper sx={{ mb: 2, overflow: 'hidden' }}>
                    <Box sx={{ p: 3, borderLeft: `4px solid ${theme.palette.info.main}` }}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <InfoIcon color="info" />
                            </Grid>
                            <Grid item xs>
                                <Typography>
                                    Calculating by total shipment is less accurate and therefore more likely to incur an additional charge
                                    for inaccurate or oversized dimensions.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )}
        </>
    );
};

export const LineItemRow = ({
    index,
    lineItemsFieldName,
    showLabels = true,
}: {
    index: number;
    lineItemsFieldName: string;
    showLabels?: boolean;
}) => {
    const { values, setFieldValue } = useFormikContext<CheckinSheetFormValues>();
    const lineItem = get(values, `${lineItemsFieldName}.${index}`);
    const size = showLabels ? 'medium' : 'small';

    const commonNumberProps: Partial<ThemedTextFieldProps> = {
        size: size,
        fullWidth: true,
        type: 'number',
        enableClear: false,
    };

    const getTotalCube = useGetTotalCube();
    const cube = getTotalCube(lineItem, values.calculateVolumeBy);

    const handleDelete = () => {
        const filteredLineItems = (get(values, lineItemsFieldName) as Array<LineItem>).filter((v, i) => i !== index);
        setFieldValue(lineItemsFieldName, filteredLineItems);
    };

    return (
        <>
            <Grid item xs={2} position="relative">
                <IconButton color="error" onClick={handleDelete} sx={{ position: 'absolute', top: 16, left: -40 }}>
                    <ClearIcon />
                </IconButton>
                <PSelect
                    name={`${lineItemsFieldName}.${index}.type`}
                    fullWidth
                    label={showLabels ? 'Type' : undefined}
                    size={size}
                >
                    <MenuItem disabled={true} value="select">
                        Select
                    </MenuItem>
                    {lineItemTypes.map((lineItemType) => (
                        <MenuItem key={lineItemType.code} value={lineItemType.code}>
                            {lineItemType.name}
                        </MenuItem>
                    ))}
                </PSelect>
            </Grid>
            <Grid item xs={1.5}>
                <PTextField
                    name={`${lineItemsFieldName}.${index}.units`}
                    label={showLabels ? 'Units' : undefined}
                    {...commonNumberProps}
                />
            </Grid>
            <Grid item xs={2}>
                <PTextField
                    name={`${lineItemsFieldName}.${index}.weight`}
                    label={showLabels ? 'Weight(kg)' : undefined}
                    {...commonNumberProps}
                />
            </Grid>
            <Grid item xs={2}>
                <PTextField
                    name={`${lineItemsFieldName}.${index}.length`}
                    label={showLabels ? 'Length(cm)' : undefined}
                    {...commonNumberProps}
                />
            </Grid>
            <Grid item xs={2}>
                <PTextField
                    name={`${lineItemsFieldName}.${index}.width`}
                    label={showLabels ? 'Width(cm)' : undefined}
                    {...commonNumberProps}
                />
            </Grid>
            <Grid item xs={2}>
                <PTextField
                    name={`${lineItemsFieldName}.${index}.height`}
                    label={showLabels ? 'Height(cm)' : undefined}
                    {...commonNumberProps}
                />
            </Grid>
            {values.calculateVolumeBy === 'totalShipment' ? (
                <Grid item xs={2}>
                    <PTextField
                        name={`${lineItemsFieldName}.${index}.totalCube`}
                        label={showLabels ? 'Total Cube (m3)' : undefined}
                        {...commonNumberProps}
                    />
                </Grid>
            ) : (
                <Grid item xs={2}>
                    <PTextFieldReadOnly label={showLabels ? 'Cube(m3)' : undefined} size={size} value={cube.toFixed(4)} />
                </Grid>
            )}
        </>
    );
};

const TotalsRow = () => {
    const { values } = useFormikContext<CheckinSheetFormValues>();
    const { cubeTotal, unitTotal, weightTotal } = useGetRowTotals(values as Consignment);

    return (
        <Grid container spacing={0.5} columns={13.5} marginBottom={2.5}>
            <Grid item xs={2}>
                Stock Totals:
            </Grid>
            <Grid item xs={1.5} sx={{ paddingLeft: '30px !important' }}>
                {unitTotal}
            </Grid>
            <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}>
                {weightTotal} kg
            </Grid>
            {/* {cubeTotal ? null : (
        <> */}
            <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
            <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
            <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
            {/* </>
      )} */}
            <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}>
                {cubeTotal.toFixed(4)}
            </Grid>
        </Grid>
    );
};
const convertBase64toFile = (dataurl: any, filename: string) => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let byteString = atob(dataurl.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return window.URL.createObjectURL(new Blob([ab], { type: mime }));
};
const BooleanOptions = () => {
    const { isLoggedIn, user } = useContext(UserContext);
    const handleSignatureClear = () => {
        setFieldValue('receivedBySignature', undefined);
    };
    const _props = useFormikContext<CheckinSheetFormValues>();
    const getFormikTextFields = useGetFormikTextFields();
    const formikProps = getFormikTextFields(_props);

    const handleCaptureSignature = (blob: Blob | null) => {
        setFieldValue('receivedBySignature', blob);
    };
    const { values, setFieldValue, handleChange, initialValues } = useFormikContext<CheckinSheetFormValues>();
    useEffect(() => {

    }, [setFieldValue]);
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PCheckbox name="knownShipper" label="Known Shipper" />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="sufficientPackaging" label="Sufficient Packaging" />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="spmPallet" label="SPM Pallet: Indicate if the pallet complies with ISPM (International Standards for Phytosanitary Measures) regulations." />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PCheckbox name="statementOfContent" label="Statement of Content: Leave blank if unsure (Yes/No)." />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="tamperEvidentForm" label="Tamper Evident Form: Was the shipment received in tamper-evident form? " />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="fragile" label="Fragile" />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PCheckbox name="labelsAttached" label="Booking docs/Labels attached ?" />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="damaged" label="Damaged?" />
                </Grid>
                <Grid item xs={4}>
                    <PCheckbox name="temperatureControlRequired" label="Temperature Controlled" />
                </Grid>
            </Grid>
            <br />
            {values.temperatureControlRequired && (
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <PTextFieldMemo
                            value={get(values, 'temperatureControlReason')}
                            name="temperatureControlReason"
                            fullWidth
                            label="Required Temperature"
                            {...formikProps}
                        />
                    </Grid>
                </Grid>
            )}

            <RadioGroup value={values.goodsType} name="goodsType" onChange={handleChange}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            value="general"
                            control={
                                <Radio
                                    icon={<CircleIcon fontSize="large" sx={{ color: Colors.grey.bg }} />}
                                    checkedIcon={<CheckCircleIcon fontSize="large" sx={{ color: Colors.greyscale.offBlack }} />}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 35,
                                        },
                                    }}
                                />
                            }
                            label="General Goods"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            value="dangerous"
                            control={
                                <Radio
                                    icon={<CircleIcon fontSize="large" sx={{ color: Colors.grey.bg }} />}
                                    checkedIcon={<CheckCircleIcon fontSize="large" sx={{ color: Colors.greyscale.offBlack }} />}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 35,
                                        },
                                    }}
                                />
                            }
                            label="Dangerous Goods"
                        />

                    </Grid>

                </Grid>

                <br />
                {values.goodsType == 'dangerous' && (
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <PTextFieldMemo
                                value={get(values, 'dangerousGoodsReason')}
                                name="dangerousGoodsReason"
                                fullWidth
                                label="Type of Dangerous Goods"
                                {...formikProps}
                            />
                        </Grid>
                    </Grid>
                )}
            </RadioGroup>

            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PCheckbox name="physicallyScanned" label="Physicaly Scanned" />
                </Grid>

            </Grid>
            <Divider />

            <Grid item xs={5}>
                {/* hide when adding a user to esure depots are reloaded */}
                <UserSelect
                    name="receivedBy"
                    label="Received By"
                    depot={user?.depotIds && user.depotIds.length > 0 ? user.depotIds[0] : ''}
                    sx={{ marginBottom: 3 }}
                />

                <SignaturePad onClear={handleSignatureClear} onCaptureSignature={handleCaptureSignature} />

            </Grid>
            <Grid item xs={5}>
                <FileUpload documents={values.proofOfDelivery?.photos}></FileUpload>
            </Grid>

            <br />
            <Grid item>
                <Typography variant="displayXSmallBold" >
                    Currently attached Document(s)
                </Typography>
                {
                    values.proofOfDelivery && values.proofOfDelivery?.photos && values.proofOfDelivery?.photos.map((item: any) => {
                        return <><a href={item.signedUrl} download={item.name ?? item.filename} target='_blank'>
                            {item.name}
                        </a> <br /></>
                    })
                }

            </Grid>
        </>
    );
};

export default CheckinSheetOrderDetailsSection;
