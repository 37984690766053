import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import React from 'react';

type Props = DialogProps & {
  title: string;
  contentText: string;
  okButtonText?: string;
};
const SimpleDialog = (props: Props) => {
  const { title, contentText, okButtonText = 'OK', onClose, ...otherProps } = props;
  const handleClose = () => {
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <Dialog onClose={handleClose} {...otherProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
