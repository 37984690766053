import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { useCallback } from 'react';
import useHandleError from '../useHandleError';
// let cancelToken: CancelTokenSource;

export const usePublicRoute = () => {
  const handleError = useHandleError();
  const publicRoute = useCallback(
    async (options: AxiosRequestConfig, source?: { token: CancelToken } | undefined) => {
      return axios({
        ...options,
        cancelToken: source ? source.token : undefined,
        headers: {
          Pragma: 'no-cache',
          OauthToken:localStorage.getItem("authToken") ?? ""
        },
        responseType: 'json',
        withCredentials: true,
      })
        .then((response) => response.data)
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            handleError(error);
          }
        });
    },
    [handleError]
  );

  return publicRoute;
};
