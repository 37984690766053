import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Depot } from '../../../types';
import useDepotApiRoutes from '../../hooks/api/useDepotApiRoutes';
import { PSelectAsync } from '../../pegasus/PSelectAsync';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/base';
import { ThemedTextFieldProps } from '../../pegasus/PTextField';
import UserContext from '../../context/user';

interface OptionType {
  addNew?: boolean;
  companyName: string;
}

const filter = createFilterOptions<OptionType>();

const DepotSelect = ({
  countryCode,
  name,
  value,
  label,
  onAddDepot,
  setFieldValue,
  onChange,
  containerClass,
  multiple = false,
  restrictDepots = false,
}: {
  countryCode: string;
  name: string;
  value: string | string[] | null;
  label: string;
  onAddDepot?: () => void;
  setFieldValue: (field: string, value: string | null | string[]) => void;
  onChange?: (depot: Depot | Depot[] | null) => void;
  multiple?: boolean;
  restrictDepots?: boolean;
} & Pick<ThemedTextFieldProps, 'containerClass'>) => {
  const [options, setOptions] = useState<Depot[]>([]);
  const selectedOption = multiple
    ? options.filter((o) => value?.includes(o._id))
    : options.find((o) => o._id === value) || null;
  const { list } = useDepotApiRoutes();
  const { user } = useContext(UserContext);

  useEffect(() => {
    let active = true;

    list(countryCode).then((depots) => {
      if (active) {
        if (user?.type === 'admin' || restrictDepots == false) {
          setOptions(depots);
        } else {
          setOptions(depots.filter((d) => user?.depotIds?.includes(d._id)));
        }
      }
    });
    return () => {
      active = false;
    };
  }, [countryCode, list, restrictDepots, user?.depotIds, user?.type]);

  const handleDepotChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, option: unknown) => {
      if (multiple) {
        if (option === null) {
          setFieldValue(name, []);
          if (onChange) {
            onChange(option);
          }
        } else if ((option as OptionType[]).some((o) => o.addNew === true)) {
          if (onAddDepot) {
            onAddDepot();
          }
        } else {
          setFieldValue(
            name,
            (option as Depot[]).map((d) => d._id)
          );
          if (onChange) {
            onChange(option as Depot[]);
          }
        }
      } else {
        if (option === null) {
          setFieldValue(name, '');
          if (onChange) {
            onChange(option);
          }
        } else if ((option as Depot)._id) {
          setFieldValue(name, (option as Depot)._id);
          if (onChange) {
            onChange(option as Depot);
          }
        } else if ((option as OptionType).addNew && onAddDepot) {
          onAddDepot();
        }
      }
    },
    [multiple, name, onAddDepot, onChange, setFieldValue]
  );

  const filterOptions = onAddDepot
    ? (options: unknown[], params: FilterOptionsState<unknown>) => {
        const filtered = filter(options as Depot[], params);
        // Suggest the creation of a new value
        filtered.push({
          addNew: true,
          companyName: 'Add New',
        });
        return filtered;
      }
    : undefined;

  const isOptionEqualToValue = useCallback(
    (option, value) => {
      if (multiple) {
        // return value ? value.some((d: Depot) => d._id === (option as Depot)._id) : false;
        return value ? (option as Depot)._id === (value as Depot)._id : false;
      } else {
        return value ? (option as Depot)._id === (value as Depot)._id : false;
      }
    },
    [multiple]
  );

  const getOptionLabel = useCallback((option: unknown) => {
    return (option as Depot)?.companyName || '';
  }, []);

  return (
    <PSelectAsync
      multiple={multiple}
      name={name}
      label={label}
      value={selectedOption}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleDepotChange}
      filterOptions={filterOptions}
      containerClass={containerClass}
    />
  );
};

export default React.memo(DepotSelect);
