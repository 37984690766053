import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Depot, User, Vehicle } from '../../types';
import useUserApiRoutes from '../hooks/api/useUserApiRoutes';
import { red, purple, indigo, blue, cyan, green, yellow, orange } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import SocketContext from './socket';
import { ConsignmentProvider } from './consignments';
import useDepotApiRoutes from '../hooks/api/useDepotApiRoutes';
import AppDrawer from '../components/Modals/AppDrawer';
import AssignDriverModal from '../pages/Dispatch/components/AssignDriverModal';
import ModalContext from './modal';
import useVehicleApiRoutes from '../hooks/api/useVehicleApiRoutes';

const DepotContext = React.createContext<{
  drivers: User[];
  vehicles: Vehicle[];
  setDrivers: React.Dispatch<React.SetStateAction<User[]>>;
  depot?: Depot;
}>({
  drivers: [],
  vehicles: [],
  setDrivers: () => undefined,
});

export default DepotContext;

export const DepotProvider = ({ children }: { children: React.ReactNode }) => {
  const { socket } = useContext(SocketContext);
  const { isDrawerOpen, consignment } = useContext(ModalContext);
  const { getDepot } = useDepotApiRoutes();
  const [drivers, setDrivers] = useState<User[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [depot, setDepot] = useState<Depot>();
  const { countryCode = '' } = useParams<'countryCode'>();
  const { depotId } = useParams<'depotId'>();
  const { list } = useUserApiRoutes();
  const { list: listVehicles } = useVehicleApiRoutes();
  const userColors = useRef([
    { name: 'red', hex: red[500] },
    { name: 'purple', hex: purple[500] },
    { name: 'indigo', hex: indigo[500] },
    { name: 'blue', hex: blue[500] },
    { name: 'cyan', hex: cyan[500] },
    { name: 'green', hex: green[500] },
    { name: 'yellow', hex: yellow[500] },
    { name: 'orange', hex: orange[500] },
  ]).current;

  const active = useRef(true);

  const refreshDrivers = useCallback(async (): Promise<void | User[]> => {
    if (depotId) {
      const { users } = await list({ depots: [depotId], unlimit: true, driversOnly: true });
      if (active.current) {
        const drivers =   users.filter((item) => item.type == "driver");
        drivers.forEach((user, index) => {
          user.driverInformation = user.driverInformation || { isActive: false };
          if (user.driverInformation?.color === undefined) {
            user.driverInformation.color = userColors[index % userColors.length].hex;
          }
        });
        setDrivers(drivers);
      }
      return users;
    }
  }, [depotId, list, userColors]);

  useEffect(() => {
    let active = true;
    if (depotId) {
      getDepot(depotId).then((_depot) => {
        if (active) {
          setDepot(_depot);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [depotId, getDepot]);

  useEffect(() => {
    active.current = true;
    Promise.all([refreshDrivers()]).then(() => {
      if (active.current) {
        setIsLoading(false);
      }
    });
    return () => {
      active.current = false;
    };
  }, [refreshDrivers]);

  useEffect(() => {
    if (depot?.countryCode) {
      listVehicles(depot?.countryCode).then((_vehicles) => {
        setVehicles(_vehicles);
      });
    }
  }, [depot?.countryCode, listVehicles]);

  useEffect(() => {
    if (socket) {
      socket.on('update-vehicles', (vehicles: Vehicle[]) => {
        setVehicles((curVehicles) => {
          for (const curVehicle of curVehicles) {
            const vehicle = vehicles.find((v) => v.registrationNumber === curVehicle.registrationNumber);
            if (vehicle && vehicle.lastKnownPosition) {
              curVehicle.lastKnownPosition = { ...vehicle.lastKnownPosition };
            } else {
              delete curVehicle.lastKnownPosition;
            }
          }
          return [...curVehicles];
        });
      });
      socket.on('update-driver', (driver: User) => {
        setDrivers((curDrivers) => {
          
          if(driver.depotIds.includes(depotId??"") && driver.type == "driver"){
          const curIndex = curDrivers.findIndex((d) => d._id === driver._id );
          if (curIndex === -1) {
            // add to array
            return [driver, ...curDrivers];
          } else {
            // replace with new value
            driver.driverInformation = driver.driverInformation || {};
            driver.driverInformation.color =
              driver.driverInformation?.color || curDrivers[curIndex].driverInformation?.color;
            curDrivers.splice(curIndex, 1, driver);
          
          }
        }
        return [...curDrivers];
        });
      });
    }

    return () => {
      if (socket) {
        socket.off('update-driver');
      }
    };
  }, [socket]);

  return (
    <DepotContext.Provider
      value={{
        vehicles,
        drivers,
        setDrivers,
        depot,
      }}
    >
      {depot ? (
        <ConsignmentProvider queryParams={{ countryCode: countryCode, depot: depotId, archived: false, unlimit: true }}>
          {isLoading ? (
            <Box flex={1} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={100} />
            </Box>
          ) : (
            children
          )}
          <AppDrawer
            headerText={
              <Typography variant="displayXSmallBold" marginY={3}>
                Assign Consignment
                <br />#{consignment?.consignmentId}
              </Typography>
            }
            open={isDrawerOpen('assign-driver') && !!consignment}
            drawerType="assign-driver"
          >
            <AssignDriverModal />
          </AppDrawer>
        </ConsignmentProvider>
      ) : null}
    </DepotContext.Provider>
  );
};
