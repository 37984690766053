import { useCallback } from 'react';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';
import { serialize } from 'object-to-formdata';
import { View } from '../../../types';



export default function useCheckinApiRoutes() {
  const publicRoute = usePublicRoute();

  const createView = useCallback(
    async (view: Partial<View>): Promise<View> => {

      const formData = serialize(view, { allowEmptyArrays: true, indices: true }, undefined);

      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/views`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );

  const updateView = useCallback(
    async (view: Partial<View>): Promise<View> => {

      const formData = serialize(view, { allowEmptyArrays: true, indices: true }, undefined);

      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/views/${view._id}`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );

  const deleteView = useCallback(
    async (view: Partial<View>): Promise<View> => {

      const formData = serialize(view, { allowEmptyArrays: true, indices: true }, undefined);

      const response = await publicRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/views/${view._id}`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );



  const listView = useCallback(
    async (): Promise<{ views: View[]; total: number }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/views`,
      });
      return response;
    },
    [publicRoute]
  );




  return {
    createView,
    listView,
    updateView,
    deleteView
  };
}
