import { Box, Button, Theme, Typography } from '@mui/material';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import React from 'react';
import LoginImage from '../imgs/LoginImage.png';
import { createStyles, makeStyles } from '@mui/styles';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import Countries, { Country } from '../constants/countries';
import { PCheckbox } from '../pegasus/PCheckbox';
import { Colors } from '../pegasus/Colors';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    cols: {
      flex: 1,
      height: '100%',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    },
    loginContainer: {
      marginTop: theme.spacing(25),
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(16),
    },
    form: {
      maxWidth: '428px',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '36px',
    },
  })
);

interface MyFormValues {
  selectedCountyCode: string;
}

const SelectCountry = () => {
  const classes = styles();
  const navigate = useNavigate();
  const initialValues: MyFormValues = {
    selectedCountyCode: Countries[0].code,
  };
  const handleNext = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    setSubmitting(false);
    navigate(`/login/${values.selectedCountyCode}`);
  };

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (!values.selectedCountyCode) {
      errors.selectedCountyCode = 'Required';
    }
    return errors;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.cols}>
        <img className={classes.image} src={LoginImage} alt="Login" />
      </Box>
      <Box className={classes.cols}>
        <Box className={classes.loginContainer}>
          <Typography variant="displayLargeBold" marginBottom={3.5}>
            Select Your Country
          </Typography>
          <Formik initialValues={initialValues} validate={validate} onSubmit={handleNext}>
            {({ handleSubmit, isSubmitting, isValid }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <div className={classes.buttonGroup}>
                    {Countries.map((country) => (
                      <CountryButton key={country.code} country={country} />
                    ))}
                  </div>

                  <Button
                    sx={{ mb: 2.5 }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Next
                    <ArrowForwardIcon sx={{ marginLeft: 1 }} />
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

const countryButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: Colors.grey.bg,
      borderRadius: '16px',
      border: 'none',
      padding: '35px 41px 46px 41px',
      maxWidth: '135px',
      maxHeight: '170px',
      position: 'relative',
    },
    selected: {
      borderColor: Colors.primary.main,
      backgroundColor: Colors.grey[400],
      borderWidth: '2px',
      borderStyle: 'solid',
      padding: '33px 39px 44px 39px',
    },
    countryName: {
      fontFamily: 'Inter',
      fontSize: '15px',
      lineHeight: '24px',
    },
    checkBox: {
      position: 'absolute',
      top: '0px',
      left: '10px',
    },
  })
);
type CountryButtonProps = {
  country: Country;
};
const CountryButton = ({ country }: CountryButtonProps) => {
  const classes = countryButtonStyles();
  const { values, setFieldValue } = useFormikContext<MyFormValues>();
  const selected = values.selectedCountyCode === country.code;
  const handleCountrySelect = () => {
    setFieldValue('selectedCountyCode', country.code);
  };
  return (
    <ButtonUnstyled class={`${classes.root} ${selected ? classes.selected : ''}`} onClick={handleCountrySelect}>
      {selected ? (
        <div className={classes.checkBox}>
          <PCheckbox name={country.code} label="" size="small" checked={selected} />
        </div>
      ) : null}
      <div>
        <img src={country.image} alt={country.name} />
      </div>
      <div className={classes.countryName}>{country.name}</div>
    </ButtonUnstyled>
  );
};

export default SelectCountry;
