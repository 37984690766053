import React, { useContext } from 'react';
import { UserTableField } from '../../../../types';
import TableHeader from './TableHeader';
import { Typography } from '@mui/material';
import { DriverActions } from './components';
import UserTableRow from './UserTableRow';
import DepotContext from '../../../context/depot';
import { Colors } from '../../../pegasus/Colors';

const TableFields: UserTableField[] = [
  {
    field: 'firstName',
    label: 'FIRST NAME',
    columns: 3,
  },
  {
    field: 'lastName',
    label: 'LAST NAME',
    columns: 3,
  },
  {
    field: 'phone',
    label: 'MOBILE #',
    columns: 3,
  },
  {
    field: 'driverInformation.currentRegistration',
    label: 'ASSIGNED TRUCK',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (user) => (
      <Typography variant="linkXSmall">{user.driverInformation?.currentRegistration || '-'}</Typography>
    ),
  },
  {
    field: 'status',
    label: 'STATUS',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (user) => {
      const isActive = user.driverInformation?.isActive ? true : false;
      return (
        <Typography variant="linkXSmall" color={isActive ? Colors.primary.dark : Colors.greyscale.coolGrey500}>
          {isActive ? 'ACTIVE' : 'OFFLINE'}
        </Typography>
      );
    },
  },
  {
    field: 'actions',
    label: '',
    columns: 4,
    // eslint-disable-next-line react/display-name
    renderField: (user) => <DriverActions driver={user} />,
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);

const DriversTable = () => {
  const { drivers } = useContext(DepotContext);

  return (
    <>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      {drivers.map((driver) => {
        return <UserTableRow key={driver._id} user={driver} tableFields={TableFields} columnCount={totalColumns} />;
      })}
    </>
  );
};

export default DriversTable;
