import { Box, Chip } from '@mui/material';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { PTextField, ThemedTextFieldProps } from './PTextField';

export const PSelect = (props: ThemedTextFieldProps & { multiple?: boolean }) => {
  const { multiple = false, SelectProps, name, ...otherProps } = props;
  const { values, setFieldValue, handleChange } = useFormikContext();

  const handleDelete = useCallback(
    (event: Event, value: unknown) => {
      setFieldValue(
        props.name,
        get(values, props.name).filter((v: unknown) => v !== value)
      );
    },
    [props.name, setFieldValue, values]
  );

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
  };

  const renderChips = useCallback(
    (selected) => (
      <Box display="flex" flexWrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip
            key={value}
            style={{ marginRight: 8, marginBottom: 4 }}
            label={value}
            color="primary"
            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
            onDelete={(e) => handleDelete(e, value)}
          />
        ))}
      </Box>
    ),
    [handleDelete]
  );
  return (
    <PTextField
      name={name}
      fullWidth
      select
      onChange={handleChangeValue}
      SelectProps={{
        renderValue: multiple ? renderChips : undefined,
        multiple: multiple,
        // MenuProps: { getContentAnchorEl: null, anchorOrigin: { horizontal: 'left', vertical: 'bottom' } },
        // MenuProps: { anchorOrigin: { horizontal: 'left', vertical: 'bottom' } },
        ...SelectProps,
      }}
      {...otherProps}
    />
  );
};
