import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import UserForm from './UserForm';
import { User } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import { Colors } from '../../../pegasus/Colors';
import { Divider, Button, Box } from '@mui/material';
import UserContext from '../../../context/user';

const EditUserModal = () => {
  const { closeDrawer, user } = useContext(ModalContext);
  const { editProfile, updateIsActive, deleteUserAcccount } = useUserApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { user: userLogin } = useContext(UserContext);

  const handleSubmit = (values: Partial<User>, { setSubmitting }: FormikHelpers<Partial<User>>) => {
    editProfile(values)
      .then(() => {
        setSubmitting(false);
        showSnack('User updated!', 'success');
        closeDrawer('edit-user');
      })
      .catch(() => setSubmitting(false));
  };

  const handleDeactivate = () => {
    if (user && user._id) {
      updateIsActive(user._id, !user.active).then((userRes) => {
        if (userRes.active) {
          showSnack('Actived Account!', 'success');
        } else {
          showSnack('Deactivated Account!', 'success');
        }
        closeDrawer('edit-user');
      });
    }
  };

  const handleDeleteAccount = () => {
    if (user && user._id) {
      deleteUserAcccount(user._id).then((userRes) => {
        if (userRes) {
          showSnack('Delete Account!', 'success');
        }
        closeDrawer('edit-user');
      });
    }
  };

  return (
    <UserForm onSubmit={handleSubmit} initialValues={{ ...user }}>
      <SubmitButton sx={{ marginRight: 1, backgroundColor: Colors.greyscale.offBlack, marginBottom: '44px' }}>
        Save Edits
      </SubmitButton>
      <Divider />
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleDeactivate} variant="outlined" color="inherit" sx={{ marginTop: '24px' }}>
          Deactivate Account
        </Button>
        {userLogin && userLogin.type && ['admin', 'branch-manager'].includes(userLogin.type) ? (
          <Button
            onClick={handleDeleteAccount}
            variant="contained"
            sx={{ marginTop: '24px', backgroundColor: Colors.greyscale.offBlack }}
          >
            Delete Account
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </UserForm>
  );
};

export default EditUserModal;
