import React, { useContext, useMemo, useState } from "react";
import { DEFAULT_PAGE, ITEM_PER_PAGE } from "../../../constants/table";
import { ConsignmentProvider } from "../../../context/consignments";
import { PIcon } from "../../../pegasus/PIcon";
import CurrentTable from "./CurrentTable";
import ScheduledTable from "./ScheduledTable";
import CheckinTable from "./CheckinTable";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import TabLabel from "../../../components/TabLabel";
import useShowIndicator from "../../../hooks/useShowIndicator";
import { Colors } from "../../../pegasus/Colors";
import { Theme, Box, Tabs, Tab, Button } from "@mui/material";
import countries from "../../../constants/countries";
import ModalContext from "../../../context/modal";
import { CheckinSheetProvider } from "../../../context/checkins";
const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: Colors.primary.background,
            flex: 1,
        },
        tabContent: {
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
        darkButton: {
            backgroundColor: Colors.primary.darkMode,
            marginRight: theme.spacing(1),
        },
        select: {
            width: 200,
        },
    })
);
const CheckinDashboard = ({ itemsPerPage, countryCode,filters,  page }: any) => {
    const classes = styles();
    const tabs = useMemo<
        {
            key: string;
            name: string;
            icon: JSX.Element;
            indicatorStatuses: string[];
            component: JSX.Element | string;
        }[]
    >(
        () => [
            {
                key: 'active',
                name: 'Active',
                icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="truck" />,
                indicatorStatuses: ['assigned', 'inTransit', 'assignedLeg2', 'inTransitLeg2'],
                component: (
                    <CheckinSheetProvider
                        checkinQueryParams={{
                            countryCode: countryCode,
                            status: ['unassigned'],
                            page: page,
                            itemsPerPage: itemsPerPage,
                        }}
                    >
                        <CheckinTable  
                        filters={filters}
                        status={'unassigned'} />
                    </CheckinSheetProvider>
                ),
            },
            {
                key: 'completed',
                name: 'Archived',
                icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="time" />,
                indicatorStatuses: [],
                component: (
                    <CheckinSheetProvider
                        checkinQueryParams={{
                            countryCode: countryCode,
                            status: ['complete'],
                            page: page,
                            itemsPerPage: itemsPerPage,
                        }}
                    >
                        <CheckinTable 
                        filters={filters}
                        status={'complete'}  />
                    </CheckinSheetProvider>
                ),
            }
        ],
        [countryCode,filters]
    );
    const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);
    const currentTab = tabs.find((tab) => tab.key === currentTabKey) || tabs[0];
    const handleChange = (event: React.SyntheticEvent, newKey: string) => {
        setCurrentTabKey(newKey);
    };
    const showIndicator = useShowIndicator();
    const { openDrawer, setCountry } = useContext(ModalContext);
    const showAddNewModal = () => {
        setCountry(countries.find((c) => c.code === countryCode));
        openDrawer('new-checkinsheet');
    };
    return (
        <Box className={classes.container}>
            <Button
                sx={{ marginTop: 0.5, marginBottom: 1, float: "right" }}
                variant="contained"
                size="medium"
                onClick={showAddNewModal}
            >
                <PIcon sx={{ marginRight: 1 }} name="plus" />
                Add New Check in Sheet
            </Button>
            <Tabs value={currentTabKey} onChange={handleChange} variant="fullWidth">
                {tabs.map((tab) => (
                    <Tab
                        key={tab.key}
                        value={tab.key}
                        label={<TabLabel label={tab.name} showIndicator={showIndicator(tab.indicatorStatuses)} />}
                        icon={tab.icon}
                        iconPosition="start"
                    />
                ))}
            </Tabs>
            <Box className={classes.tabContent}>{currentTab.component}</Box>
        </Box>

    );
}

export default CheckinDashboard;
