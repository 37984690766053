import { Box, Divider, Grid, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { PTextFieldMemo, useGetFormikTextFields } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { Consignment, Depot } from '../../../../types';
import SenderSection from './SenderSection';
import ReceiverSection from './ReceiverSection';
import OrderDetailsSection from './OrderDetailsSection';
import DepotSelect from '../../../components/form/DepotSelect';
import orderTypes from '../../../constants/orderTypes';
import omit from 'lodash/omit';
import useDepotApiRoutes from '../../../hooks/api/useDepotApiRoutes';
import { get } from 'lodash';
import useValidators from '../../../hooks/useValidators';

export type ConsignmentFormValues = Partial<Consignment>;

type Props = {
  initialValues: ConsignmentFormValues;
  readOnlyValues?: (keyof ConsignmentFormValues)[];
  onSubmit: (
    values: ConsignmentFormValues,
    formikHelpers: FormikHelpers<ConsignmentFormValues>
  ) => void | Promise<Consignment>;
  children: React.ReactNode;
};
const OrderTypeSelectComp = ({
  onChange,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}) => {
  return (
    <PSelect name="orderType" label="Order Type" fullWidth onChange={onChange}>
      {orderTypes.filter((item) => item.displayInConsignment).map((orderType) => (
        <MenuItem key={orderType.code} value={orderType.code}>
          {orderType.name}
        </MenuItem>
      ))}
    </PSelect>
  );
};

const OrderTypeSelect = React.memo(OrderTypeSelectComp);

const ConsignmentForm = ({ initialValues, onSubmit, children, readOnlyValues }: Props) => {
  const { validateConsignment } = useValidators();

  const processInitialValues = useCallback(() => {
    const toReturn = { ...initialValues };
    if ((toReturn.depot as Depot)?._id) {
      toReturn.depot = (toReturn.depot as Depot)._id;
    }
    return toReturn;
  }, [initialValues]);

  return (
    <Formik initialValues={processInitialValues()} validate={validateConsignment} onSubmit={onSubmit}>
      {() => <FormComponent readOnlyValues={readOnlyValues}>{children}</FormComponent>}
    </Formik>
  );
};

const FormComponent = ({ children, readOnlyValues }: Pick<Props, 'children' | 'readOnlyValues'>) => {
  const classes = useStyles();
  const _props = useFormikContext<ConsignmentFormValues>();
  const [countryCode, setCountryCode] = useState('');
  const getFormikTextFields = useGetFormikTextFields();
  const formikProps = getFormikTextFields(_props);
  const { setFieldValue, values, handleSubmit } = _props;
  const [depots, setDepots] = useState<Depot[]>([]);
  const { list } = useDepotApiRoutes();
  useEffect(() => {
    let active = true;
    if (_props.values.countryCode) {
      setCountryCode(_props.values.countryCode);
      list(_props.values.countryCode).then((depots) => {
        if (active) {
          setDepots(depots);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [_props.values.countryCode, list, setCountryCode]);

  const updateAddressFields = useCallback(
    (orderType: string, depot?: Depot) => {
      if (orderType === 'drop-off' && depot) {

        let details:any = { ...omit(depot, ['name', 'createdAt', 'updatedAt'])};
        if(depot.countryCode == "NZ") {
          details = { ...omit(depot, ['name', 'createdAt', 'updatedAt','contactName','phone','email']),
           contactName:"Imports Team",
           phone:"099663850",
           email:"imports.nz@ubfreight.com",
           milestones: false, labels: true };

        }
        if(depot.countryCode == "AU") {
          details = { ...omit(depot, ['name', 'createdAt', 'updatedAt','contactEmails','contactName','phone','email']),
           contactName:"Imports Team",
           phone:"296691774",
           email:" importair.au@ubfreight.com",
           contactEmails:["importsea.au@ubfreight.com"],
           milestones: false, labels: true };

        }

        setFieldValue('sender', details);
        setFieldValue('receiver', { countryCode: values.countryCode });
      }
      if (orderType === 'pick-up' && depot) {


        let details:any = { ...omit(depot, ['name', 'createdAt', 'updatedAt'])};
        if(depot.countryCode == "NZ") {
          details = { ...omit(depot, ['name', 'createdAt', 'updatedAt','contactEmails','contactName','phone','email']),
           contactName:"Exports Team",
           phone:"099663850",
           email:"exportair.nz@ubfreight.com",
           contactEmails:["exportsea.nz@ubfreight.com"],
          };

        }
        if(depot.countryCode == "AU") {
          details = { ...omit(depot, ['name', 'createdAt', 'updatedAt','contactEmails','contactName','phone','email']),
           contactName:"Exports Team",
           phone:"296691774",
           email:"exportair.au@ubfreight.com",
           contactEmails:["exportsea.au@ubfreight.com"],
           };

        }


        setFieldValue('receiver', details);
        setFieldValue('sender', { milestones: false, labels: true, countryCode: values.countryCode });
      }
    },
    [setFieldValue, values.countryCode]
  );

  const handleChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, value);
      const depot = depots.find((d) => d._id === values.depot);
      if (depot) {
        updateAddressFields(value, depot);
      } else {
        updateAddressFields(value, depots[0]);
        setFieldValue('depot', depots[0]._id);
      }
    },
    [depots, setFieldValue, updateAddressFields, values.depot]
  );

  const handleDepotChange = useCallback(
    (depot: Depot | Depot[] | null) => {
      const orderType = values.orderType;
      if (orderType && depot) {
        updateAddressFields(orderType, depot as Depot);
      }
    },
    [updateAddressFields, values.orderType]
  );

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Box overflow="auto" padding={6.5} paddingBottom={4}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <PTextFieldMemo
              value={get(values, 'consignmentId')}
              disabled={readOnlyValues?.includes('consignmentId')}
              name="consignmentId"
              fullWidth
              label="Consignment Id"
              {...formikProps}
            />
          </Grid>
          <Grid item xs={4}>
            <OrderTypeSelect onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <DepotSelect
              countryCode={countryCode}
              name="depot"
              value={values.depot as string}
              label="Regional Depot"
              onChange={handleDepotChange}
              setFieldValue={setFieldValue}
              restrictDepots={true}
            />
          </Grid>
        </Grid>
        <Divider />
        <SenderSection values={values} {...formikProps} />
        <ReceiverSection values={values} {...formikProps} />
        <OrderDetailsSection />
      </Box>
      <Box padding={6.5} paddingBottom={4}>
        {children}
      </Box>
    </form>
  );
};

export default ConsignmentForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
