import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TableFieldBase } from '../../../../types';
import { Colors } from '../../../pegasus/Colors';
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles(() =>
  createStyles({
    cell: {
      paddingLeft: 18,
      paddingRight: 16,
    },
  })
);

const TableHeader = ({ tableFields, columnCount }: { tableFields: TableFieldBase[]; columnCount: number }) => {
  const classes = styles();
  return (
    <Grid container columns={columnCount} marginBottom={2}>
      {tableFields.map((field, index) => (
        <Grid key={index} item xs={field.columns} className={classes.cell}>
          <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
            {field.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
export default TableHeader;
