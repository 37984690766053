import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';
import { CalculatedBy, LineItem, User } from '../../../../types';
import ViewConsignmentLabel from './ViewConsignmentLabel';
import useGetTotalCube, { useGetRowTotals, useGetRowTotalsCheckin } from '../../../hooks/useGetTotalCube';
import useGetLineItem from '../../../hooks/useGetLineItem';
import { Colors } from '../../../pegasus/Colors';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import { Buffer } from 'buffer';
import ImageViewer from "react-simple-image-viewer";

import consignment from '../../../../server/models/consignment';
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      backgroundColor: Colors.primary.contrastText,
      border: `2px solid ${Colors.primary.background}`,
    },
    image: {
      width: '100%',
    },
  })
);
const convertBase64toFile = (dataurl:any, filename:string) => {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let  byteString = window.atob(dataurl.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return  window.URL.createObjectURL(new Blob([ab], { type: mime }));
};

const downloadBase64 = (dataurl:any, filename:string) => {
    let a = document.createElement("a"); //Create <a>
    a.href = dataurl; //Image Base64 Goes here
    a.download = filename; //File name Here
    a.click();
}

const base64ToUint8Array =(base64:any) => {
  const base64String = base64.split(',')[1];
  const binaryString = Buffer.from(base64, 'base64').toString('binary');
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const downloadImage = (base64:any, filename:any) =>{
  const base64String = base64.split(',')[1];
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const byteArray = base64ToUint8Array(base64String);
  const blob = new Blob([byteArray], { type: mimeString });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(url);
}

const OrderDetailsCheckinSectionReadOnly = ({ contextColor }: { contextColor: string }) => {
  const { checkinsheet } = useContext(ModalContext);
  const classes = styles();
  const [receivedByUser, setReceivedByUser] = useState<User>();
  const { getUser } = useUserApiRoutes();
  const finalImg= (checkinsheet?.proofOfDelivery && checkinsheet.proofOfDelivery.photos && checkinsheet.proofOfDelivery.photos.length? checkinsheet.proofOfDelivery.photos.map((item) => item.signedUrl) : []).map(String);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  useEffect(() => {
    let active = true;
    if (checkinsheet?.receivedBy) {
      getUser(checkinsheet?.receivedBy).then((user) => {
        if (active) {
          setReceivedByUser(user);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [checkinsheet?.receivedBy, getUser]);
  return checkinsheet ? (
    <>
      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography variant="displayXSmallBold" color={contextColor}>
            Order Details
          </Typography>
        </Grid>
      {/*   <Grid display="flex" item xs justifyContent="flex-end" color={contextColor}>
          <ViewConsignmentLabel />
        </Grid> */}
      </Grid>

      <Grid container spacing={1} mb={5} columns={7}>
        <Grid item xs={1}>
          <ReadOnlyField label="TYPE" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="UNITS" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="LENGTH (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="WIDTH (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="HEIGHT (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="WEIGHT (KG)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="CUBIC (M3)" />
        </Grid>
      </Grid>
      {checkinsheet.lineItems?.map((lineItem, index) => (
        <LineItemRow key={index} lineItem={lineItem} calculateVolumeBy={checkinsheet.calculateVolumeBy} />
      ))}
      <TotalsRow color={contextColor} />

      <Grid container spacing={1} mb={5} columns={4}>
        <Grid item xs={1}>
            <ReadOnlyField label="KNOWN SHIPPER" value={checkinsheet?.knownShipper ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="SUFFICIENT PACKAGING" value={checkinsheet?.sufficientPackaging ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="SPM Pallet" value={checkinsheet?.spmPallet ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="STATEMENT OF CONTENT" value={checkinsheet?.statementOfContent ? "YES": "NO"} />
        </Grid>
      </Grid>
      <Grid  container spacing={1} mb={5} columns={3}>
        <Grid item xs={1}>
            <ReadOnlyField label="TAMPER EVIDENT FORM" value={checkinsheet?.tamperEvidentForm ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="FRAGILE" value={checkinsheet?.fragile ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="DAMAGED" value={checkinsheet?.damaged ? "YES": "NO"} />
        </Grid>
        <Grid item xs={1}>
            <ReadOnlyField label="PHYSICALLY SCANNED" value={checkinsheet?.physicallyScanned ? "YES": "NO"} />
        </Grid>
      </Grid>

      <Grid container spacing={1} mb={5} columns={3}>
        <Grid item xs={1}>
              <ReadOnlyField label="DOCS/ LABELS ATTACHED" value={checkinsheet?.labelsAttached ? "YES": "NO"} />
          </Grid>
          <Grid item xs={1}>
              <ReadOnlyField label="TEMPERATURE CONTROLLED" value={checkinsheet?.labelsAttached ? checkinsheet?.temperatureControlReason: "NO"} />
          </Grid>
          <Grid item xs={1}>
              <ReadOnlyField label="DANGEROUS GOODS ?" value={checkinsheet?.goodsType == 'dangerous' ? checkinsheet?.dangerousGoodsReason: "NO"} />
          </Grid>
      </Grid>
      
      <Grid item mb={5}>
      <Typography variant="displayXSmallBold"  color={contextColor}>
            Document(s)
          </Typography>
          {
            checkinsheet.proofOfDelivery && checkinsheet.proofOfDelivery?.photos && checkinsheet.proofOfDelivery.photos.map((item:any) => {
              return <><a  href={item.signedUrl}   target='_blank'>
                {item.filename?? item.name}
              </a> <br/></>
            })
          }
      </Grid>
      <Box mb={2}>
      {checkinsheet.proofOfDelivery && checkinsheet.proofOfDelivery.photos && checkinsheet.proofOfDelivery.photos.length && checkinsheet.proofOfDelivery.photos.map((src, index) => (
        <img
          src={src.signedUrl}
          onClick={() => openImageViewer(index)}
          width="100"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={finalImg}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
{/*         <Carousel photos={consignment.proofOfDelivery.photos} />
 */}      </Box>
      <Grid item xs={5}>
          <Typography variant="textSmall">
            Signed by: {receivedByUser?.firstName} {receivedByUser?.lastName}
          </Typography>
          <img
            className={classes.image}
            src={checkinsheet.receivedBySignature?.signedUrl}
            alt={checkinsheet.receivedBySignature?.filename}
          />
      </Grid>
    </>
  ) : null;
};

const LineItemField = ({ text }: { text: string }) => {
  return (
    <Grid item xs={1}>
      <Typography variant="textSmall">{text}</Typography>
    </Grid>
  );
};
const LineItemRow = ({ lineItem, calculateVolumeBy }: { lineItem: LineItem; calculateVolumeBy: CalculatedBy }) => {
  const getTotalCube = useGetTotalCube();
  const getLineItem = useGetLineItem();
  const lineItemType = getLineItem(lineItem.type);
  const cube = getTotalCube(lineItem, calculateVolumeBy);
  return (
    <>
      <Grid container spacing={1} mb={1} columns={7} color="black">
        <LineItemField text={lineItemType?.name || ''} />
        <LineItemField text={lineItem.units.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment' && (lineItem.length ==null || lineItem.length ==undefined) ? '-' : lineItem.length.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment' && (lineItem.width ==null || lineItem.width ==undefined) ? '-' : lineItem.width.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment'  && (lineItem.height ==null || lineItem.height ==undefined) ? '-' : lineItem.height.toString()} />
        <LineItemField text={lineItem.weight ? lineItem.weight.toString() : "-"} />
        <LineItemField text={cube?(isNaN(cube)?cube.toString():cube.toFixed(4)):"0"} />
      </Grid>
      <Divider />
    </>
  );
};

const TotalsRow = ({ color }: { color: string }) => {
  const { checkinsheet } = useContext(ModalContext);
  const { cubeTotal, unitTotal, weightTotal } = useGetRowTotalsCheckin(checkinsheet);

  return (
    <Grid container spacing={1} columns={7} marginBottom={7} color={color}>
      <LineItemField text="TOTALS" />
      <LineItemField text={unitTotal.toString()} />
      <Grid item xs={3}></Grid>
      <LineItemField text={weightTotal.toString()} />
      <LineItemField text={cubeTotal.toFixed(4)} />
    </Grid>
  );
};

export default OrderDetailsCheckinSectionReadOnly;
