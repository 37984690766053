import React, { useContext, useEffect, useState } from 'react';
import { User, UserTableField } from '../../../../types';
import TableRow from '../../../components/Table/TableRow';
import TableHeader from '../../../components/Table/TableHeader';
import { IconButton, TablePagination, Typography } from '@mui/material';
import { PIcon } from '../../../pegasus/PIcon';
import { Colors } from '../../../pegasus/Colors';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import ModalContext from '../../../context/modal';
import { createStyles, makeStyles } from '@mui/styles';
import { startCase } from 'lodash';
import UserContext from '../../../context/user';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../../../constants/table';

const TableFields: UserTableField[] = [
  {
    field: 'depotInfo[0].companyName',
    label: 'DEPOT',
    columns: 5,
    default: '-',
    renderField: function fieldButton(user) {
      return <Typography variant="linkXSmall">{user.depotInfo.map((d) => d.companyName).join(', ')}</Typography>;
    },
  },
  {
    field: 'type',
    label: 'USER TYPE',
    columns: 3,
    renderField: function fieldButton(user: User) {
      return <Typography variant="linkXSmall">{startCase(user.type)}</Typography>;
    },
  },
  {
    field: 'firstName',
    label: 'FIRST NAME',
    columns: 3,
    default: '-',
  },
  {
    field: 'lastName',
    label: 'LAST NAME',
    columns: 3,
    default: '-',
  },
  {
    field: 'email',
    label: 'EMAIL',
    columns: 5,
    default: '-',
  },
  {
    field: 'phone',
    label: 'MOBILE #',
    columns: 5,
    default: '-',
  },
  {
    field: 'actions',
    label: '',
    columns: 2,
    position: 'right',
    renderField: function fieldButton(user: User) {
      return <ViewButton user={user} />;
    },
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);
const styles = makeStyles(() =>
  createStyles({
    paginationRoot: {
      color: Colors.primary.main,
      '& .MuiTablePagination-displayedRows': {
        color: Colors.primary.main,
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.25px',
        lineHeight: '18px',
      },
    },
  })
);

const ViewButton = ({ user }: { user: User }) => {
  const { openDrawer, setUser } = useContext(ModalContext);

  const handleClick = () => {
    if (setUser) {
      setUser(user);
      openDrawer('edit-user');
    }
  };
  return (
    <IconButton size="small" onClick={handleClick}>
      <PIcon name="edit" size={24} sx={{ color: Colors.primary.dark, display: 'block' }} />
    </IconButton>
  );
};

const UserTable = ({ countryCode }: { countryCode: string }) => {
  const classes = styles();
  const [users, setUsers] = useState<User[]>([]);
  const { list } = useUserApiRoutes();
  const { isDrawerOpen } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);

  useEffect(() => {
    list({ countryCode: countryCode, page: page, itemsPerPage: ITEM_PER_PAGE }).then((response) => {
      setUsers(response.users);
      setTotalItem(response.total);
    });
  }, [countryCode, list, page]);

  // reloaded data when updated
  useEffect(() => {
    if (!(isDrawerOpen('new-user') || isDrawerOpen('edit-user'))) {
      list({
        countryCode: countryCode,
        page: page,
        itemsPerPage: ITEM_PER_PAGE,
       // depots: user?.depotIds,
      }).then((response) => {
        setUsers(response.users);
        setTotalItem(response.total);
      });
    }
  }, [countryCode, isDrawerOpen, list, page, user?.depotIds, user?.type]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TablePagination
        component="div"
        count={totalItem}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={ITEM_PER_PAGE}
        rowsPerPageOptions={[]}
        classes={{ root: classes.paginationRoot }}
      />
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      {users.map((user) => {
        return <TableRow key={user._id} rowData={user} tableFields={TableFields} columnCount={totalColumns} />;
      })}
    </>
  );
};

export default UserTable;
