import React, { useContext } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import ModalContext from '../../../context/modal';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { SubmitButton } from '../../../pegasus/PSubmit';
import { PIcon } from '../../../pegasus/PIcon';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import SnackBarContext from '../../../context/snackbar';
import { Colors } from '../../../pegasus/Colors';

export const TabNotes = ({ contextColor }: { contextColor: string }) => {
  const { consignment, setConsignment } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { addSpecialInstruction } = useConsignmentApiRoutes();
  const classes = useStyles();

  const handleSubmit = (values: { text: string }, { setSubmitting, resetForm }: FormikHelpers<{ text: string }>) => {
    if (consignment) {
      setSubmitting(false);
      addSpecialInstruction(consignment._id, values.text)
        .then((_consignment) => {
          showSnack('Special Instruction Added!', 'success');
          setConsignment(_consignment);
          resetForm();
        })
        .finally(() => setSubmitting(false));
    }
  };

  return consignment ? (
    <>
      <Box color={contextColor}>
        <Formik initialValues={{ text: '' }} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box flex={1} mr={3}>
                <PTextField name="text" fullWidth label="Add another note to this consignment..." />
              </Box>
              <SubmitButton color="inherit" variant="outlined" className={classes.submit}>
                <PIcon name="plus" sx={{ marginRight: 1.5 }} />
                Add New Note
              </SubmitButton>
            </form>
          )}
        </Formik>
      </Box>
      <Typography variant="displayXSmallBold" color={contextColor}>
        Notes/History
      </Typography>
      <ul>
        {consignment.specialInstructions.map((specialInstruction, index) => {
          return specialInstruction.text.length > 0 ? (
            <li key={index}>
              <Typography
                variant="textSmall"
                fontWeight={600}
                color={specialInstruction.importance === 'high' ? Colors.error.main : Colors.greyscale.offBlack}
              >
                {specialInstruction.text}{' '}
              </Typography>
              <Typography variant="textSmall" className={classes.greyItalic}>
                Updated {format(new Date(specialInstruction.createdAt), 'd MMM, h:mm aa')}
                {'. '}
              </Typography>
              {specialInstruction.updatedByName ? (
                <Typography variant="textSmall" className={classes.greyItalic}>
                  {specialInstruction.updatedByName}
                </Typography>
              ) : null}
            </li>
          ) : null;
        })}
      </ul>
    </>
  ) : null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
    },
    submit: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    greyItalic: {
      color: Colors.greyscale.label,
      fontStyle: 'italic',
    },
  })
);
