import { TablePagination } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../../../constants/table';
import ConsignmentContext from '../../../context/consignments';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { Colors } from '../../../pegasus/Colors';
import useCheckinApiRoutes, { CheckinSheetQueryParams } from '../../../hooks/api/useCheckinApiRoutes';
import useViewApiRoutes from '../../../hooks/api/useViewApiRoutes';
import CheckinSheetContext from '../../../context/checkins';
import ViewContext from '../../../context/view';

const styles = makeStyles(() =>
  createStyles({
    paginationRoot: {
      color: Colors.primary.main,
      '& .MuiTablePagination-displayedRows': {
        color: Colors.primary.main,
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.25px',
        lineHeight: '18px',
      },
    },
  })
);

const TableFooter = ({isConsignment}:{
  isConsignment:boolean,
  params?:CheckinSheetQueryParams
}) => {
  const classes = styles();
  const { totalConsignments, queryParams, setConsignments, setTotalConsignments, setIsLoading } =
    useContext(ConsignmentContext);
    const { totalCheckinSheets, checkinQueryParams, setCheckinsheets, setTotalCheckinsheets } =
    useContext(CheckinSheetContext);
    const { saveView,  view} =
    useContext(ViewContext);
  const [page, setPage] = useState(queryParams.page);
  const { list: listConsignments } = useConsignmentApiRoutes();
  const {  listCheckinSheet } = useCheckinApiRoutes();
  const {  listView } = useViewApiRoutes();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };
  
  useEffect(() => {
    setIsLoading(true);
    listConsignments({ ...queryParams, page })
      .then((result) => {
        setIsLoading(false);
        setTotalConsignments(result.total);
        setConsignments(result.consignments);
        
      listView()
      .then((view) => {
        if (view) {
          saveView(view.views);
        }
      })
      })
      .catch(() => {
        setIsLoading(false);
      });

 
  }, [page, listConsignments,  queryParams,  setTotalConsignments, setConsignments,  setIsLoading]);
  
  useEffect(() => {
    setIsLoading(true);
    listCheckinSheet({ ...checkinQueryParams, page })
      .then((result) => {
        setIsLoading(false);
        setTotalCheckinsheets(result.total);
        setCheckinsheets(result.checkinsheets);
        
    
      })
      .catch(() => {
        setIsLoading(false);
      });

 
  }, [page,  totalCheckinSheets, checkinQueryParams, setTotalCheckinsheets, setCheckinsheets, setIsLoading]);


  return (
    <>
    {
      isConsignment && (
        <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalConsignments}
        rowsPerPage={queryParams.itemsPerPage || ITEM_PER_PAGE}
        page={page || DEFAULT_PAGE}
        onPageChange={handleChangePage}
        classes={{ root: classes.paginationRoot }}
      />
      )
    }
    {
      !isConsignment && (
        <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCheckinSheets}
        rowsPerPage={checkinQueryParams.itemsPerPage || ITEM_PER_PAGE}
        page={page || DEFAULT_PAGE}
        onPageChange={handleChangePage}
        classes={{ root: classes.paginationRoot }}
      />
      )
    }
     
    </>
  );
};

export default TableFooter;
