import { Box, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { Depot } from '../../../../types';
import countries from '../../../constants/countries';
import AutoCompleteAddress from '../../../components/form/AutoCompleteAddress';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';

type Props = {
  initialValues: Partial<Depot>;
  onSubmit: (values: Partial<Depot>, formikHelpers: FormikHelpers<Partial<Depot>>) => void | Promise<Depot>;
  children: React.ReactNode;
};

const DepotForm = ({ initialValues, onSubmit, children }: Props) => {
  const classes = useStyles();
  const validate = (values: Partial<Depot>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof Depot]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (!values[k]) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <PTextField name="companyName" fullWidth label="Depot Name" />
            <AutoCompleteAddress name="address" fullWidth label="Address" />
            <PSelect name="countryCode" fullWidth label="Country">
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </PSelect>
            <PTextField name="contactName" fullWidth label="Contact Name" />
            <PhoneNumberInput name="phone" fullWidth label="Contact Phone" />
            <PTextField name="email" fullWidth label="Contact Email" type="email" />
            <Box marginTop={4}>{children}</Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default DepotForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
