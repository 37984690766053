import { useCallback } from 'react';
import { ConsignmentFormValues } from '../pages/Operations/consignment/ConsignmentForm';
import { LineItem } from '../../types';
import { CheckinSheetFormValues } from '../pages/Operations/consignment/CheckinSheetFormAdd';

export interface EmailValues {
  email: string;
}

export default function useValidators() {
  const isEmailValid = useCallback((email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email), []);

  const validateEmail = useCallback(
    (values: EmailValues) => {
      const errors: Partial<EmailValues> = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!isEmailValid(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    [isEmailValid]
  );

  const validateConsignment = (values: ConsignmentFormValues) => {
  //   : Partial<
  //   {
  //     'sender.address': string;
  //     'receiver.address': string;
  //   // eslint-disable-next-line no-unused-vars
  //   } & { [key in keyof ConsignmentFormValues]: string }
  //   & {[key in keyof LineItem]:string }
  // > 
   
    const errors: any = {};
    if (!values.sender?.location) {
      errors['sender.address'] = 'Please select address from drop down';
    }
    if (!values.receiver?.location) {
      errors['receiver.address'] = 'Please select address from drop down';
    }
    (['consignmentId', 'depot', 'orderType'] as (keyof ConsignmentFormValues)[]).forEach((k) => {
      if (!values[k]) {
        errors[k] = 'Required';
      }
    });

    if (!(values.lineItems && values.lineItems.length >= 1)) {
      errors.lineItems = 'At least one row is required';
    }
    if(values.lineItems && values.lineItems.length >=1) {
      if(values.lineItems){
        values.lineItems.forEach((lineItem,idx) => {
          (['weight'] as (keyof LineItem)[]).forEach((k) => {
            if(lineItem[k] ==undefined || lineItem[k] == null) {
              errors[`lineItems.${idx}.${k}`] = 'Required';
            }
          });
        })
      }
     
    }
    return errors;
  };
  const validateCheckInSheet = (values: CheckinSheetFormValues) => {
    //   : Partial<
    //   {
    //     'sender.address': string;
    //     'receiver.address': string;
    //   // eslint-disable-next-line no-unused-vars
    //   } & { [key in keyof ConsignmentFormValues]: string }
    //   & {[key in keyof LineItem]:string }
    // > 
     
      const errors: any = {};
      if (!values.sender?.companyName) {
        errors['sender.companyName'] = 'Please enter company name ';
      }
      if (!values.consignee?.companyName) {
        errors['consignee.companyName'] = 'Please enter company name';
      }
  
  
      if (!(values.lineItems && values.lineItems.length >= 1)) {
        errors.lineItems = 'At least one row is required';
      }

      if(values.physicallyScanned) {
        if(!values.receivedBySignature) {
          errors['receivedBySignature'] = "Signature is required for physically scanned items";
        }
        if(!values.proofOfDelivery || !values.proofOfDelivery?.photos) {
          errors['proofOfDelivery'] = "Photos is required for physically scanned items";

        }
        if(!values.receivedBy) {
          errors['receivedBy'] = "Received by name is required for physically scanned items";

        }
      }
      if(values.lineItems && values.lineItems.length >=1) {
        if(values.lineItems){
          values.lineItems.forEach((lineItem,idx) => {
            (['weight'] as (keyof LineItem)[]).forEach((k) => {
              if(lineItem[k] ==undefined || lineItem[k] == null) {
                errors[`lineItems.${idx}.${k}`] = 'Required';
              }
            });
          })
        }
       
      }
      return errors;
    };
  return { isEmailValid, validateEmail, validateConsignment,validateCheckInSheet };
}
