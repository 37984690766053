import { InputAdornment, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { PTextField, ThemedTextFieldProps } from './PTextField';
import get from 'lodash/get';

const phoneCountryCodes = ['+64', '+61', '+679','+91'];
const phoneRegex = new RegExp(phoneCountryCodes.map((code) => `^\\${code}`).join('|'));

export const PhoneNumberInput = ({ name, ...otherProps }: ThemedTextFieldProps) => {
  const { values, handleChange, handleBlur: formikHandleBlur, setFieldValue, initialValues } = useFormikContext();
  const [countryCode, setCountryCode] = useState(
    phoneCountryCodes.find((c) => (get(initialValues, name, '') as string).startsWith(c)) || phoneCountryCodes[0]
  );
  const value = get(values, name, '');
  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setCountryCode(event.target.value);
    setFieldValue(name, `${event.target.value}${value.replace(phoneRegex, '')}`);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    e.target.value = `${countryCode}${e.target.value}`;
    handleChange(e);
    formikHandleBlur(e);
  };

  return (
    <PTextField
      name={name}
      value={name ? value.replace(phoneRegex, '') : undefined}
      // onChange={handleNumberChange}
      onBlur={handleBlur}
      InputProps={{
        autoComplete: 'do-not-autofill',
        startAdornment: (
          <InputAdornment position="start">
            <Select variant="standard" disableUnderline={true} value={countryCode} onChange={handleCountryChange}>
              {phoneCountryCodes.map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      {...otherProps}
    />
  );
};
