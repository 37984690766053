import { Autocomplete, Box, createFilterOptions, FilterOptionsState, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import { PTextField } from '../../pegasus/PTextField';
import GoogleApiContext, { PlaceType } from '../../context/googleApi';
import get from 'lodash/get';
import parse from 'autosuggest-highlight/parse';
import { PropsOf } from '@emotion/react';
import { PIcon } from '../../pegasus/PIcon';
import { useGetGooglePredictions } from '../../hooks/useGetGooglePredictions';
import { useGetAddressBookOptions } from '../../hooks/useGetAddressBookOptions';
import makeStyles from '@mui/styles/makeStyles/makeStyles';

const filter = createFilterOptions<PlaceType>();

export default function AddressSelect({
  name,
  label,
  addressBookFieldName,
  ...otherProps
}: Omit<PropsOf<typeof Autocomplete>, 'renderInput' | 'options' | 'onChange'> & {
  name: string;
  label: string;
  addressBookFieldName: string;
}) {
  const { placesService } = useContext(GoogleApiContext);
  const [inputValue, setInputValue] = React.useState('');
  const { values, handleChange } = useFormikContext();
  const formValue = get(values, name, '');
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [addressBookOptions, setAddressBookOptions] = React.useState<readonly PlaceType[]>([]);

  const getPredictions = useGetGooglePredictions();
  const getAddressBookOptions = useGetAddressBookOptions();

  useEffect(() => {
    const newValue = {
      description: formValue,
      structured_formatting: {
        main_text: formValue,
        secondary_text: '',
        main_text_matched_substrings: [],
      },
    };
    setValue(newValue);
    setOptions((o) => [...o, newValue]);
  }, [formValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPredictions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    getAddressBookOptions(inputValue, (results) => {
      if (active && results) {
        setAddressBookOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, getPredictions, getAddressBookOptions]);

  const handleAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue?.place_id && placesService) {
      placesService.getDetails(
        { placeId: newValue?.place_id, fields: ['geometry.location', 'formatted_address', 'address_components'] },
        (placeResult) => {
          handleChange({ target: { name: name, value: placeResult?.formatted_address } });
          const country = placeResult?.address_components?.filter((aComp) =>
            aComp.types.some((typesItem) => typesItem === 'country')
          );
          if (country && country.length > 0) {
            handleChange({
              target: { name: `${addressBookFieldName}.originCountryCode`, value: country[0].short_name },
            });
          }
          const state = placeResult?.address_components?.filter((aComp) =>
            aComp.types.some((typesItem) => typesItem === 'administrative_area_level_1')
          );
          if (state && state.length > 0) {
            handleChange({
              target: { name: `${addressBookFieldName}.state`, value: state[0].long_name },
            });
          }
          handleChange({
            target: {
              name: `${addressBookFieldName}.location`,
              value: {
                lat: placeResult?.geometry?.location?.lat(),
                lng: placeResult?.geometry?.location?.lng(),
              },
            },
          });
        }
      );
    } else if (newValue?.addressBook) {
      handleChange({ target: { name: name, value: newValue?.description } });
      handleChange({
        target: {
          name: addressBookFieldName,
          value: newValue.addressBook,
        },
      });
    }
  };

  const filterOptions = (options: unknown[], params: FilterOptionsState<unknown>) => {
    const placeTypes = options as PlaceType[];
    const filtered = filter(
      placeTypes.filter((p) => p.addressBook),
      params
    ); // filter only address books
    // push in ALL non-address book enrties as they have already been filtered by google
    // this is done as google does a better job as suggesting matches e.g. mis-spellings
    filtered.push(...placeTypes.filter((p) => p.addressBook === undefined));
    return filtered;
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option: unknown) => (typeof option === 'string' ? option : (option as PlaceType).description)}
      options={[...addressBookOptions, ...options]}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={handleAutoCompleteChange as () => void}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={({ inputProps, InputProps, ...otherParams }) => (
        <PTextField
          {...otherParams}
          inputProps={{ ...inputProps, autoComplete: 'new-password' }}
          InputProps={{ disableUnderline: true, ...InputProps }}
          name={name}
          label={label}
          fullWidth
          classes={{ root: classes.root }}
        />
      )}
      filterOptions={filterOptions}
      renderOption={(props, option) => {
        const placeType = option as PlaceType;
        const matches = placeType.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          placeType.structured_formatting.main_text,
          (matches || []).map((match) => [match.offset, match.offset + match.length])
        );

        const overrideIcon = placeType.addressBook
          ? () => <PIcon name="readBook" sx={{ color: 'text.secondary', mr: 2 }} />
          : undefined;
        return (
          <AutoCompleteOption
            key={props.id}
            listElemProps={props}
            parts={parts}
            secondaryText={(option as PlaceType).structured_formatting.secondary_text}
            Icon={overrideIcon}
          />
        );
      }}
      {...otherProps}
    />
  );
}

export type Part = {
  text: string;
  highlight: boolean;
};

export const AutoCompleteOption = ({
  listElemProps,
  parts,
  secondaryText,
  Icon,
}: {
  listElemProps: React.HTMLAttributes<HTMLLIElement>;
  parts: Part[];
  secondaryText: string;
  Icon?: React.FunctionComponent;
}) => {
  return (
    <li {...listElemProps}>
      <Grid container alignItems="center">
        <Grid item>{Icon ? <Icon /> : <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />}</Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="textMedium" color="text.secondary">
            {secondaryText}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};

const useStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      paddingLeft: '20px',
    },
  },
});
