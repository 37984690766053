import React, { useContext } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { format } from 'date-fns';
import ModalContext from '../../../context/modal';
import { useGetDriverFullName } from '../../../hooks/useGetDriverName';
import ReceiverSectionReadOnly from './ReceiverSectionReadOnly';
import { ReadOnlyField, ReadOnlyFieldProps } from './ReadOnlyField';
import SenderSectionReadOnly from './SenderSectionReadOnly';
import OrderDetailsSectionReadOnly from './OrderDetailsSectionReadOnly';
import OrderDeadRunsSection from './OrderDeadRunsSection';
import CheckInSignature from './CheckInSignature';
import ProofOfDelivery from './ProofOfDelivery';
import { Colors } from '../../../pegasus/Colors';
import useFormattedSeconds from '../../../hooks/useFormattedSeconds';
import TabLabel from '../../../components/TabLabel';
import useShowIndicator from '../../../hooks/useShowIndicator';
import ConsigneeSectionCheckinReadOnly from './ConsigneeSectionCheckinReadOnly';
import SenderSectionCheckinReadOnly from './SenderSectionCheckinReadOnly';
import OrderDetailsCheckinSectionReadOnly from './OrderDetailsCheckinSectionReadOnly';

export const TabCheckinSheet = () => {
  const { checkinsheet } = useContext(ModalContext);
  const showIndicator   = useShowIndicator();

  return checkinsheet ? (
    <>
      <Grid container columns={5} mb={5}>
        <Grid item xs>
          <ReadOnlyField
            label="CONSIGNMENT ID"
            value={
              checkinsheet.consignmentId
                ? checkinsheet.consignmentId
                : '-'
            }
          />
        </Grid>
        <Grid item xs>
          <ReadOnlyField
          label="DELIVERY MODE"
          value={
            checkinsheet.deliveryMode
              ? checkinsheet.deliveryMode
              : '-'
          }
          />
        </Grid>
        <Grid item xs>
          <ReadOnlyField
           label="DELIVERED BY"
           value={
             checkinsheet.deliveredBy
               ? checkinsheet.deliveredBy
               : '-'
           }
          />
        </Grid>
   
      </Grid>

      <Grid container columns={5}>
        <Grid item xs={3}>
            <SenderSectionCheckinReadOnly  />
            <ConsigneeSectionCheckinReadOnly  />
         
        </Grid>
        
      </Grid>
      <Divider />
      <OrderDetailsCheckinSectionReadOnly  contextColor='' />
    </>
  ) : null;
};

const ReadOnlyFieldWithMargin = (props: ReadOnlyFieldProps) => (
  <Box mb={5}>
    <ReadOnlyField {...props} />
  </Box>
);
