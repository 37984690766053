import React from 'react';
import { Marker, InfoBox } from '@react-google-maps/api';
import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../../pegasus/Colors';
import { renderToStaticMarkup } from 'react-dom/server';
import { Vehicle } from '../../../../../types';
import { InfoBoxContentsWrapper, InfoWindowWidth } from './InfoBoxContentsWrapper';

export const SvgCircleSize = 37;
const SvgCircleMarker = ({ color, active }: { color: string; active: boolean }) => {
  const activeCircleColor = active ? Colors.primary.dark : Colors.greyscale.coolGrey;
  return (
    <svg
      width={SvgCircleSize}
      height={SvgCircleSize}
      viewBox={`0 0 ${SvgCircleSize} ${SvgCircleSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M0 18C0 8.05887 8.05887 0 18 0V0C27.9411 0 36 8.05887 36 18V18C36 27.9411 27.9411 36 18 36V36C8.05887 36 0 27.9411 0 18V18Z"
        fill={color}
      />
      <circle cx="31" cy="31" r="5.5" fill={activeCircleColor} stroke="white" />
    </svg>
  );
};

const renderVehicleMarkerUrl = (color: string, active = true) => {
  const markerSvgString = encodeURIComponent(renderToStaticMarkup(<SvgCircleMarker color={color} active={active} />));
  return `data:image/svg+xml;charset=utf-8,${markerSvgString}`;
};

const styles = makeStyles(() =>
  createStyles({
    mapMarkerText: {
      fontWeight: 600,
      fontSize: 13,
    },
  })
);

export const VehicleMarker = ({
  vehicle,
  onClick,
  onCloseInfoBox,
  isActive,
}: {
  vehicle: Vehicle;
  onClick: (driverId: string) => void;
  isActive: boolean;
  onCloseInfoBox: () => void;
}) => {
  const classes = styles();
  const handleMarkerClick = () => {
    onClick(vehicle.registrationNumber);
  };

  return vehicle.lastKnownPosition ? (
    <Marker
      position={vehicle.lastKnownPosition}
      icon={{
        url: renderVehicleMarkerUrl(Colors.primary.main, false),
      }}
      label={{ text: 'UV', color: 'white', className: classes.mapMarkerText }}
      onClick={handleMarkerClick}
    >
      {isActive ? (
        <InfoBox
          onCloseClick={onCloseInfoBox}
          options={{
            alignBottom: true,
            maxWidth: InfoWindowWidth,
            pixelOffset: new google.maps.Size(-0.5 * InfoWindowWidth, -1 * SvgCircleSize),
          }}
        >
          <InfoBoxContentsWrapper>
            <Typography variant="textXSmall" color={Colors.greyscale.offBlack} display="block">
              Unassigned Vehicle
            </Typography>
            <Typography variant="textXSmall" color={Colors.greyscale.offBlack} display="block">
              {vehicle.registrationNumber}
            </Typography>
            <Typography variant="textXSmall" color={Colors.greyscale.offBlack} display="block">
              {vehicle.description}
            </Typography>
          </InfoBoxContentsWrapper>
        </InfoBox>
      ) : null}
    </Marker>
  ) : null;
};
