import { Button, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AddressBook, CheckinSheetForm, Consignment, DrawerType, User, View } from '../../types';
import AppModals from '../components/Modals/AppModals';
import BlockingDialog from '../components/Modals/BlockingDialog';
import SimpleDialog from '../components/Modals/SimpleDialog';
import { Country } from '../constants/countries';
import { Colors } from '../pegasus/Colors';

const ModalContext = React.createContext<{
  // drawerOpen: DrawerType;
  // setDrawerOpen: React.Dispatch<React.SetStateAction<DrawerType>>;
  openDrawers: DrawerType[];
  isDrawerOpen: (drawerType: DrawerType) => boolean;
  openDrawer: (drawerType: DrawerType) => void;
  closeDrawer: (drawerType: DrawerType) => void;
  setSimpleDialogOptions: React.Dispatch<React.SetStateAction<DialogOptions>>;
  country?: Country;
  viewFilter?:View[];
  setCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  setViewFilter: React.Dispatch<React.SetStateAction<View[] | undefined>>;

  consignment?: Consignment;
  checkinsheet?:CheckinSheetForm;
  view?:View;
  setConsignment: React.Dispatch<React.SetStateAction<Consignment | undefined>>;
  setCheckinSheet: React.Dispatch<React.SetStateAction<CheckinSheetForm | undefined>>;
  setView: React.Dispatch<React.SetStateAction<View | undefined>>;
  addressBook?: AddressBook;
  setAddressBook: React.Dispatch<React.SetStateAction<AddressBook | undefined>>;
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}>({
  // drawerOpen: undefined,
  // setDrawerOpen: () => undefined,
  openDrawers: [],
  isDrawerOpen: () => false,
  openDrawer: () => undefined,
  closeDrawer: () => undefined,
  country: undefined,
  setCountry: () => undefined,
  consignment: undefined,
  checkinsheet:undefined,
  setConsignment: () => undefined,
  setCheckinSheet: () => undefined,
  setView: () => undefined,
  setViewFilter: () => undefined,

  setSimpleDialogOptions: () => undefined,
  addressBook: undefined,
  setAddressBook: () => undefined,
  user: undefined,
  view: undefined,
  viewFilter:undefined,
  setUser: () => undefined,
});
export default ModalContext;

type DialogOptions = { title: string; contentText: string; okButtonText?: string; open: boolean };

export const ModalServiceProvider = ({ children }: { children: React.ReactNode }) => {
  // const [drawerOpen, setDrawerOpen] = useState<DrawerType>(undefined);
  const [openDrawers, setOpenDrawers] = useState<DrawerType[]>([]);
  const [country, setCountry] = useState<Country>();
  const [consignment, setConsignment] = useState<Consignment>();
  const [checkinsheet, setCheckinSheet] = useState<CheckinSheetForm>();
  const [view, setView] = useState<View>();
  const [viewFilter, setViewFilter] = useState<View[]>();

  const defaultDialogOptions = useRef<DialogOptions>({
    title: '',
    contentText: '',
    okButtonText: 'OK',
    open: false,
  }).current;
  const [simpleDialogOptions, setSimpleDialogOptions] = useState<DialogOptions>(defaultDialogOptions);
  const [addressBook, setAddressBook] = useState<AddressBook>();
  const [user, setUser] = useState<User>();

  const handleSimpleDialogClose = () => {
    setSimpleDialogOptions(defaultDialogOptions);
  };

  const isDrawerOpen = useCallback(
    (drawerType: DrawerType) => {
      return openDrawers.includes(drawerType);
    },
    [openDrawers]
  );

  const openDrawer = useCallback(
    (drawerType: DrawerType) => {
      setOpenDrawers((_openDrawers) => {
        if (!_openDrawers.includes(drawerType)) {
          _openDrawers.push(drawerType);
        }
        return [...openDrawers];
      });
    },
    [openDrawers]
  );

  const closeDrawer = useCallback(
    (drawerType: DrawerType) => {
      setOpenDrawers((_openDrawers) => {
        const curIndex = _openDrawers.findIndex((d) => d === drawerType);
        if (curIndex !== -1) {
          _openDrawers.splice(curIndex, 1);
        }
        return [...openDrawers];
      });
    },
    [openDrawers]
  );

  // useEffect(() => {
  //   if (drawerOpen === undefined) {
  //     setConsignment(undefined);
  //     setAddressBook(undefined);
  //   }
  // }, [drawerOpen]);
  return (
    <>
      <ModalContext.Provider
        value={{
          openDrawers,
          isDrawerOpen,
          openDrawer,
          closeDrawer,
          setSimpleDialogOptions,
          country,
          setCountry,
          consignment,
          setConsignment,
          addressBook,
          setAddressBook,
          user,
          setUser,
          checkinsheet,
          setCheckinSheet,
          view,
          setView,
          viewFilter,
          setViewFilter
        }}
      >
        <ConfirmationServiceProvider>
          {children}
          <AppModals />
        </ConfirmationServiceProvider>
        <SimpleDialog
          title={simpleDialogOptions.title}
          contentText={simpleDialogOptions.contentText}
          okButtonText={simpleDialogOptions.okButtonText}
          open={simpleDialogOptions.open}
          onClose={handleSimpleDialogClose}
        />
      </ModalContext.Provider>
    </>
  );
};

export const ConfirmationServiceContext = React.createContext<{
  showBockingModal: (id: string) => Promise<void>;
  showConfirmationModal: (confirmationOptions: ConfirmationOptions) => Promise<{ confirmed: boolean } | void>;
  openId: string | undefined;
  setOpenId: (id: string | undefined) => void;
}>({
  showConfirmationModal: Promise.reject,
  showBockingModal: Promise.reject,
  openId: undefined,
  setOpenId: () => undefined,
});

type ConfirmationOptions = {
  title: string;
  contentText: string;
  hideOk?: boolean;
};
const ConfirmationServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [openId, setOpenId] = useState<string | undefined>(undefined);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions>({
    title: '',
    contentText: '',
  });
  const awaitingPromiseRef = React.useRef<{
    resolve: (payload?: any) => void; // eslint-disable-line
    reject: () => void;
  }>();

  useEffect(() => {
    if (openId === undefined && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
  }, [openId]);

  const showBockingModal = (id: string) => {
    setOpenId(id);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const showConfirmationModal = (confirmationOptions: ConfirmationOptions) => {
    setConfirmationOptions(confirmationOptions);
    setOpenId('confirmation-modal');
    return new Promise<{ confirmed: boolean } | void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ confirmed: true });
    }
  };

  return (
    <ConfirmationServiceContext.Provider value={{ showBockingModal, openId, setOpenId, showConfirmationModal }}>
      {children}
      <ConfirmationModal
        confirmationOptions={confirmationOptions}
        onConfirm={handleConfirm}
        onCancel={() => setOpenId(undefined)}
      />
    </ConfirmationServiceContext.Provider>
  );
};

const confirmationStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorButton: {
      color: theme.palette.error.main,
    },
    title: {
      color: Colors.greyscale.offBlack,
      margin: '20px 0px',
      fontSize: '30px',
      fontWeight: '700',
    },
    actions: {
      justifyContent: 'flex-start',
      paddingLeft: '24px',
      paddingRight: '24px',
      marginBottom: '30px',
    },
  })
);

const ConfirmationModal = ({
  confirmationOptions,
  onConfirm,
  onCancel,
}: {
  confirmationOptions: ConfirmationOptions;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const classes = confirmationStyles();
  return (
    <BlockingDialog id="confirmation-modal">
      <DialogTitle className={classes.title}>{confirmationOptions.title}</DialogTitle>
      <DialogContent>
        <Typography align="center" variant="textMedium" color="textSecondary" sx={{ whiteSpace: 'pre-line' }}>
          {confirmationOptions.contentText}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {confirmationOptions.hideOk ? null : (
          <Button variant="contained" color="primary" autoFocus onClick={onConfirm}>
            Continue
          </Button>
        )}
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </BlockingDialog>
  );
};
