import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CheckinSheetForm, TableFieldCheckin } from '../../../../types';
import TableHeader from './TableHeader';
import {
  Box,
  Button,
  FormControl,

} from '@mui/material';

import TableFooter from './TableFooter';
import { Formik } from 'formik';
import { Search } from '../../../components/form/Search';
import CheckinSheetContext from '../../../context/checkins';
import CheckinTableRow from './CheckinTableRow';
import { ViewButton, ViewCheckInButton } from './components';
import { PDatepicker } from '../../../pegasus/PDatepicker';
import { PIcon } from '../../../pegasus/PIcon';
import useCheckinApiRoutes from '../../../hooks/api/useCheckinApiRoutes';
import SnackBarContext from '../../../context/snackbar';

export const TableFields: TableFieldCheckin[] = [

  { field: 'consignmentId', label: 'CONSIGNMENT #', columns: 3 },

  { field: 'sender.companyName', label: 'SHIPPER', columns: 3 },
  { field: 'consignee.companyName', label: 'CONSIGNEE', columns: 3 },
  { field: 'deliveredBy', label: 'CARRIER', columns: 3 },
  { field: 'defaultDeliveryDateTime', label: 'DELIVERY DATE', columns: 3, formatDate: true },
  { field: 'tags', label: 'TAGS', columns: 3, commaString: true },
  { field: 'console', label: 'CONSOL', columns: 3, commaString: true },

  {
    field: 'actions',
    label: '',
    columns: 1,
    // eslint-disable-next-line react/display-name
    renderField: (checkinsheet: CheckinSheetForm) => <ViewCheckInButton checkinsheet={checkinsheet} />,
  },
];

const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);
const CheckinTable = ({ status, filters }: any) => {
  const { checkins,setCheckinsheets, setTotalCheckinsheets, checkinQueryParams} = useContext(CheckinSheetContext);
  const [selectedCheckins, setSelectedCheckins] = useState<string[]>([]);
  const { showSnack } = useContext(SnackBarContext);

  const prevDataRef = useRef();


  const { reportCsvArchive,listCheckinSheet, statusUpdateCheckin } = useCheckinApiRoutes();
  console.log(filters);
  const [checkinFilter, setCheckinFilter] = useState<{
    searchText: string;
    dataFrom: number;
    dataTo: number;
  }>({
    searchText: '',
    dataFrom: new Date(new Date().setDate(new Date().getDate() - 30)).getTime(),
    dataTo: new Date().getTime()
  });
  const [filterArchived, setFilterArchived] = useState<{
    searchText: string;
    dataFrom: number;
    dataTo: number;
  }>({
    searchText: '',
    dataFrom: new Date(new Date().setDate(new Date().getDate() - 30)).getTime(),
    dataTo: new Date().getTime()
  });

  
  const handleCheckinFilterChange = async (values: {
    searchText: string;
    dataFrom: number ;
    dataTo: number ;
  }) => {
    checkinQueryParams.searchText = values.searchText;
    checkinQueryParams.dataFrom = values.dataFrom;
    checkinQueryParams.dataTo = values.dataTo;

    const { checkinsheets, total } = await listCheckinSheet(checkinQueryParams);
    setCheckinsheets(checkinsheets);
    setTotalCheckinsheets(total);
    setCheckinFilter(values);
  };


  const handleReportCsvArchive = () => {
    reportCsvArchive({ ...filterArchived }).then((response) => {
      const csvFileBlob = new Blob([response], { type: 'text/csv' });
      const blobUrl = URL.createObjectURL(csvFileBlob);
      window.open(blobUrl, '_top');
    });
  };
  const moveSelectionsToArchived = () => {
    const promises:Array<any> = [];
    selectedCheckins.forEach((id:string) => {
      promises.push(statusUpdateCheckin(id,'complete'));
    });
    Promise.all(promises).then((data) =>{
      showSnack("The checkinsheets have been moved to archived", "success");
    })
  };
  const handleFiltersChange = () => {
    console.log('Filters changed, re-filtering checkins:', filters);
    // Additional logic can be implemented here
  };

  // useEffect to detect changes in filters

  const filteredCheckins = checkins.filter((checkin: any) => {
    // Perform text search if searchText is provided
    const searchTextMatch = checkinFilter.searchText ? (
      checkin.consignmentId?.toLowerCase().includes(checkinFilter.searchText.toLowerCase()) ||
      checkin.sender.companyName.toLowerCase().includes(checkinFilter.searchText.toLowerCase()) ||
      checkin.consignee.companyName.toLowerCase().includes(checkinFilter.searchText.toLowerCase())
    ) : true;

    // Check date range
    const dateMatch = checkin.defaultDeliveryDateTime >= checkinFilter.dataFrom && checkin.defaultDeliveryDateTime <= checkinFilter.dataTo;
    let additionalFieldsMatch = true;
    // Check filters for additional field matches
    if (filters && filters.length) {
      additionalFieldsMatch = filters.every((filter: any) => {
        // Access the field value from the checkin dynamically
        const value = filter.field.split('.').reduce((acc: any, part: any) => acc && acc[part], checkin);
        // Check if the field value is one of the specified filter values
        // Assuming `value` could be an array or a single value
        return filter.values.length > 0 ? (
          Array.isArray(value) ?
            filter.values.some((filterValue: any) => value.includes(filterValue)) : // Check if any filter value is in the value array
            filter.values.includes(value) // Check if the single value is in filter values
        ) : true;
      });
    }
    return searchTextMatch && dateMatch && additionalFieldsMatch;
  });

  useEffect(() => {
   
    //prevDataRef.current = filters;  // Update the ref to the current value after actions
  }, [filters, filteredCheckins]);
  return (
    <>
      {/* <Button
              sx={{ marginTop: 0.5, marginBottom: 1, float:"right" }}
              variant="contained"
              size="medium"
              onClick={showAddNewModal}
            >
              <PIcon sx={{ marginRight: 1 }} name="plus" />
              Add New
      </Button> */}
      <Formik
        validationDisabled={true}
        initialValues={checkinFilter}
        onSubmit={handleCheckinFilterChange}
        enableReinitialize
      >
        {({ handleSubmit, }) => {
          const handleSearchChange = (
           
          ) => {
            handleSubmit();
          };
          return (
            <>
              <Search
                name="searchText"
                placeholder="Search by Consignment ID #, Shipper, Consignee"
                onChange={handleSearchChange}
                sx={{ width: '600px', marginTop: 0.5, marginBottom: 1, marginRight: 1, border: 'solid lightgray 1px', borderRadius: '10px', boxShadow: '0px 0px 5px 0px lightgray' }}
              />
              <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                <PDatepicker
                  name="dataFrom"
                  onChange={handleSearchChange}

                  fullWidth
                  label="Date From"
                  inputFormat="dd/MM/yy"
                  mask="__/__/__"
                />
              </FormControl>
              <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>

                <PDatepicker
                  name="dataTo"
                  onChange={handleSearchChange}

                  fullWidth
                  label="Date To"
                  inputFormat="dd/MM/yy"
                  mask="__/__/__"
                />
              </FormControl>
              {
                status == 'unassigned' && (
                  <>
                  <FormControl sx={{  marginRight: 1, marginTop: 0.5, marginBottom: 0.5, display:"flex", flexDirection:"row" }}>
                    <Button
                      sx={{ height: '60px', width:'200px', marginTop: 0.5, marginBottom: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleReportCsvArchive}
                    >
                      <PIcon sx={{ marginRight: 1 }} name="file" />
                      Export to CSV
                    </Button>
                    <Button
                     sx={{ height: '60px', width:'200px', marginTop: 0.5, marginLeft:1, marginBottom: 0.5 }}
                     size="small"
                     variant="contained"
                     onClick={moveSelectionsToArchived}
                   >
                     <PIcon sx={{ marginRight: 1 }} name="file" />
                     Move to Archived
                   </Button>
                  </FormControl>
                 
                   </>
                )
              }

            </>
          );
        }}
      </Formik>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      {checkins.length > 0 ? filteredCheckins.map((checkinsheet) => {
        return (
          <CheckinTableRow
            key={checkinsheet._id}
            checkinsheet={checkinsheet}
            tableFields={TableFields}
            columnCount={totalColumns}
            onChange={(selectedIds: Array<string>) => {
              setSelectedCheckins((prevSelectedCheckins: Array<string>) => {
                // Create a new set to avoid duplicates, then convert it back to an array
                const updatedSelectedCheckins = new Set([...prevSelectedCheckins, ...selectedIds]);
                return Array.from(updatedSelectedCheckins);
              });
            }}       />
        );
      })
        : null}
      <TableFooter isConsignment={false} params={checkinFilter}/>
    </>
  );
};

export default CheckinTable;
