import React from 'react';
import { useContext } from 'react';
import { Button } from '@mui/material';
import ModalContext, { ConfirmationServiceContext } from '../../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import AddressBookForm from './AddressBookForm';
import { AddressBook } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import { Colors } from '../../../pegasus/Colors';
import useAddressBookApiRoutes from '../../../hooks/api/useAddressBookApiRoutes';

const EditAddressBookModal = () => {
  const { closeDrawer, addressBook } = useContext(ModalContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { update, remove } = useAddressBookApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const handleSubmit = (values: Partial<AddressBook>, { setSubmitting }: FormikHelpers<Partial<AddressBook>>) => {
    update(values as AddressBook)
      .then(() => {
        setSubmitting(false);
        showSnack('AddressBook Updated!', 'success');
        closeDrawer('edit-address-book');
      })
      .catch(() => setSubmitting(false));
  };

  const handleDelete = async () => {
    if (addressBook?._id) {
      const res = await showConfirmationModal({
        title: 'Delete Company',
        contentText: 'Do you wish to remove this company from the Address Book?',
      });
      if (res && res.confirmed === true) {
        await remove(addressBook?._id);
        showSnack('AddressBook Deleted!', 'success');
        closeDrawer('edit-address-book');
        setOpenId(undefined);
      }
    }
  };

  return addressBook ? (
    <AddressBookForm onSubmit={handleSubmit} initialValues={addressBook}>
      <SubmitButton
        sx={{
          marginRight: 1,
          backgroundColor: Colors.greyscale.offBlack,
          paddingLeft: '50px',
          paddingRight: '50px',
        }}
      >
        Save
      </SubmitButton>
      <Button
        onClick={handleDelete}
        variant="outlined"
        sx={{
          borderColor: Colors.greyscale.offBlack,
          paddingLeft: '44.5px',
          paddingRight: '44.5px',
          color: Colors.greyscale.offBlack,
        }}
      >
        Delete
      </Button>
    </AddressBookForm>
  ) : null;
};

export default EditAddressBookModal;
