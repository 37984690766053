import { Box, Button, Theme, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import LoginImage from '../imgs/LoginImage.png';
import { createStyles, makeStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../pegasus/PTextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAuthApiRoutes from '../hooks/api/useAuthApiRoutes';
import { useParams, useNavigate } from 'react-router-dom';
import UserContext from '../context/user';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    cols: {
      flex: 1,
      height: '100%',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    },
    loginContainer: {
      marginTop: theme.spacing(25),
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(16),
    },
    form: {
      maxWidth: '430px',
    },
  })
);

interface MyFormValues {
  password: string;
  password2: string;
}

const PasswordReset = () => {
  const classes = styles();
  const { resetPassword, signOut } = useAuthApiRoutes();
  const { saveUser, isLoggedIn } = useContext(UserContext);
  const { token = '' } = useParams<'token'>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const navigate = useNavigate();
  const initialValues: MyFormValues = {
    password: '',
    password2: '',
  };

  useEffect(() => {
    // signout on page load
    signOut().then(() => {
      saveUser(undefined);
      setPageLoaded(true);
    });
  }, [saveUser, signOut]);

  useEffect(() => {
    if (isLoggedIn && pageLoaded) {
      navigate('/');
    }
  }, [isLoggedIn, navigate, pageLoaded]);
  const handleReset = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    resetPassword(values.password, values.password2, token)
      .then((_user) => {
        saveUser(_user);
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.password2) {
      errors.password2 = 'Required';
    }
    if (values.password && values.password2 && values.password !== values.password2) {
      errors.password2 = 'Passwords do not match';
    }
    return errors;
  };

  const goToLogin = () => {
    navigate('/');
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.cols}>
        <img className={classes.image} src={LoginImage} alt="Login" />
      </Box>
      <Box className={classes.cols}>
        <Box className={classes.loginContainer}>
          <Typography variant="displayLargeBold" marginBottom={3.5}>
            Reset Password
          </Typography>
          <Formik initialValues={initialValues} validate={validate} onSubmit={handleReset}>
            {({ handleSubmit, isSubmitting, isValid }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <PTextField label="Password" type="password" name="password" fullWidth />
                  <PTextField label="Confirm Password" type="password" name="password2" fullWidth />

                  <Button
                    sx={{ mb: 2.5 }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Reset
                    <ArrowForwardIcon sx={{ marginLeft: 1 }} />
                  </Button>
                  <Button sx={{ mb: 2.5 }} fullWidth variant="outlined" type="button" onClick={goToLogin}>
                    <ArrowBackIcon sx={{ marginRight: 1 }} />
                    Back To Login
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordReset;
