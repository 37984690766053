import { Box, Button, Tab, Tabs, Theme, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Navbar from '../../components/NavBar';
import { Link, useParams } from 'react-router-dom';
import { PIcon } from '../../pegasus/PIcon';
import { Colors } from '../../pegasus/Colors';
import { OrderBadge } from './components/OrderBadge';
import UnAssignedTable from './tables/UnAssignedTable';
import AssignedTab from './components/AssignedTab';
import { DriverMap } from './components/DriverMap';
import ConsignmentContext from '../../context/consignments';
import IncompleteFailedTable from './tables/IncompleteFailedTable';
import CompletedTable from './tables/CompletedTable';
import TabLabel from '../../components/TabLabel';
import useShowIndicator from '../../hooks/useShowIndicator';
import SocketContext from '../../context/socket';
import DepotContext from '../../context/depot';
import OperationsViewButton from '../../components/OperationsViewButton';
import DispatchViewButton from '../../components/DispatchViewButton';
import useGetUserRoutePermissions from '../../hooks/useGetUserRoutePermissions';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    tabContent: {
      flex: 1,
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      flexWrap: 'wrap',
      minWidth: 200,
      paddingLeft: theme.spacing(2),
      borderLeft: `2px solid ${Colors.greyscale.coolGrey}`,
    },
  })
);

const DispatchDashboard = () => {
  const classes = styles();
  const { countryCode = '' } = useParams<'countryCode'>();
  const { depot } = useContext(DepotContext);
  const { consignments } = useContext(ConsignmentContext);
  const { setUpdatedStatusCodes } = useContext(SocketContext);
  const { canSeeOperationsDashboard } = useGetUserRoutePermissions();

  const statsCounts = consignments.reduce<{ status: string; count: number }[]>(
    (accum, curConsigment) => {
      const curStatus = accum.find((a) => a.status === curConsigment.status);
      const total = accum.find((a) => a.status === 'Total Consignments');
      if (total) {
        total.count += 1;
      }
      if (curStatus) {
        curStatus.count += 1;
      } else {
        accum.unshift({ status: curConsigment.status, count: 1 });
      }
      return accum;
    },
    [{ status: 'Total Consignments', count: 0 }]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'assigned',
        name: 'Assigned',
        icon: <PIcon sx={{ marginRight: 1 }} name="truck" />,
        component: <AssignedTab />,
        indicatorStatuses: ['assigned', 'inTransit', 'assignedLeg2', 'inTransitLeg2'],
      },
      {
        key: 'unassigned',
        name: 'Unassigned',
        icon: <PIcon sx={{ marginRight: 1 }} name="time" />,
        component: <UnAssignedTable />,
        indicatorStatuses: ['unassigned', 'onHold', 'atDepot'],
      },
      {
        key: 'incompleteFailed',
        name: 'Incomplete/Failed',
        icon: <PIcon sx={{ marginRight: 1 }} name="flag" />,
        component: <IncompleteFailedTable />,
        indicatorStatuses: ['failed', 'inComplete'],
      },
      {
        key: 'completed',
        name: 'Completed',
        icon: <PIcon sx={{ marginRight: 1 }} name="bag" />,
        component: <CompletedTable />,
        indicatorStatuses: ['complete'],
      },
    ],
    []
  );

  const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);
  const currentTab = tabs.find((tab) => tab.key === currentTabKey) || tabs[0];

  useEffect(() => {
    setUpdatedStatusCodes((curCodes) => {
      return curCodes.filter((c) => !currentTab.indicatorStatuses.includes(c));
    });
  }, [currentTab.indicatorStatuses, setUpdatedStatusCodes]);

  const handleChange = (event: React.SyntheticEvent, newKey: string) => {
    setCurrentTabKey(newKey);
  };

  const showIndicator = useShowIndicator();

  return (
    <Box className={classes.container}>
      <Navbar>
        <Box display="flex" alignItems="center">
          <Box flex={1} display="flex" alignItems="center">
            <Typography variant="linkLarge" color={Colors.greyscale.offBlack}>
              {depot?.companyName}
            </Typography>
            <Box flex={1} mt={1} ml={2} flexWrap="wrap" display="flex">
              {statsCounts.map((stat) => (
                <OrderBadge key={stat.status} label={stat.status.toUpperCase()} count={stat.count} />
              ))}
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <DispatchViewButton buttonText="Change Depot" />
            {canSeeOperationsDashboard ? <OperationsViewButton /> : null}
            <Button
              variant="contained"
              size="medium"
              to={`/dispatch/${countryCode}/${depot?._id}/drivers`}
              component={Link}
            >
              Our Drivers
            </Button>
          </Box>
        </Box>
      </Navbar>
      <Box display="flex" flex={1} overflow="auto">
        <Box flex={1} display="flex" flexDirection="column">
          <Tabs value={currentTabKey} onChange={handleChange} variant="fullWidth">
            {tabs.map((tab) => (
              <Tab
                key={tab.key}
                value={tab.key}
                label={<TabLabel label={tab.name} showIndicator={showIndicator(tab.indicatorStatuses)} />}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
          <Box className={classes.tabContent}>{currentTab.component}</Box>
        </Box>

        <DriverMap />
      </Box>
    </Box>
  );
};

export default DispatchDashboard;
