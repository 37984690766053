import { useCallback } from 'react';
import { AddressBook } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useAddressBookApiRoutes() {
  const publicRoute = usePublicRoute();

  const create = useCallback(
    async (addressBook: Partial<AddressBook>): Promise<AddressBook> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/address-books`,
        data: addressBook,
      });
      return response;
    },
    [publicRoute]
  );

  const list = useCallback(
    async (params: {
      userId?: string;
      countryCode?: string;
      page?: number;
      itemsPerPage?: number;
      unlimit?: boolean;
      searchText?: string;
    }): Promise<{ addressBooks: AddressBook[]; total: number }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/address-books`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const update = useCallback(
    async (addressBook: AddressBook): Promise<AddressBook> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/address-books/${addressBook._id}`,
        data: addressBook,
      });
      return response;
    },
    [publicRoute]
  );

  const remove = useCallback(
    async (addressBookId: string): Promise<AddressBook> => {
      const response = await publicRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/address-books/${addressBookId}`,
      });
      return response;
    },
    [publicRoute]
  );

  const exportCsv = useCallback(
    async (params: { countryCode: string }): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/address-books/export-csv`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  return { create, list, update, remove, exportCsv };
}
