import { Box, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { View } from '../../../../types';

import ViewContext from '../../../context/view';

type Props = {
  initialValues: Partial<View>;
  onSubmit: (values: Partial<View>, formikHelpers: FormikHelpers<Partial<View>>) => void | Promise<View>;
  children: React.ReactNode;
};

const ViewForm = ({ initialValues, onSubmit, children }: Props) => {
  const  view  = initialValues;
  const classes = useStyles();

  const validate = (values: Partial<View>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof View]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (
        !values[k] &&
        values[k] !== 0 &&
        values[k] !== false 

      ) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

 
  return (
    <Box overflow="auto" padding={6.5} paddingBottom={4}>
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => {
        

        return (
          <>
            <form onSubmit={handleSubmit} className={classes.form}>
              <PTextField name="name" fullWidth label="View Name" />
              <Box marginTop={4}>{children}</Box>
            </form>
           
          </>
        );
      }}
    </Formik>
    </Box>
  );
};

export default ViewForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
