import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import { View } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useViewApiRoutes from '../../../hooks/api/useViewApiRoutes';
import ViewForm from './ViewForm';
import CheckinSheetContext from '../../../context/checkins';

const NewViewModal = ({ countryCode }: { countryCode: string }) => {
  const { closeDrawer, viewFilter } = useContext(ModalContext);
  const { createView } = useViewApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const handleSubmit = (values: Partial<View>, { setSubmitting }: FormikHelpers<Partial<View>>) => {
    if(localStorage.getItem('view')){
        values.filters = JSON.parse(localStorage.getItem('view')??"");
    }
    createView(values)
      .then((view) => {
        setSubmitting(false);
        showSnack('View Created!', 'success');
        closeDrawer('new-view');
        
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <ViewForm
      onSubmit={handleSubmit}
      initialValues={{
        countryCode: countryCode
      }}
    >
      <SubmitButton color="primary" sx={{ marginRight: 1 }}>
        Save View
      </SubmitButton>
    </ViewForm>
  );
};

export default NewViewModal;
