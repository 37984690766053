import React, { useContext, useState } from 'react';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ModalContext from '../../../context/modal';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { Colors } from '../../../pegasus/Colors';
import ViewPdfModal from './ViewPdfModal';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1.5),
    },
    greyButton: {
      backgroundColor: Colors.greyscale.offBlack,
      opacity: 0.5,
    },
  })
);

const ViewCheckInSheet = () => {
  const [open, setOpen] = useState(false);
  const { consignment } = useContext(ModalContext);
  const { getPdfCheckInSheet } = useConsignmentApiRoutes();
  const classes = useStyles();
  const showModal = () => {
    setOpen(true);
  };

  return consignment ? (
    <ViewPdfModal
      open={open}
      setOpen={setOpen}
      getPdfDocument={getPdfCheckInSheet}
      printButtonText="Print Check In Sheet"
    >
      <Button
        disabled={!(consignment?.checkInSheet?.recievedBy && consignment?.checkInSheet?.screenedBy)}
        variant="contained"
        className={`${classes.button} ${classes.greyButton}`}
        onClick={showModal}
      >
        View Check-In Sheet
      </Button>
    </ViewPdfModal>
  ) : null;
};

export default ViewCheckInSheet;
