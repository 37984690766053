import { Box, FormControlLabel, Grid, MenuItem, Paper, Switch, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { View } from '../../../../types';
import InfoIcon from '@mui/icons-material/Info';

import ViewContext from '../../../context/view';
import { theme } from '../../../context/theme';

type Props = {
  initialValues: Partial<View>;
  onSubmit: (values: Partial<View>, formikHelpers: FormikHelpers<Partial<View>>) => void | Promise<View>;
  children: React.ReactNode;
};

const ShareViewForm = ({ initialValues, onSubmit, children }: Props) => {
  const { view } = useContext(ViewContext);
  const classes = useStyles();

  const validate = (values: Partial<View>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof View]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (
        !values[k] &&
        values[k] !== 0 &&
        values[k] !== false

      ) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };


  return (
    <Box overflow="auto" padding={6.5} paddingBottom={4} paddingRight={3}>
      <Paper sx={{ mb: 2, overflow: 'hidden' }}>
        <Box sx={{ p: 3, borderLeft: `4px solid ${theme.palette.info.main}` }}>
          <Grid container spacing={3}>
            <Grid item>
              <InfoIcon color="info" />
            </Grid>
            <Grid item xs>
              <Typography>
                If shared, recipients can view the containers
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, values, setFieldValue }) => {


          return (
            <>

              <form onSubmit={handleSubmit} className={classes.form}>
                <strong>Share publicly</strong> <br />
                <FormControlLabel control={<Switch defaultChecked />} name='isPubliclyAccessible' label="Public Access" />
                <Box marginTop={4}>{children}</Box>
            
              </form>

            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ShareViewForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
