import React, { useContext } from 'react';
import { Consignment } from '../../../../types';
import { IconButton, IconButtonProps } from '@mui/material';
import { PIcon } from '../../../pegasus/PIcon';
import ModalContext from '../../../context/modal';
import useGetContextColor from '../../../hooks/useGetContextColor';

export const AssignDriverButton = ({ consignment, ...otherProps }: { consignment: Consignment } & IconButtonProps) => {
  const { openDrawer, setConsignment } = useContext(ModalContext);
  const contextColor = useGetContextColor(consignment);
  const handleClick = () => {
    setConsignment(consignment);
    openDrawer('assign-driver');
  };
  return (
    <IconButton
      disabled={consignment.status === 'onHold'}
      onClick={handleClick}
      sx={{ color: contextColor }}
      {...otherProps}
    >
      <PIcon name="plusUserCircle" />
    </IconButton>
  );
};
