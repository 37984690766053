import { Box, IconButton, Tab, Tabs, Theme, Typography, Avatar } from '@mui/material';
import React, { useContext } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../components/NavBar';
import { PIcon } from '../../pegasus/PIcon';
import ModalContext from '../../context/modal';
import countries from '../../constants/countries';
import { Colors } from '../../pegasus/Colors';
import UserTable from './user/UserTable';
import OperationsViewButton from '../../components/OperationsViewButton';
import DispatchViewButton from '../../components/DispatchViewButton';
import UserContext from '../../context/user';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
    },
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginRight: theme.spacing(2),
    },
    tab: {
      width: theme.spacing(36),
    },
    tabbleContent: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  })
);

const Accounts = () => {
  const classes = styles();
  const { openDrawer, setCountry } = useContext(ModalContext);
  const { user } = useContext(UserContext);

  const { countryCode = '' } = useParams<'countryCode'>();
  const showAddUser = () => {
    setCountry(countries.find((c) => c.code === countryCode));
    openDrawer('new-user');
  };

  const restrictedCountries =
    user?.type === 'admin' ? countries : countries.filter((c) => c.code === user?.countryCode);

  return (
    <>
      <Box className={classes.container}>
        <Navbar>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="linkLarge">Team Accounts</Typography>
            <Box display="flex">
              <DispatchViewButton />
              <OperationsViewButton />
            </Box>
          </Box>
        </Navbar>
        <Box display="flex" alignItems="center" justifyContent="space-between" px={1}>
          <Tabs value={countryCode}>
            {restrictedCountries.map((country) => (
              <Tab
                value={country.code}
                key={country.code}
                label={country.name}
                to={`/admin/accounts/${country.code}`}
                component={Link}
                icon={<Avatar src={country.image} alt={country.name} sx={{ width: 24, height: 24 }} />}
                iconPosition="start"
                className={classes.tab}
              />
            ))}
          </Tabs>
          <IconButton onClick={showAddUser} className={classes.iconButton}>
            <PIcon name="plus" />
          </IconButton>
        </Box>
        <Box className={classes.tabbleContent}>
          <UserTable countryCode={countryCode} />
        </Box>
      </Box>
    </>
  );
};

export default Accounts;
