import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { AddressBookTableField, TableField, UserTableField, Consignment, User, AddressBook } from '../../../types';
import get from 'lodash/get';
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 6,
      overflow: 'hidden',
      marginBottom: 10,
      alignItems: 'center',
    },
    firstColumn: {
      borderLeftWidth: 6,
    },
    cell: {
      paddingTop: 18,
      paddingBottom: 20,
      paddingLeft: 18,
      paddingRight: 16,
      wordBreak: 'break-word',
    },
  })
);

const TableRow = ({
  rowData,
  tableFields,
  columnCount,
}: {
  rowData: Consignment | User | AddressBook;
  tableFields: TableField[] | UserTableField[] | AddressBookTableField[];
  columnCount: number;
}) => {
  const classes = styles();
  return (
    <Grid container columns={columnCount} className={classes.container}>
      {tableFields.map((tableField, index) => {
        const fieldData = get(rowData, tableField.field, '') ? get(rowData, tableField.field, '') : tableField.default;
        const rowClasses = [classes.cell];
        if (index === 0) {
          rowClasses.push(classes.firstColumn);
        }
        return (
          <Grid
            key={index}
            item
            xs={tableField.columns}
            className={rowClasses.join(' ')}
            textAlign={tableField.position ? tableField.position : 'left'}
          >
            {tableField.renderField ? (
              tableField.renderField(rowData)
            ) : (
              <Typography variant="linkXSmall">{fieldData}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TableRow;
