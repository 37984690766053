import { Box, Divider, Grid, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { PTextFieldMemo, useGetFormikTextFields } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { CheckinSheetForm, Depot } from '../../../../types';
import SenderSection from './SenderSection';

import omit from 'lodash/omit';
import useDepotApiRoutes from '../../../hooks/api/useDepotApiRoutes';
import { get } from 'lodash';
import useValidators from '../../../hooks/useValidators';
import consignmentTypes from '../../../constants/consignmentTypes';
import CheckinSheetOrderDetailsSection from './CheckinSheetOrderDetailsSection';
import ConsigneeSection from './ConsigneeSection';
import SenderSectionCheckin from './SenderSectionCheckin';

export type CheckinSheetFormValues = Partial<CheckinSheetForm>;

type Props = {
  initialValues: CheckinSheetFormValues;
  readOnlyValues?: (keyof CheckinSheetFormValues)[];
  onSubmit: (
    values: CheckinSheetFormValues,
    formikHelpers: FormikHelpers<CheckinSheetFormValues>
  ) => void | Promise<CheckinSheetForm>;
  children: React.ReactNode;
};
const OrderTypeSelectComp = ({
  onChange,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}) => {
  return (
    <PSelect name="deliveryMode" label="Delivery Mode" fullWidth onChange={onChange}>
      {consignmentTypes.map((consignmentType) => (
        <MenuItem key={consignmentType.code} value={consignmentType.code}>
          {consignmentType.name}
        </MenuItem>
      ))}
    </PSelect>
  );
};

const OrderTypeSelect = React.memo(OrderTypeSelectComp);

const CheckinSheetFormAdd = ({ initialValues, onSubmit, children, readOnlyValues }: Props) => {
  const { validateConsignment } = useValidators();
  const { validateCheckInSheet } = useValidators();

  const processInitialValues = useCallback(() => {
    const toReturn = { ...initialValues };
    if ((toReturn.depot as Depot)?._id) {
      toReturn.depot = (toReturn.depot as Depot)._id;
    }
    return toReturn;
  }, [initialValues]);

  return (
    <Formik initialValues={processInitialValues()} validate={validateCheckInSheet} onSubmit={onSubmit}>
      {() => <FormComponent readOnlyValues={readOnlyValues}>{children}</FormComponent>}
    </Formik>
  );
};

const FormComponent = ({ children, readOnlyValues }: Pick<Props, 'children' | 'readOnlyValues'>) => {
  const classes = useStyles();
  const _props = useFormikContext<CheckinSheetFormValues>();
  const [countryCode, setCountryCode] = useState('');
  const getFormikTextFields = useGetFormikTextFields();
  const formikProps = getFormikTextFields(_props);
  const { setFieldValue, values, handleSubmit } = _props;
  const [depots, setDepots] = useState<Depot[]>([]);
  const { list } = useDepotApiRoutes();
  useEffect(() => {
    let active = true;
    if (_props.values.countryCode) {
      setCountryCode(_props.values.countryCode);
      list(_props.values.countryCode).then((depots) => {
        if (active) {
          setDepots(depots);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [_props.values.countryCode, list, setCountryCode]);

  

  const handleChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue("deliveryMode", value);
      const depot = depots.find((d) => d._id === values.depot);
    
    },
    [depots, setFieldValue, values.depot]
  );



  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Box overflow="auto" padding={6.5} paddingBottom={4}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <PTextFieldMemo
              value={get(values, 'consignmentId')}
              disabled={readOnlyValues?.includes('consignmentId')}
              name="consignmentId"
              fullWidth
              label="Consignment Id"
              {...formikProps}
            />
          </Grid>
          <Grid item xs={4}>
            <OrderTypeSelect onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <PTextFieldMemo
              value={get(values, 'deliveredBy')}
              disabled={readOnlyValues?.includes('deliveredBy')}
              name="deliveredBy"
              fullWidth
              label="Delivered By"
              {...formikProps}
            />
          </Grid>
        </Grid>
        <Divider />
        <SenderSectionCheckin values={values} {...formikProps} />
        <ConsigneeSection values={values} {...formikProps} />
        <CheckinSheetOrderDetailsSection />
      </Box>
      <Box padding={6.5} paddingBottom={4}>
        {children}
      </Box>
    </form>
  );
};

export default CheckinSheetFormAdd;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
