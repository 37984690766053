import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';

const ConsigneeSectionCheckinReadOnly = () => {
  const { checkinsheet } = useContext(ModalContext);
  return (
    <>
      <Typography variant="displayXSmallBold"  mb={3}>
        Consignee
      </Typography>
      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="COMPANY NAME" value={checkinsheet?.consignee.companyName} />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="Port/Country" value={checkinsheet?.consignee.port} />
        </Grid>
      </Grid>
     
      <Grid container spacing={1} columns={3} mb={5}>
      <Grid item xs={3}>
            <ReadOnlyField label="IS CONSIGNEE UNKNOWN" value={checkinsheet?.consignee.consigneeIsUnknown? "YES" : "NO  "} />
          </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="WAREHOUSE LOCATION" value={checkinsheet?.warehouseLocation} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="CONSIGNEE EMAIL" value={checkinsheet?.consignee.email ? checkinsheet?.consignee.email : "-"} />
        </Grid>
      </Grid>
      {checkinsheet?.tags && (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="TAGS" value={checkinsheet?.tags.join(', ')} />
          </Grid>
        </Grid>
      )}
        {checkinsheet?.console && (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="CONSOLE" value={checkinsheet?.console.join(', ')} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ConsigneeSectionCheckinReadOnly;
