import { useCallback } from 'react';
import { CheckInSheet, CheckinSheetForm, Consignment, StatusCode, View } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';
import { serialize } from 'object-to-formdata';

export type CheckinSheetQueryParams = {
  countryCode?: string;
  orderType?: string;
  status?: string[];
  searchText?: string;
  dataFrom?: number | string;
  dataTo?: number | string;
  page?: number;
  itemsPerPage?: number;
  filters?:Array<any>;
  unlimit?: boolean;
};

export default function useCheckinApiRoutes() {
  const publicRoute = usePublicRoute();

  const createCheckinsheet = useCallback(
    async (consignment: Partial<CheckinSheetForm>): Promise<CheckinSheetForm> => {
      if(!consignment.defaultDeliveryDateTime) {
        consignment.defaultDeliveryDateTime = new Date().getTime();
      }
      const formData = serialize(consignment, { allowEmptyArrays: true, indices: true }, undefined);

      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );

  const updateCheckinsheet = useCallback(
    async (checkinsheet: CheckinSheetForm): Promise<CheckinSheetForm> => {

      // if(checkinsheet.documentsSrc && checkinsheet.documentsSrc.length) {
      //   checkinsheet.documents.push(...checkinsheet.documentsSrc)
      // }
      const formData = serialize(checkinsheet, { allowEmptyArrays: true, indices: true }, undefined);

      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/${checkinsheet._id}`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );



  const statusUpdateCheckin = useCallback(
    async (consignmentId: string, status: any): Promise<CheckinSheetForm> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/${consignmentId}/status-update/${status}`,
        data: {},
      });
      return response;
    },
    [publicRoute]
  );

  const listCheckinSheet = useCallback(
    async (params: CheckinSheetQueryParams): Promise<{ checkinsheets: CheckinSheetForm[]; total: number }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets`,
        params: {
          ...params,
          requestFrom: "web"
        },
      });
      return response;
    },
    [publicRoute]
  );
  const listCheckinCompanies = useCallback(
    async (): Promise<any> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/companies`,
      });
      return response;
    },
    [publicRoute]
  );
  const listByView = useCallback(
    async (viewId:string): Promise<{ checkinsheets: CheckinSheetForm[]; view: View }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/ex/checkinsheets/${viewId}`
      });
      return response;
    },
    [publicRoute]
  );
  const getPdfCheckInSheetThirdParty = useCallback(
    async (checkinsheetId: string): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/${checkinsheetId}/pdf-check-in-sheet`,
      });
      return response;
    },
    [publicRoute]
  );
  const toggleCompletion = async (checkinsheet: CheckinSheetForm) => {
    let toStatus: StatusCode | null = null;
    if (checkinsheet.status != 'complete') {
      toStatus = 'complete';
    } else if (checkinsheet.status == 'complete') {
      toStatus = 'unassigned';
    }
    await statusUpdateCheckin(checkinsheet._id,toStatus);
  };

  const reportCsvArchive = useCallback(
    async (params: CheckinSheetQueryParams): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/report-csv/archived`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const emailReport = useCallback(
    async (checkinsheetId: string): Promise<string> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/checkinsheets/${checkinsheetId}/checkinsheet-report`,
      });
      return response;
    },
    [publicRoute]
  );

  return {
    createCheckinsheet,
    statusUpdateCheckin,
    listCheckinSheet,
    updateCheckinsheet,
    toggleCompletion,
    getPdfCheckInSheetThirdParty,
    emailReport,
    reportCsvArchive,
    listCheckinCompanies,
    //external
    listByView
  };
}
