import { IconButton, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { CheckinSheetFormValues } from './CheckinSheetFormAdd';
import fileIcon from '../../../imgs/file.png';
import { Colors } from '../../../pegasus/Colors';
import { PIcon } from '../../../pegasus/PIcon';
import Button from '@mui/material/Button';
import { FileUploader } from "react-drag-drop-files";
const FileUpload = ({documents = []}:any) => {
  const { values, setFieldValue } = useFormikContext<CheckinSheetFormValues>();
  const [files, setFiles] = useState<any>(documents);
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "DOCX", "XLSX"];
  const processFiles = (selectedFiles: FileList) => {
    const filesData: Array<any> = documents;
    for(let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        filesData.push(file);
    }
    setFieldValue('proofOfDelivery.photos', filesData);

    // for(let i = 0; i < selectedFiles.length; i++) {
    //   const file = selectedFiles[i];
    //   const reader = new FileReader();

    //   reader.onload = (e: any) => {
    //     filesData.push({ name: file.name, document:  e?.target?.result, imageType: file.type.includes("image") ? 1 : 0 });

    //     setFiles(filesData);
    //     setFieldValue('documents', filesData);

    //   };

    //   reader.readAsDataURL(file);
    // }
    //setFieldValue('proofOfDelivery',{ filesData});

  };

  const handleFileChange = (event: any) => {
    const selectedFiles = Array.from(event?.target?.files);
    processFiles(selectedFiles);
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedFiles = Array.from(event?.dataTransfer?.files);
    processFiles(selectedFiles);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault(); // Necessary to allow the drop
  };

  const deleteFile = (index:number) => {
    const updatedFiles = files.filter((_: any, i: number) => i !== index);
    setFiles(updatedFiles);
    setFieldValue('proofOfDelivery.photos', updatedFiles);

  }
  

    const [file, setFile] = useState(null);
    const handleChange = (file:any) => {
      setFile(file);
    };

  useEffect(() => {
    console.log("Files updated", files);

  },[files]);
  return (
    <div>
      <br />
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Upload Documents
      </Typography>
      <FileUploader handleChange={processFiles} multiple={true} name="file" types={fileTypes} />
{/*       <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
      <label htmlFor="fileInput" style={{ border: '2px dashed #ccc', padding: '20px', display: 'block', cursor: 'pointer' }}>
        Click to select files or drag and drop them in the space below
      </label>*/}
      <div
      
        style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px', height: '200px' }}
      >
        {files.map((file: any, index: number) => (


          <>
            <img key={index} src={file.imageType ? file.document : fileIcon} alt={`file-${index}`} style={{ width: '100px', height: '100px', marginRight: '10px' }}
            />
            <label>{file.name ?? file.filename}</label>
           <Button onClick={(event) => deleteFile(index)} color='warning'>
            Delete
           </Button>
          </>

        ))} 
      </div>
    </div>
  );
}

export default FileUpload;
