import React, { useContext } from 'react';
import { Consignment } from '../../../../types';
import { IconButton, IconButtonProps } from '@mui/material';
import { PIcon } from '../../../pegasus/PIcon';
import ModalContext from '../../../context/modal';
import { Colors } from '../../../pegasus/Colors';
import useGetContextColor from '../../../hooks/useGetContextColor';

export const CheckInSheetButton = ({ consignment, ...otherProps }: { consignment: Consignment } & IconButtonProps) => {
  const { openDrawer, setConsignment } = useContext(ModalContext);
  const contextColor = useGetContextColor(consignment);
  const handleClick = () => {
    setConsignment(consignment);
    openDrawer('edit-check-in-sheet');
  };
  const disabled = !!consignment.checkInSheet?.screenAtDate; //disable if a checkInSheet has beedn uploaded already
  return consignment.orderType === 'pick-up' ? (
    <IconButton
      disabled={disabled}
      onClick={handleClick}
      sx={{ backgroundColor: disabled ? `${Colors.greyscale.placehold} !important` : contextColor, padding: 1.5 }}
      {...otherProps}
    >
      <PIcon name="edit" sx={{ color: Colors.greyscale.offWhite }} size={16} />
    </IconButton>
  ) : null;
};
