import React from 'react';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { ErrorLabel } from './ErrorLabel';
import DateTimePicker, { DateTimePickerProps } from '@mui/lab/DateTimePicker';

export type ThemedDatePickerProps = Omit<DateTimePickerProps, 'onChange' | 'value' | 'renderInput'> & {
  containerClass?: string;
  textFieldVariant?: TextFieldProps['variant'];
  fullWidth?: TextFieldProps['fullWidth'];
  textFieldClassName?: TextFieldProps['className'];
  textFieldSize?: TextFieldProps['size'];
  onChange?: (e: { target: { name: string; value: number } }) => void;
} & Required<Pick<TextFieldProps, 'name'>>;

export const PDateTimePicker = ({
  containerClass,
  name,
  textFieldVariant = 'filled',
  fullWidth = false,
  textFieldClassName = undefined,
  textFieldSize = 'medium',
  onChange,
  ...otherProps
}: ThemedDatePickerProps) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext();
  const error = name ? get(errors, name) : undefined;
  const getValue = get(values, name, new Date());
  const value = name ? getValue : new Date();

  const handleDateChange = (date: Date) => {
    const value = date.getTime();
    handleChange({ target: { name: name, value: value } });
    if (onChange) {
      onChange({ target: { name: name, value: value } });
    }
  };


  return name ? (
    <Box className={containerClass}>
      <DateTimePicker
        inputFormat="dd/MM/yyyy h:mm a"
        mask="__/__/____"
        value={value || 'Invalid Date'}
        onChange={handleDateChange}
        InputProps={{ disableUnderline: true }}
        showTodayButton={true}
        renderInput={(params) => (
          <TextField
            disabled={true}
            variant={textFieldVariant}
            fullWidth={fullWidth}
            name={name}
            className={textFieldClassName}
            onBlur={handleBlur}
            size={textFieldSize}
            {...params}
          />
        )}
        {...otherProps}
      />
      {name ? <ErrorLabel errorText={get(touched, name) && error} /> : null}
    </Box>
  ) : null;
};
