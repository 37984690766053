import React from 'react';
import { Button, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import { View } from './ViewConsignmentModal';

export const TabButton = ({
  view,
  setView,
  active,
  contextColor,
  ...otherProps
}: {
  view: View;
  setView: React.Dispatch<React.SetStateAction<View>>;
  active: boolean;
  contextColor: string;
}) => {
  const classes = useStyles();
  const handleClick = () => {
    setView(view);
  };
  return (
    <Button
      {...otherProps}
      onClick={handleClick}
      classes={{ root: classes.buttonRoot }}
      className={active ? classes.active : ''}
      sx={{
        backgroundColor: active ? contextColor : 'transparent',
        borderColor: active ? contextColor : Colors.greyscale.placehold,
      }}
    >
      <Typography variant="linkSmall">{view.label}</Typography>
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      borderRadius: 0,
      width: 200,
      padding: theme.spacing(1),
      color: Colors.greyscale.placehold,
      borderWidth: 2,
      borderStyle: 'solid',
    },
    active: {
      color: theme.palette.common.white,
    },
  })
);
