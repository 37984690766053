import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { createStyles, makeStyles } from '@mui/styles';
import Delete from '@mui/icons-material/Delete';

import { Colors } from '../pegasus/Colors';
import { Box, IconButton, Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: theme.spacing(2),
      overflow: 'hidden',
      position: 'relative',
      border : `2px solid ${Colors.primary.background}`
    },
    label: {
      position: 'absolute',
      left: theme.spacing(2.5),
      top: theme.spacing(2.5),
    },
    clear: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    canvas: {
      width: '100%',
    },
  })
);

function SignaturePad({
  onCaptureSignature,
  onClear,
}: {
  onCaptureSignature: (blob: Blob | null) => void;
  onClear: () => void;
}) {
  const classes = useStyles();
  const canvasRef = useRef<SignatureCanvas | null>();
  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }
    onClear();
  };

  const handleEnd = () => {
    if (canvasRef.current) {
      canvasRef.current.getTrimmedCanvas().toBlob((blob) => {
        onCaptureSignature(blob);
      });
      // const img = canvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      // onCaptureSignature(img);
    }
  };
  return (
    <Box className={classes.container}>
      <Typography variant="textSmall" color={Colors.greyscale.label} className={classes.label}>
        Sign
      </Typography>
      <IconButton size="medium" onClick={handleClear} className={classes.clear}>
        <Delete />
      </IconButton>
      <SignatureCanvas
        ref={(ref) => {
          canvasRef.current = ref;
        }}
        backgroundColor={Colors.primary.contrastText}
        canvasProps={{ height: 200, className: classes.canvas }}
        onEnd={handleEnd}
      />
    </Box>
  );
}

export default SignaturePad;
