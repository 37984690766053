import React, { useCallback, useEffect } from 'react';
import { Stack, Autocomplete, TextField, Chip, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

export default function TagsInput({field,value,list, view, removeFilter, onChange}:any) {
  const [tags, setTags] = React.useState<any>([]);

  const handleDelete = (chipToDelete:any) => () => {
    setTags((chips:any) => chips.filter((chip:any) => chip !== chipToDelete));
  };

  const handleAddTag = useCallback((event:any,newValue:any) => {
   setTags(newValue);
   onChange(newValue);
  },[onChange]);
 
  return (
    <Stack spacing={2} direction="row" sx={{ width: 'auto', marginBottom:"2px", justifyContent:'flex-end', display:'flex'}} alignItems="center">
    <Chip label={value} sx={{width:"200px"}} />
      <Autocomplete
        multiple
        id="tags-filled"
        options={list.map((option:any) => option)}
        getOptionLabel={((option:any) => option)}
        onChange={handleAddTag}
        defaultValue={view ? view.filters?.find((item:any) => item.field == field)?.values ?? undefined : undefined}
        filterSelectedOptions        
        sx={{minWidth:200, width:'auto'}}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={value}
          />
        )}
      />

      <IconButton sx={{marginLeft:"2px"}} onClick={()=>removeFilter()}>
        <ClearIcon />
      </IconButton>
    </Stack>
  );
}
