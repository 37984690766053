import React from 'react';
import { Colors } from '../../../pegasus/Colors';
import { SecondaryBadge } from './SecondaryBadge';
import { booleanFields, Consignment } from '../../../../types';
import startCase from 'lodash/startCase';
import { Box } from '@mui/material';
import useGetStatus from '../../../hooks/useGetStatus';

export const ConsignmentBadges = ({
  color = Colors.greyscale.offBlack,
  backgroundColor = Colors.secondary.background,
  consignment,
}: {
  color?: string;
  backgroundColor?: string;
  consignment: Consignment;
}) => {
  const deadRunCount = consignment.deadRuns?.length || 0;
  const getStatus = useGetStatus();
  const status = getStatus(consignment);

  return (
    <Box display="flex" flexWrap="wrap" flex={1}>
      {status?.name ? <SecondaryBadge label={status.name} backgroundColor={backgroundColor} color={color} /> : null}
      {/* {consignment.status === 'onHold' ? (
        <SecondaryBadge label="Holds" backgroundColor={backgroundColor} color={color} />
      ) : null} */}
      <SecondaryBadge label={consignment.orderType} backgroundColor={backgroundColor} color={color} />
      {booleanFields.map((field) => {
        return consignment[field] ? (
          <SecondaryBadge key={field} label={startCase(field)} backgroundColor={backgroundColor} color={color} />
        ) : null;
      })}
      <SecondaryBadge label={`${consignment.goodsType} goods`} backgroundColor={backgroundColor} color={color} />
      <SecondaryBadge label={`Trip ${deadRunCount + 1}`} backgroundColor={backgroundColor} color={color} />
    </Box>
  );
};
