import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import SnackBarContext from '../../../context/snackbar';
import ConsignmentForm, { ConsignmentFormValues } from './ConsignmentForm';
import { SubmitButton } from '../../../pegasus/PSubmit';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { CheckinSheetForm, Consignment } from '../../../../types';
import { Colors } from '../../../pegasus/Colors';
import CheckinSheetFormAdd, { CheckinSheetFormValues } from './CheckinSheetFormAdd';
import useCheckinApiRoutes from '../../../hooks/api/useCheckinApiRoutes';

const EditCheckinSheet = () => {
  let { closeDrawer, checkinsheet } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { updateCheckinsheet } = useCheckinApiRoutes();

  const handleSubmit = (values: CheckinSheetFormValues, { setSubmitting }: FormikHelpers<CheckinSheetFormValues>) => {
    updateCheckinsheet(values as CheckinSheetForm)
      .then((data:any) => {
        setSubmitting(false);
        checkinsheet = data;
        showSnack('CheckIn-sheet Updated!', 'success');
        closeDrawer('edit-checkinsheet');
      })
      .catch(() => setSubmitting(false));
  };

  return checkinsheet ? (
    <Box color={Colors.greyscale.offBlack} display="flex" flexDirection="column" flex={1}>
      <CheckinSheetFormAdd
        onSubmit={handleSubmit}
        initialValues={{
          ...checkinsheet,
          sender: {
            ...checkinsheet.sender,
            state: checkinsheet.sender.state ? checkinsheet.sender.state : '',
            originCountryCode: checkinsheet.sender.originCountryCode ? checkinsheet.sender.originCountryCode : '',
          },
          consignee: {
            ...checkinsheet.consignee,
            state: checkinsheet.consignee.state ? checkinsheet.consignee.state : '',
            originCountryCode: checkinsheet.consignee.originCountryCode ? checkinsheet.consignee.originCountryCode : '',
          },
        }}
      >
        <SubmitButton sx={{ marginRight: 1, backgroundColor: Colors.greyscale.offBlack }}>Save Edits</SubmitButton>
        <Button variant="outlined" color="inherit" onClick={() => closeDrawer('edit-checkinsheet')}>
          Cancel
        </Button>
      </CheckinSheetFormAdd>
    </Box>
  ) : null;
};

export default EditCheckinSheet;
