import React from 'react';
import { Typography, Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginRight: 5,
      marginBottom: 5,
      marginTop: 5,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      alignSelf: 'center',
    },
  })
);

export const SecondaryBadge = ({
  label,
  backgroundColor = Colors.secondary.background,
  color = Colors.greyscale.offBlack,
}: {
  label: string;
  color?: string;
  backgroundColor?: string;
}) => {
  const classes = styles();
  return (
    <Box className={classes.container} sx={{ backgroundColor, color }}>
      <Typography variant="textXSmall" fontWeight={500} noWrap>
        {label}
      </Typography>
    </Box>
  );
};
