import React, { useContext } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';
import { CalculatedBy, LineItem } from '../../../../types';
import ViewConsignmentLabel from './ViewConsignmentLabel';
import useGetTotalCube, { useGetRowTotals } from '../../../hooks/useGetTotalCube';
import useGetLineItem from '../../../hooks/useGetLineItem';

const OrderDetailsSectionReadOnly = ({ contextColor }: { contextColor: string }) => {
  const { consignment } = useContext(ModalContext);

  return consignment ? (
    <>
      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography variant="displayXSmallBold" color={contextColor}>
            Order Details
          </Typography>
        </Grid>
        <Grid display="flex" item xs justifyContent="flex-end" color={contextColor}>
          <ViewConsignmentLabel />
        </Grid>
      </Grid>

      <Grid container spacing={1} mb={5} columns={7}>
        <Grid item xs={1}>
          <ReadOnlyField label="TYPE" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="UNITS" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="LENGTH (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="WIDTH (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="HEIGHT (CM)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="WEIGHT (KG)" />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="CUBIC (M3)" />
        </Grid>
      </Grid>
      {consignment.lineItems?.map((lineItem, index) => (
        <LineItemRow key={index} lineItem={lineItem} calculateVolumeBy={consignment.calculateVolumeBy} />
      ))}
      <TotalsRow color={contextColor} />
    </>
  ) : null;
};

const LineItemField = ({ text }: { text: string }) => {
  return (
    <Grid item xs={1}>
      <Typography variant="textSmall">{text}</Typography>
    </Grid>
  );
};
const LineItemRow = ({ lineItem, calculateVolumeBy }: { lineItem: LineItem; calculateVolumeBy: CalculatedBy }) => {
  const getTotalCube = useGetTotalCube();
  const getLineItem = useGetLineItem();
  const lineItemType = getLineItem(lineItem.type);
  const cube = getTotalCube(lineItem, calculateVolumeBy);
  return (
    <>
      <Grid container spacing={1} mb={1} columns={7} color="black">
        <LineItemField text={lineItemType?.name || ''} />
        <LineItemField text={lineItem.units.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment' ? '-' : (lineItem.length ==null || lineItem.length ==undefined) ? "-":lineItem.length.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment' ? '-' : (lineItem.width ==null || lineItem.width ==undefined) ? "-": lineItem.width.toString()} />
        <LineItemField text={calculateVolumeBy === 'totalShipment' ? '-' :(lineItem.height ==null || lineItem.height ==undefined) ? "-": lineItem.height.toString()} />
        <LineItemField text={lineItem.weight ? lineItem.weight.toString() : "-"} />
        <LineItemField text={cube?(isNaN(cube)?cube.toString():cube.toFixed(4)):"0"} />
      </Grid>
      <Divider />
    </>
  );
};

const TotalsRow = ({ color }: { color: string }) => {
  const { consignment } = useContext(ModalContext);
  const { cubeTotal, unitTotal, weightTotal } = useGetRowTotals(consignment);

  return (
    <Grid container spacing={1} columns={7} marginBottom={7} color={color}>
      <LineItemField text="TOTALS" />
      <LineItemField text={unitTotal.toString()} />
      <Grid item xs={3}></Grid>
      <LineItemField text={weightTotal.toString()} />
      <LineItemField text={cubeTotal.toFixed(4)} />
    </Grid>
  );
};

export default OrderDetailsSectionReadOnly;
