import { useCallback } from 'react';
import { Depot } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useDepotApiRoutes() {
  const publicRoute = usePublicRoute();

  const create = useCallback(
    async (depot: Partial<Depot>): Promise<Depot> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/depots`,
        data: depot,
      });
      return response;
    },
    [publicRoute]
  );

  const list = useCallback(
    async (countryCode: string): Promise<Depot[]> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/depots`,
        params: { countryCode },
      });
      return response;
    },
    [publicRoute]
  );

  const getDepot = useCallback(
    async (depotId: string): Promise<Depot> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/depots/${depotId}`,
      });
      return response;
    },
    [publicRoute]
  );

  return { create, list, getDepot };
}
