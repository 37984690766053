import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PTextFieldMemo, RequiredFormikTextFields } from '../../../pegasus/PTextField';
import AddressSelect from '../../../components/form/AddressSelect';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import { ConsignmentFormValues } from './ConsignmentForm';
import { get, isEqual, pick } from 'lodash';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';
import PTagsInput from '../../../pegasus/PTagsInput';
import { PCheckbox } from '../../../pegasus/PCheckbox';
import { CheckinSheetFormValues } from './CheckinSheetFormAdd';

const ConsigneeSection = ({
  values,
  ...formikProps
}: RequiredFormikTextFields & { values: Partial<CheckinSheetFormValues> }) => {
  return (
    <>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Consignee
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'consignee.companyName')}
            name="consignee.companyName"
            fullWidth
            label="Company Name"
            {...formikProps}
            disabled={get(values, 'consignee.isUnknown')}
          />
        </Grid>
        <Grid item xs={8}>
          <PTextFieldMemo
            value={get(values, 'consignee.port')}
            name="consignee.port"
            fullWidth
            label="Port/Country"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PCheckbox name="consignee.consigneeIsUnknown" label="Is Consignee unknown?" />
        </Grid>
        <Grid item xs={8}>
          <PDateTimePicker name="defaultDeliveryDateTime" fullWidth label="Default Delivery Date Time" />
        </Grid>

      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <PTextFieldMemo
            value={get(values, 'warehouseLocation')}
            name="warehouseLocation"
            fullWidth
            label="Warehouse Location"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={7}>
          <PTextFieldMemo
            value={get(values, 'consignee.email')}
            name="consignee.email"
            fullWidth
            label="Consignee Email"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Add Tag
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PTagsInput name="tags"
            label="Tags" {...formikProps} />
        </Grid>
      </Grid>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Console
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PTagsInput name="console"
            label="Console" {...formikProps}/>
        </Grid>
      </Grid>
    </>
  );
};

const formFields = [
  'consignee.companyName',
  'consignee.email',
  'consignee.port',
  'consignee.consigneeIsUnknown',
  'warehouseLocation',
  'defaultDeliveryDateTime',
  'tags',
  'console'
];
export default React.memo(ConsigneeSection, (prevProps, nextProps) => {
  return isEqual(pick(prevProps.values, formFields), pick(nextProps.values, formFields));
});
