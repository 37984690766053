import React, { ChangeEvent, useContext,useState  } from 'react';
import { Consignment, TableField } from '../../../../types';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { Typography } from '@mui/material';
import { CheckInField, CompanyText, DriverText, ViewButton } from './components';
import ConsignmentContext from '../../../context/consignments';
import { Search } from '../../../components/form/Search';
import { Formik } from 'formik';
import TableFooter from './TableFooter';

const TableFields: TableField[] = [
  {
    field: 'orderType',
    label: 'TYPE',
    columns: 2,
    // eslint-disable-next-line react/display-name
    renderField: (consignment: Consignment) => (
      <Typography variant="linkXSmall">{consignment.orderType.toUpperCase()}</Typography>
    ),
  },
  { field: 'consignmentId', label: 'CONSIGNMENT #', columns: 3 },
  {
    field: 'depot.companyName',
    label: 'COMPANY',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment: Consignment) => <CompanyText consignment={consignment} />,
  },
  { field: 'sender.address', label: 'ORIGIN', columns: 3 },
  { field: 'receiver.address', label: 'DESTINATION', columns: 3 },
  { field: 'estimatedDeliveryTime', label: 'ESTIMATED DELIVERY', columns: 3, formatDate: true },
  { field: 'pickedUpDate', label: 'PICKUP DATE', columns: 3, formatDate: true },
  {
    field: 'consignment.assignedToDriverLeg1',
    label: 'DRIVER',
    columns: 2,
    // eslint-disable-next-line react/display-name
    renderField: (consignment: Consignment) => <DriverText consignment={consignment} />,
  },
  {
    field: 'checkInSheet.screenAtDate',
    label: 'CHECK-IN SHEET',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment: Consignment) => <CheckInField consignment={consignment} />,
  },
  {
    field: 'actions',
    label: '',
    columns: 1,
    // eslint-disable-next-line react/display-name
    renderField: (consignment: Consignment) => <ViewButton consignment={consignment} />,
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);
const CompletedTable = () => {
  const { consignments } = useContext(ConsignmentContext);
  const [consigmentFilter, setConsignmentFilter] = useState<{
    searchText: string;
  }>({ searchText: '' });
  return (
    <>
     <Formik
              validationDisabled={true}
              initialValues={consigmentFilter}
              onSubmit={setConsignmentFilter}
              enableReinitialize
            >
              {({ handleSubmit, handleChange }) => {
                const handleSearchChange = (
                  event: React.FormEvent<HTMLFormElement> | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  handleChange(event);
                  handleSubmit();
                };
                return (
                  <>
                    <Search
                      name="searchText"
                      placeholder="Search by Consignment, Company name, Origin, Destination"
                      onChange={handleSearchChange}
                      sx={{ width: '600px', marginTop: 0.5, marginBottom:1, marginRight: 1, border: 'solid lightgray 1px',borderRadius: '10px',boxShadow: '0px 0px 5px 0px lightgray' }}
                    />
                  </>
                );
              }}
      </Formik>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      {consignments.length > 0
        ? (consigmentFilter && !consigmentFilter.searchText.trim()?consignments:
        consignments.filter((c) => (c.consignmentId.includes(consigmentFilter.searchText.toLocaleLowerCase()) 
            || c.sender.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
            || c.receiver.address.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
            || c.receiver.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
            || c.sender.companyName.toLocaleLowerCase().includes(consigmentFilter.searchText.toLocaleLowerCase())
        ))).map((consignment) => {
            return (
              <TableRow
                key={consignment._id}
                consignment={consignment}
                tableFields={TableFields}
                columnCount={totalColumns}
              />
            );
          })
        : null}
      <TableFooter />
    </>
  );
};

export default CompletedTable;
