import { useContext, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { PlaceType } from '../context/googleApi';
import UserContext from '../context/user';
import useAddressBookApiRoutes from './api/useAddressBookApiRoutes';
const debounceDelay = 500;
import match from 'autosuggest-highlight/match';

export const useGetAddressBookOptions = () => {
  const { user } = useContext(UserContext);
  const { list } = useAddressBookApiRoutes();

  const getAddressBookOptions = useMemo(
    () =>
      debounce(async (searchString: string, callback: (results?: readonly PlaceType[]) => void) => {
        let _addressBookOptions: PlaceType[] = [];
        if (user && user._id) {
          _addressBookOptions = (
            await list({
              countryCode: user?.countryCode,
              unlimit: true,
            })
          ).addressBooks.map((addressBook) => {
            const addressArr = addressBook.address.split(',');
            const main_text = addressArr[0].trim();
            addressArr.shift();
            const secondary_text = addressArr.join(', ').trim();
            return {
              addressBook: addressBook,
              description: addressBook.address,
              structured_formatting: {
                main_text: main_text,
                secondary_text: secondary_text,
                main_text_matched_substrings: [],
              },
            };
          });
        }
        _addressBookOptions = _addressBookOptions.map((o) => {
          const matches = match(o.description, searchString);
          if (matches.length > 0) {
            o.structured_formatting.main_text_matched_substrings = matches.map(([fromIndex, toIndex]) => ({
              length: toIndex - fromIndex,
              offset: fromIndex,
            }));
          }
          return o;
        });
        return callback(_addressBookOptions);
      }, debounceDelay),
    [list, user]
  );
  return getAddressBookOptions;
};
