import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

// eslint-disable-next-line
const errorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      letterSpacing: '0.4px',
      fontWeight: 700,
      fontSize: 12,
      marginTop: 11,
      marginBottom: -11,
    },
  })
);
export const ErrorLabel = ({ errorText }: { errorText: string }) => {
  const classes = errorStyles();
  return (
    <Typography className={classes.root} color="error">
      {errorText}
    </Typography>
  );
};
